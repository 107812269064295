import { React, useState, useEffect } from 'react'
import './clients.css'
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import DefaultPhoto from '../../../img/icons/workplace/ClientDefaultPhoto.svg'
import { useHistory, NavLink } from 'react-router-dom';

export default function Clients() {

    const { t } = useTranslation();
    const [defaultitems, setDefaultItems] = useState();
    const [items, setItems] = useState();
    const [searchText, setSearchText] = useState("");

    useEffect(() => { axios.get("/clients").then(response => { setDefaultItems(response.data.ClientList); setItems(response.data.ClientList) }); }, []);

    useEffect(() => {
        if (searchText !== "") {
            setItems([]);
            defaultitems.map(item =>
                (item.Name && item.Name.toUpperCase().includes(searchText.toUpperCase()))
                    || (item.Company && item.Company.toUpperCase().includes(searchText.toUpperCase()))
                    || (item.Index && item.Index.toUpperCase().includes(searchText.toUpperCase()))
                    || (item.Address && item.Address.toUpperCase().includes(searchText.toUpperCase()))
                    || (item.Phone && item.Phone.toUpperCase().includes(searchText.toUpperCase()))
                    || (item.Email && item.Email.toUpperCase().includes(searchText.toUpperCase()))
                    ? setItems(array => [...array, item])
                    : null
            )
        }
        else
            setItems(defaultitems);
    }
        , [searchText, defaultitems]);

    const history = useHistory();
    const handleRowClick = (id) => {
        history.push("/workplace/clients/" + id);
    }

    return (
        <div id="clients">
            <NavLink to="/workplace/clients/0" id="ClientsAddButtonBlock">
                <div id="ClientsAddButton" className="AddButton">{t("workplace.menuitems.clients.addbutton")}</div>
            </NavLink>

            <div id="ClientsSearchBlock">
                <input value={searchText} onChange={(e) => { setSearchText(e.target.value); }} placeholder={t("workplace.menuitems.clients.searchtext")} type="text" id="ClientsSearchInput" />
            </div>

            <div id="ClientsBackBlock">
                <table id="ClientsTable">
                    <thead>
                        <tr className="tablehead">
                            <th className="clientsTableNumberBlock">№</th>
                            <th>{t("workplace.menuitems.clients.name")}</th>
                            <th>{t("workplace.menuitems.clients.company")}</th>
                            <th>{t("workplace.menuitems.clients.indexaddress")}</th>
                            <th>{t("workplace.menuitems.clients.phone")}</th>
                            <th>E-mail</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            items
                                ? items.map((item, index) => (
                                    <tr className="tablebody" key={item.Id} onClick={() => { handleRowClick(item.Id); }}>
                                        <td className="clientsTableNumberBlock">{(index - items.length) * -1}</td>
                                        <td className="ClientsTablePhotoNameBlock">{item.Photo ? <img className="ClientsTablePhoto" src={item.Photo} alt="" /> : <img className="ClientsTablePhoto" src={DefaultPhoto} alt="" />}<span>{item.Name}</span></td>
                                        <td>{item.Company}</td>
                                        <td>{item.Index}{item.Index && item.Index !== "" && item.Address && item.Address !== "" ? <span>, </span> : null}{item.Address}</td>
                                        <td>{item.Phone}</td>
                                        <td>{item.Email}</td>
                                    </tr>
                                ))
                                : null
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}
