import axios from 'axios';
import { React, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

export default function RequisitesContractor({ commercialProposalItems, setCommercialProposalItems, setRequisitesBlock, contractorid, requisites, setRequisites, dateInput, cpid }) {

    const { t } = useTranslation();

    const [companyName, setCompanyName] = useState("");
    const [contactName, setContactName] = useState("");
    const [locality, setLocality] = useState("");
    const [address, setAddress] = useState("");
    const [department, setDepartment] = useState("");
    const [index, setIndex] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [fax, setFax] = useState("");
    const [inn, setINN] = useState("");
    const [offerperiod, setOfferPeriod] = useState("");
    const [bank, setBank] = useState("");
    const [codeBank, setCodeBank] = useState("");
    const [bankAccount, setBankAccount] = useState("");
    const [companyAccount, setCompanyAccount] = useState("");
    const [bik, setBIK] = useState("");
    const [bankINN, setBankINN] = useState("");
    const [governingBody, setGoverningBody] = useState("");
    const [supervisor, setSupervisor] = useState("");
    const [chairmanSupervisoryBoard, setChairmanSupervisoryBoard] = useState("");
    const [registeredCourt, setRegisteredCourt] = useState("");
    const [registrationNumber, setRegistrationNumber] = useState("");
    const [website, setWebsite] = useState("");

    const [contractorRequisitesLinks, setContractorRequisitesLinks] = useState([]);

    const [companyNameValid, setCompanyNameValid] = useState(true)
    const [contactNameValid, setContactNameValid] = useState(true)
    const [localityValid, setLocalityValid] = useState(true)
    const [addressValid, setAddressValid] = useState(true)
    const [departmentValid, setDepartmentValid] = useState(true)
    const [indexValid, setIndexValid] = useState(true)
    const [emailValid, setEmailValid] = useState(true)
    const [phoneValid, setPhoneValid] = useState(true)
    const [faxValid, setFaxValid] = useState(true)
    const [innValid, setINNValid] = useState(true)
    const [offerperiodValid, setOfferPeriodValid] = useState(true)
    const [bankValid, setBankValid] = useState(true)
    const [codeBankValid, setCodeBankValid] = useState(true)
    const [bankAccountValid, setBankAccountValid] = useState(true)
    const [companyAccountValid, setCompanyAccountValid] = useState(true)
    const [bikValid, setBIKValid] = useState(true)
    const [bankINNValid, setBankINNValid] = useState(true);
    const [governingBodyValid, setGoverningBodyValid] = useState(true);
    const [supervisorValid, setSupervisorValid] = useState(true);
    const [chairmanSupervisoryBoardValid, setChairmanSupervisoryBoardValid] = useState(true);
    const [registeredCourtValid, setRegisteredCourtValid] = useState(true);
    const [registrationNumberValid, setRegistrationNumberValid] = useState(true);
    const [websiteValid, setWebsiteValid] = useState(true);

    const [parameters, setParameters] = useState([]);

    const [date, setDate] = useState("");

    const editrequisites = () => {
        var invalid = false;
        let parametersTitle = ["CompanyName", "ContactName", "Locality", "Address", "Department", "Index", "Email", "Phone", "Fax", "INN", "OfferPeriod", "Bank", "CodeBank", "BankAccount", "CompanyAccount", "BIK", "BankINN", "GoverningBody", "Supervisor", "ChairmanSupervisoryBoard", "RegisteredCourt", "RegistrationNumber", "Website"];
        let hooksValue = [companyName, contactName, locality, address, department, index, email, phone, fax, inn, offerperiod, bank, codeBank, bankAccount, companyAccount, bik, bankINN, governingBody, supervisor, chairmanSupervisoryBoard, registeredCourt, registrationNumber, website];
        let hooksValidValue = [setCompanyNameValid, setContactNameValid, setLocalityValid, setDepartmentValid, setAddressValid, setIndexValid, setEmailValid, setPhoneValid, setFaxValid, setINNValid, setOfferPeriodValid, setBankValid, setCodeBankValid, setBankAccountValid, setCompanyAccountValid, setBIKValid, setBankINNValid, setGoverningBodyValid, setSupervisorValid, setChairmanSupervisoryBoardValid, setRegisteredCourtValid, setRegistrationNumberValid, setWebsiteValid];

        hooksValue.forEach((hookValue, index) => {
            if ((hookValue === null || hookValue.trim() === "") && parameters.includes(parametersTitle[index])) {
                invalid = true;
                hooksValidValue[index](false)
            }
        });

        if (invalid) return;

        let formData = new FormData();
        formData.append('ContractorId', contractorid);
        formData.append('Date', date);
        formData.append('CompanyName', companyName);
        formData.append('ContactName', contactName);
        formData.append('Locality', locality);
        formData.append('Address', address);
        formData.append('Index', index);
        formData.append('Email', email);
        formData.append('Phone', phone);
        formData.append('Fax', fax);
        formData.append('Department', department);
        formData.append('INN', inn);
        formData.append('OfferPeriod', offerperiod);
        formData.append('Bank', bank);
        formData.append('CodeBank', codeBank);
        formData.append('BankAccount', bankAccount);
        formData.append('CompanyAccount', companyAccount);
        formData.append('BIK', bik);
        formData.append('BankINN', bankINN);
        formData.append('GoverningBody', governingBody);
        formData.append('Supervisor', supervisor);
        formData.append('ChairmanSupervisoryBoard', chairmanSupervisoryBoard);
        formData.append('RegisteredCourt', registeredCourt);
        formData.append('RegistrationNumber', registrationNumber);
        formData.append('Website', website);
        formData.append('CommercialProposalId', cpid);

        axios.post("/editrequisitescontractor", formData, { headers: { 'Content-Type': 'multipart/form-data' } })
            .then(() => {
                setRequisitesBlock(false);
                setRequisites({
                    CompanyName: companyName,
                    ContactName: contactName,
                    Locality: locality,
                    Address: address,
                    Index: index,
                    Email: email,
                    Phone: phone,
                    Fax: fax,
                    Department: department,
                    INN: inn,
                    OfferPeriod: offerperiod,
                    Bank: bank,
                    CodeBank: codeBank,
                    BankAccount: bankAccount,
                    CompanyAccount: companyAccount,
                    BIK: bik,
                    BankINN: bankINN,
                    GoverningBody: governingBody,
                    Supervisor: supervisor,
                    ChairmanSupervisoryBoard: chairmanSupervisoryBoard,
                    RegisteredCourt: registeredCourt,
                    RegistrationNumber: registrationNumber,
                    Website: website,
                    ContractorRequisitesLinks: contractorRequisitesLinks
                });
                setCommercialProposalItems({ ...commercialProposalItems, Date: date })
                closeForm();
            })
    }

    const closeForm = () => {
        let hooksValidValue = [setCompanyNameValid, setContactNameValid, setLocalityValid, setDepartmentValid, setAddressValid, setIndexValid, setEmailValid, setPhoneValid, setFaxValid, setINNValid, setOfferPeriodValid, setBankValid, setCodeBankValid, setBankAccountValid, setCompanyAccountValid, setBIKValid, setBankINNValid, setGoverningBodyValid, setSupervisorValid, setChairmanSupervisoryBoardValid, setRegisteredCourtValid, setRegistrationNumberValid, setWebsiteValid];

        hooksValidValue.forEach(item => {
            item(true)
        });
    }

    const cancelRequisites = () => {
        setCompanyName(requisites.CompanyName);
        setContactName(requisites.ContactName);
        setLocality(requisites.Locality);
        setAddress(requisites.Address);
        setIndex(requisites.Index);
        setEmail(requisites.Email);
        setPhone(requisites.Phone);
        setFax(requisites.Fax);
        setDepartment(requisites.Department);
        setINN(requisites.INN);
        setOfferPeriod(requisites.OfferPeriod);
        setBank(requisites.Bank);
        setCodeBank(requisites.CodeBank);
        setBankAccount(requisites.BankAccount);
        setCompanyAccount(requisites.CompanyAccount);
        setBIK(requisites.BIK);
        setBankINN(requisites.BankINN);
        setGoverningBody(requisites.GoverningBody);
        setSupervisor(requisites.Supervisor);
        setChairmanSupervisoryBoard(requisites.ChairmanSupervisoryBoard);
        setRegisteredCourt(requisites.RegisteredCourt);
        setRegistrationNumber(requisites.RegistrationNumber);
        setWebsite(requisites.Website);
    }

    useEffect(() => {
        if (requisites === undefined || requisites === null) return;
        else {
            setDate(requisites.Date);
            setCompanyName(requisites.CompanyName);
            setContactName(requisites.ContactName);
            setLocality(requisites.Locality);
            setAddress(requisites.Address);
            setIndex(requisites.Index);
            setEmail(requisites.Email);
            setPhone(requisites.Phone);
            setFax(requisites.Fax);
            setDepartment(requisites.Department);
            setINN(requisites.INN);
            setOfferPeriod(requisites.OfferPeriod);
            setDate(requisites.Date);
            setBank(requisites.Bank);
            setCodeBank(requisites.CodeBank);
            setBankAccount(requisites.BankAccount);
            setCompanyAccount(requisites.CompanyAccount);
            setBIK(requisites.BIK);
            setBankINN(requisites.BankINN);
            setGoverningBody(requisites.GoverningBody);
            setSupervisor(requisites.Supervisor);
            setChairmanSupervisoryBoard(requisites.ChairmanSupervisoryBoard);
            setRegisteredCourt(requisites.RegisteredCourt);
            setRegistrationNumber(requisites.RegistrationNumber);
            setWebsite(requisites.Website);
            setParameters(requisites.ParametersLink);
            setContractorRequisitesLinks(requisites.ContractorRequisitesLinks);
        }
    }, [requisites])

    return (
        <div id="RequisitesModal">

            <div id="RequisitesModalHeader">
                <div id="RequisitesModalHeaderTitle">{t("workplace.menuitems.requisites.titlecontractormodal")}</div>
            </div>

            {/* <input id='RequisitesLogo' onChange={e => { setLogo(e.target.files[0]); }} style={{ display: 'none' }} type='file' />
            <div className="addcontractorforminputblock" id='RequisitesLogoBlock'>
                <label id='RequisitesLogoLabelBlock' htmlFor="RequisitesLogo">
                    {
                        Preview
                            ? <img id='RequisitesLogoLabelImage' src={Preview} alt="" />
                            : <div id='RequisitesLogoLabel'>
                                <MdOutlineAddAPhoto id='RequisitesLogoLabelIcon' />
                                <div id='RequisitesLogoLabelText'>{t("workplace.menuitems.requisites.logo")}</div>
                            </div>
                    }
                </label>
            </div> */}

            <div style={{ maxHeight: '500px', overflow: 'auto', padding: '10px 0', marginBottom: '10px' }}>

                <div className="addcontractorforminputblock">
                    <input value={companyName ? companyName : ""} onChange={e => { setCompanyName(e.target.value); }} placeholder=" " id="RequisitesCompanyName" className="addcontractorforminput" />
                    <label htmlFor="RequisitesCompanyName" className="addcontractorformlabel">{t("workplace.menuitems.requisites.companyname")}</label>
                    <div className={companyNameValid ? "forminputvalid textValidSettingsContractors" : "forminputvalid textValidSettingsContractors Invalid"}>{t("workplace.menuitems.requisites.validcompanyname")}</div>
                </div>
                <div className="addcontractorforminputblock">
                    <input value={contactName} onChange={e => { setContactName(e.target.value); setContactNameValid(false); }} placeholder=" " id="RequisitesContactName" className="addcontractorforminput" />
                    <label htmlFor="RequisitesContactName" className="addcontractorformlabel">{t("workplace.menuitems.requisites.contactname")}</label>
                    <div className={contactNameValid ? "forminputvalid textValidSettingsContractors" : "forminputvalid textValidSettingsContractors Invalid"}>{t("workplace.menuitems.requisites.validcontactname")}</div>
                </div>
                <div className="addcontractorforminputblock">
                    <input value={locality} onChange={e => { setLocality(e.target.value); setLocalityValid(false); }} placeholder=" " id="RequisitesLocality" className="addcontractorforminput" />
                    <label htmlFor="RequisitesLocality" className="addcontractorformlabel">{t("workplace.menuitems.requisites.locality")}</label>
                    <div className={localityValid ? "forminputvalid textValidSettingsContractors" : "forminputvalid textValidSettingsContractors Invalid"}>{t("workplace.menuitems.requisites.validlocality")}</div>
                </div>
                <div className="addcontractorforminputblock">
                    <input value={address} onChange={e => { setAddress(e.target.value); setAddressValid(false); }} placeholder=" " id="RequisitesAddress" className="addcontractorforminput" />
                    <label htmlFor="RequisitesAddress" className="addcontractorformlabel">{t("workplace.menuitems.requisites.address")}</label>
                    <div className={addressValid ? "forminputvalid textValidSettingsContractors" : "forminputvalid textValidSettingsContractors Invalid"}>{t("workplace.menuitems.requisites.validaddress")}</div>
                </div>
                <div className="addcontractorforminputblock">
                    <input value={department} onChange={e => { setDepartment(e.target.value); setDepartmentValid(true); }} placeholder=" " id="RequisitesDepartment" className="addcontractorforminput" />
                    <label htmlFor="RequisitesDepartment" className="addcontractorformlabel">{t("workplace.menuitems.requisites.department")}</label>
                    <div className={departmentValid ? "forminputvalid textValidSettingsContractors" : "forminputvalid textValidSettingsContractors Invalid"}>{t("workplace.menuitems.requisites.validdepartment")}</div>
                </div>
                <div className="addcontractorforminputgroupblock">
                    <div className="addcontractorforminputblock">
                        <input value={index} onChange={e => { setIndex(e.target.value); setIndexValid(false); }} placeholder=" " id="RequisitesIndex" className="addcontractorforminput" />
                        <label htmlFor="RequisitesIndex" className="addcontractorformlabel">{t("workplace.menuitems.requisites.index")}</label>
                        <div className={indexValid ? "forminputvalid textValidSettingsContractors" : "forminputvalid textValidSettingsContractors Invalid"}>{t("workplace.menuitems.requisites.validindex")}</div>
                    </div>
                    <div className="addcontractorforminputblock">
                        <input value={email} onChange={e => { setEmail(e.target.value); setEmailValid(false); }} placeholder=" " id="RequisitesEmail" className="addcontractorforminput" />
                        <label htmlFor="RequisitesEmail" className="addcontractorformlabel">E-mail</label>
                        <div className={emailValid ? "forminputvalid textValidSettingsContractors" : "forminputvalid textValidSettingsContractors Invalid"}>{t("workplace.menuitems.requisites.validemail")}</div>
                    </div>
                </div>
                <div className="addcontractorforminputgroupblock">
                    <div className="addcontractorforminputblock">
                        <input value={phone} onChange={e => { setPhone(e.target.value); setPhoneValid(false); }} placeholder=" " id="RequisitesPhone" className="addcontractorforminput" />
                        <label htmlFor="RequisitesPhone" className="addcontractorformlabel">{t("workplace.menuitems.requisites.phone")}</label>
                        <div className={phoneValid ? "forminputvalid textValidSettingsContractors" : "forminputvalid textValidSettingsContractors Invalid"}>{t("workplace.menuitems.requisites.validphone")}</div>
                    </div>
                    <div className="addcontractorforminputblock">
                        <input value={fax} onChange={e => { setFax(e.target.value); setFaxValid(false); }} placeholder=" " id="RequisitesFax" className="addcontractorforminput" />
                        <label htmlFor="RequisitesFax" className="addcontractorformlabel">{t("workplace.menuitems.requisites.fax")}</label>
                        <div className={faxValid ? "forminputvalid textValidSettingsContractors" : "forminputvalid textValidSettingsContractors Invalid"}>{t("workplace.menuitems.requisites.validfax")}</div>
                    </div>
                </div>
                <div className="addcontractorforminputgroupblock">
                    <div className="addcontractorforminputblock">
                        <input value={inn} onChange={e => { setINN(e.target.value); setINNValid(true); }} placeholder=" " id="RequisitesINN" className="addcontractorforminput" />
                        <label htmlFor="RequisitesINN" className="addcontractorformlabel">{t("workplace.menuitems.requisites.inn")}</label>
                        <div className={innValid ? "forminputvalid textValidSettingsContractors" : "forminputvalid textValidSettingsContractors Invalid"}>{t("workplace.menuitems.requisites.validinn")}</div>

                    </div>
                    <div className="addcontractorforminputblock">
                        <input value={offerperiod} onChange={e => { setOfferPeriod(e.target.value); setOfferPeriodValid(true); }} placeholder=" " id="RequisitesOfferPeriod" className="addcontractorforminput" />
                        <label htmlFor="RequisitesOfferPeriod" className="addcontractorformlabel">{t("workplace.menuitems.requisites.offerperiod")}</label>
                        <div className={offerperiodValid ? "forminputvalid textValidSettingsContractors" : "forminputvalid textValidSettingsContractors Invalid"}>{t("workplace.menuitems.requisites.validofferperiod")}</div>
                    </div>
                </div>

                <div className="addcontractorforminputgroupblock">
                    <div className="addcontractorforminputblock">
                        <input value={bank} onChange={e => { setBank(e.target.value); setBankValid(true); }} placeholder=" " id="RequisitesBank" className="addcontractorforminput" />
                        <label htmlFor="RequisitesBank" className="addcontractorformlabel">{t("workplace.menuitems.requisites.bank")}</label>
                        <div className={bankValid ? "forminputvalid textValidSettingsContractors" : "forminputvalid textValidSettingsContractors Invalid"}>{t("workplace.menuitems.requisites.validbank")}</div>
                    </div>
                    <div className="addcontractorforminputblock">
                        <input value={codeBank} onChange={e => { setCodeBank(e.target.value); setCodeBankValid(true); }} placeholder=" " id="RequisitesCodeBank" className="addcontractorforminput" />
                        <label htmlFor="RequisitesCodeBank" className="addcontractorformlabel">{t("workplace.menuitems.requisites.codebank")}</label>
                        <div className={codeBankValid ? "forminputvalid textValidSettingsContractors" : "forminputvalid textValidSettingsContractors Invalid"}>{t("workplace.menuitems.requisites.validcodeBank")}</div>
                    </div>
                </div>
                <div className="addcontractorforminputgroupblock">
                    <div className="addcontractorforminputblock">
                        <input value={bankAccount} onChange={e => { setBankAccount(e.target.value); setBankAccountValid(true); }} placeholder=" " id="RequisitesBankAccount" className="addcontractorforminput" />
                        <label htmlFor="RequisitesBankAccount" className="addcontractorformlabel">{t("workplace.menuitems.requisites.bankaccount")}</label>
                        <div className={bankAccountValid ? "forminputvalid textValidSettingsContractors" : "forminputvalid textValidSettingsContractors Invalid"}>{t("workplace.menuitems.requisites.validbankAccount")}</div>
                    </div>
                    <div className="addcontractorforminputblock">
                        <input value={companyAccount} onChange={e => { setCompanyAccount(e.target.value); setCompanyAccountValid(true); }} placeholder=" " id="RequisitesCompanyAccount" className="addcontractorforminput" />
                        <label htmlFor="RequisitesCompanyAccount" className="addcontractorformlabel">{t("workplace.menuitems.requisites.companyaccount")}</label>
                        <div className={companyAccountValid ? "forminputvalid textValidSettingsContractors" : "forminputvalid textValidSettingsContractors Invalid"}>{t("workplace.menuitems.requisites.validcompanyAccount")}</div>
                    </div>
                </div>
                <div className="addcontractorforminputgroupblock">
                    <div className="addcontractorforminputblock">
                        <input value={bik} onChange={e => { setBIK(e.target.value); setBIKValid(true); }} placeholder=" " id="RequisitesBIK" className="addcontractorforminput" />
                        <label htmlFor="RequisitesBIK" className="addcontractorformlabel">{t("workplace.menuitems.requisites.bik")}</label>
                        <div className={bikValid ? "forminputvalid textValidSettingsContractors" : "forminputvalid textValidSettingsContractors Invalid"}>{t("workplace.menuitems.requisites.validbik")}</div>
                    </div>
                    <div className="addcontractorforminputblock">
                        <input value={bankINN} onChange={e => { setBankINN(e.target.value); setBankINNValid(true); }} placeholder=" " id="RequisitesBankINN" className="addcontractorforminput" />
                        <label htmlFor="RequisitesBankINN" className="addcontractorformlabel">{t("workplace.menuitems.requisites.bankinn")}</label>
                        <div className={bankINNValid ? "forminputvalid textValidSettingsContractors" : "forminputvalid textValidSettingsContractors Invalid"}>{t("workplace.menuitems.requisites.validbankINN")}</div>
                    </div>
                </div>
                <div className="addcontractorforminputgroupblock">
                    <div className="addcontractorforminputblock">
                        <input value={governingBody} onChange={e => { setGoverningBody(e.target.value); setGoverningBodyValid(true); }} placeholder=" " id="RequisitesGoverningBody" className="addcontractorforminput" />
                        <label htmlFor="RequisitesGoverningBody" className="addcontractorformlabel">{t("workplace.menuitems.requisites.governingbody")}</label>
                        <div className={governingBodyValid ? "forminputvalid textValidSettingsContractors" : "forminputvalid textValidSettingsContractors Invalid"}>{t("workplace.menuitems.requisites.validgoverningBody")}</div>

                    </div>
                    <div className="addcontractorforminputblock">
                        <input value={supervisor} onChange={e => { setSupervisor(e.target.value); setSupervisorValid(true); }} placeholder=" " id="RequisitesSupervisor" className="addcontractorforminput" />
                        <label htmlFor="RequisitesSupervisor" className="addcontractorformlabel">{t("workplace.menuitems.requisites.supervisor")}</label>
                        <div className={supervisorValid ? "forminputvalid textValidSettingsContractors" : "forminputvalid textValidSettingsContractors Invalid"}>{t("workplace.menuitems.requisites.validsupervisor")}</div>
                    </div>
                </div>
                <div className="addcontractorforminputgroupblock">
                    <div className="addcontractorforminputblock">
                        <input value={chairmanSupervisoryBoard} onChange={e => { setChairmanSupervisoryBoard(e.target.value); setChairmanSupervisoryBoardValid(true); }} placeholder=" " id="RequisitesChairmanSupervisoryBoard" className="addcontractorforminput" />
                        <label htmlFor="RequisitesChairmanSupervisoryBoard" className="addcontractorformlabel">{t("workplace.menuitems.requisites.chairmansupervisoryboard")}</label>
                        <div className={chairmanSupervisoryBoardValid ? "forminputvalid textValidSettingsContractors" : "forminputvalid textValidSettingsContractors Invalid"}>{t("workplace.menuitems.requisites.validchairmanSupervisoryBoard")}</div>
                    </div>
                    <div className="addcontractorforminputblock">
                        <input value={registeredCourt} onChange={e => { setRegisteredCourt(e.target.value); setRegisteredCourtValid(true); setRegistrationNumberValid(true); }} placeholder=" " id="RequisitesRegisteredCourt" className="addcontractorforminput" />
                        <label htmlFor="RequisitesRegisteredCourt" className="addcontractorformlabel">{t("workplace.menuitems.requisites.registeredcourt")}</label>
                        <div className={registeredCourtValid ? "forminputvalid textValidSettingsContractors" : "forminputvalid textValidSettingsContractors Invalid"}>{t("workplace.menuitems.requisites.validregisteredCourt")}</div>
                    </div>
                </div>
                <div className="addcontractorforminputgroupblock">
                    <div className="addcontractorforminputblock">
                        <input value={registrationNumber} onChange={e => { setRegistrationNumber(e.target.value); }} placeholder=" " id="RequisitesRegistrationNumber" className="addcontractorforminput" />
                        <label htmlFor="RequisitesRegistrationNumber" className="addcontractorformlabel">{t("workplace.menuitems.requisites.registrationnumber")}</label>
                        <div className={registrationNumberValid ? "forminputvalid textValidSettingsContractors" : "forminputvalid textValidSettingsContractors Invalid"}>{t("workplace.menuitems.requisites.validregistrationNumber")}</div>
                    </div>
                    <div className="addcontractorforminputblock">
                        <input value={website} onChange={e => { setWebsite(e.target.value); setWebsiteValid(true); }} placeholder=" " id="RequisitesWebsite" className="addcontractorforminput" />
                        <label htmlFor="RequisitesWebsite" className="addcontractorformlabel">{t("workplace.menuitems.requisites.website")}</label>
                        <div className={websiteValid ? "forminputvalid textValidSettingsContractors" : "forminputvalid textValidSettingsContractors Invalid"}>{t("workplace.menuitems.requisites.validwebsite")}</div>
                    </div>
                </div>
                {
                    dateInput
                        ?
                        <div className="addcontractorforminputgroupblock">
                            <div className="addcontractorforminputblock">
                                <input value={date} onChange={e => { setDate(e.target.value); }} placeholder=" " id="RequisitesOfferPeriod" className="addcontractorforminput" />
                                <label htmlFor="RequisitesDepartment" className="addcontractorformlabel">{t("workplace.menuitems.commercialProposal.date")}</label>
                            </div>
                        </div>
                        : null
                }
            </div>

            <div id="RequisitesAddButtonBlock">
                <div id="RequisitesModalCancel" onClick={() => { setRequisitesBlock(false); closeForm(); cancelRequisites(); }}>{t("buttons.cancel")}</div>
                <div id="RequisitesAddButton" onClick={() => editrequisites()}>{t("buttons.save")}</div>
            </div>

        </div>
    )
}
