import { React, useState, useEffect } from 'react'
import './transaction.css'
import { useParams, useHistory, NavLink } from 'react-router-dom'
import axios from 'axios';
import DefaultPhoto from '../../../../img/icons/workplace/ClientDefaultPhoto.svg'
import NoteIcon from '../../../../img/icons/workplace/transaction/TransactionHistoryNote.svg'
import { useTranslation } from 'react-i18next'
import { IoIosArrowDown, IoMdClose, IoMdCheckmark } from 'react-icons/io'
import { AiOutlineRight, AiOutlineDelete } from 'react-icons/ai'
import { HiClock } from 'react-icons/hi'
import { animateScroll } from "react-scroll"
import CurrencyInput from './currencyinput';
import CommercialProposal from './commercialProposal/commercialProposal';
import { Route } from 'react-router-dom/cjs/react-router-dom.min';

export default function Transaction() {

    const { t } = useTranslation();
    const history = useHistory();
    const { transactionid } = useParams();
    const { taskfocuseid } = useParams();
    const [transaction, setTransaction] = useState();
    const [transactionitems, setTransactionItems] = useState([]);
    const [title, setTitle] = useState();
    const [price, setPrice] = useState("");
    const [description, setDescription] = useState("");
    const [stepblock, setStepblock] = useState(false);
    const [clientsblock, setClientsblock] = useState(false);
    const [board, setBoard] = useState();
    const [boards, setBoards] = useState();
    const [clients, setClients] = useState();
    const [client, setClient] = useState();
    const [clientId, setClientId] = useState(0);
    const [note, setNote] = useState("");
    const [procent, setProcent] = useState();
    const [removeBlock, setRemoveBlock] = useState(false);
    const [currency, setCurrency] = useState("");
    const [tasksBlock, setTasksBlock] = useState(false);
    const [dateTimeTask, setDateTimeTask] = useState("");
    const [titleTask, setTitleTask] = useState("");
    const [transitionTasks, setTransitionTasks] = useState([]);
    const [transitionTasksDateValid, setTransitionTasksDateValid] = useState(true);
    const [transitionTasksTextValid, setTransitionTasksTextValid] = useState(true);
    const [transitionLUP, setTransitionLUP] = useState([]);
    const [transitionTaskAddManagerBlock, setTransitionTaskAddManagerBlock] = useState(false);
    const [transitionTaskAddCurrentManager, setTransitionTaskAddCurrentManager] = useState();
    const [focuseTask, setFocuseTask] = useState(0);
    const [postponeBlock, setPostponeBlock] = useState(false);
    const [postponeCurrentTask, setPostponeCurrentTask] = useState();
    const [postponeDate, setPostponedDate] = useState("");
    const [postponeValidDate, setPostponeValidDate] = useState(false);
    const [postponeNote, setPostponeNote] = useState("");
    const [postponeValidNote, setPostponeValidNote] = useState(false);
    const [commercialProposals, setCommercialProposals] = useState();
    const [transactionClientDataDetalization, setTransactionClientDataDetalization] = useState(false);

    const PostponeTask = () => {
        if (postponeDate.trim().length === 0 || postponeNote.trim().length === 0) {
            if (postponeDate.trim().length === 0) setPostponeValidDate(true);
            if (postponeNote.trim().length === 0) setPostponeValidNote(true);
            return;
        }
        axios.post("/postponetask", { TaskId: postponeCurrentTask.Tasks.Id, DateTime: postponeDate, PostponeNote: postponeNote }).then(() => {
            var task = transitionTasks[transitionTasks.findIndex(x => x.Tasks.Id === postponeCurrentTask.Tasks.Id)];
            task.Overdue = false;
            task.Tasks.DateTime = postponeDate;
            setTransitionTasks([...transitionTasks]);
            setPostponeBlock(false);
            setPostponeCurrentTask();
            setPostponedDate("");
            setPostponeValidDate(false);
            setPostponeNote("");
            setPostponeValidNote(false);
            TransactionItems();
        })
    }

    useEffect(() => (transactionitems.length > 0
        ? animateScroll.scrollToBottom({
            duration: 0,
            containerId: "TransactionHistoryContent"
        })
        : null), [transactionitems]);

    let icons = []
    icons["TransactionHistoryClassNote"] = NoteIcon;

    useEffect(() => {
        var deltaTime = new Date().getTimezoneOffset() / 60;
        axios.get("/transaction/" + transactionid + "/" + deltaTime).then(response => response.data)
            .then(data => {
                if (data.Back === true) window.location.href = "/workplace/transactions";
                setTransaction(data);
                setBoard(data.Transaction.Boards.Title);
                setTitle(data ? data.Transaction.Title : "Новая сделка");
                setClients(data ? data.Clients : null);
                if (data && data.Transaction.Price) setPrice(data.Transaction.Price);
                if (data && data.Transaction.Description) setDescription(data.Transaction.Description);
                setBoards(data ? data.Boards ? data.Boards : null : null);
                setClient(data ? data.Transaction.Clients ? data.Transaction.Clients : null : null);
                setClientId(data ? data.Transaction.Clients ? data.Transaction.ClientsId : 0 : 0);
                setProcent(data ? data.Procent ? data.Procent : null : null);
                setTransactionItems(data ? data.TransactionItems ? data.TransactionItems : null : null);
                setCurrency(data ? data.Currency ? data.Currency : null : null);
                setTransitionTasks(data ? data.TransactionTasks ? data.TransactionTasks : null : null);
                setTransitionLUP(data ? data.ManagersAddTask ? data.ManagersAddTask : [] : []);
                setTransitionTaskAddCurrentManager(data.Transaction.Manager);
                setCommercialProposals(data.CommercialProposals);
            }
            )
    }, [transactionid, setTransaction]);

    function ChangeBoard(value) { axios.post("/transactionchangeboardselector", { TransactionId: transaction.Transaction.Id, Value: value.toString() }).then(response => response.data).then(data => { setProcent(data.Procent); setBoards(data.Boards ? data.Boards : null); setTransactionItems(data.TransactionItems); }) }
    function AddNote() { if (note != null && note.length > 1) axios.post("/transactionadditem", { Class: "TransactionHistoryClassNote", TransactionId: transaction.Transaction.Id, Note: note }).then(() => TransactionItems()); }
    function TransactionItems() { axios.get("/transactionitems?TransitionId=" + transaction.Transaction.Id).then(response => response.data).then(data => { setTransactionItems(data); }) }
    function RemoveTransaction() { axios.post("/transactionremove/" + transaction.Transaction.Id).then(() => history.goBack()) }

    const addcp = () => {
        axios.post("/addcommercialproposal/" + transactionid).then(response => response.data).then(data => {
            history.push("/workplace/transactions/transaction/" + transactionid + "/cp/" + data.Id); commercialProposals.push(data); setCommercialProposals([...commercialProposals]);
            animateScroll.scrollToBottom({
                duration: 0,
                containerId: "TransactionCPContent"
            });
        })
    }

    const editTransaction = () => {
        axios.post("/edittransaction", { TransactionId: transaction.Transaction.Id, Title: title, Description: description, Price: price, ClientId: clientId }).then(() => TransactionItems())
    }// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (clientId !== 0)
            editTransaction()
    }, [clientId])// eslint-disable-line react-hooks/exhaustive-deps

    const addTask = () => {
        if (dateTimeTask.trim() === "") setTransitionTasksDateValid(false);
        if (titleTask.trim() === "") setTransitionTasksTextValid(false);
        if (clientId === 0 || dateTimeTask.trim() === "" || titleTask.trim() === "") return;
        axios.post("/addtasks", { DateTime: dateTimeTask.trim(), Title: titleTask.trim(), TransactionsId: transaction.Transaction.Id, ManagerId: transitionTaskAddCurrentManager.Id }).then(response => { setDateTimeTask(""); setTitleTask(""); transitionTasks.push(response.data); setTransitionTasks([...transitionTasks]); TransactionItems(); })
    }
    const changeResultTask = (value, taskId) => {
        transitionTasks[transitionTasks.findIndex(x => x.Tasks.Id === taskId)].Tasks.Result = value;
        setTransitionTasks([...transitionTasks]);
    }
    const successTask = (taskId) => {
        var result = transitionTasks[transitionTasks.findIndex(x => x.Tasks.Id === taskId)].Tasks.Result;
        if (result && result.trim().length > 0)
            axios.post("/successtask", { TaskId: taskId, Result: result }).then(() => { transitionTasks.splice(transitionTasks.findIndex(x => x.Tasks.Id === taskId), 1); setTransitionTasks([...transitionTasks]); TransactionItems(); })
    }

    const CommercialPropoaslItems = () => {
        return (commercialProposals.filter(x => !x.Check).map(item => (
            item.LinkCheckCP != null
                ?
                <div key={"cp_" + item.Id} className='cpFullBlockMain'>
                    <div className="cpBlockMain">
                        <div className={'cpBlock' + (item.Check ? ' Check' : '') + (history.location.pathname === "/workplace/transactions/transaction/" + transactionid + "/cp/" + item.Id ? " Active" : "")} onClick={() => history.push("/workplace/transactions/transaction/" + transactionid + "/cp/" + item.Id)}>
                            <div className='cpText'>{t("workplace.menuitems.commercialProposal.cpTitle")}</div>
                            <div className='cpText'>{item.Title}</div>
                        </div>
                    </div>
                    <div className="cpBlockMain">
                        <div className={'cpBlock Check' + (history.location.pathname === "/workplace/transactions/transaction/" + transactionid + "/cp/" + commercialProposals[commercialProposals.findIndex(x => x.Id === item.LinkCheckCP)].Id ? " Active" : "")} onClick={() => history.push("/workplace/transactions/transaction/" + transactionid + "/cp/" + commercialProposals[commercialProposals.findIndex(x => x.Id === item.LinkCheckCP)].Id)}>
                            <div className='cpText'>{t("workplace.menuitems.commercialProposal.checkTitle")}</div>
                            <div className='cpText'>{commercialProposals[commercialProposals.findIndex(x => x.Id === item.LinkCheckCP)].Title}</div>
                        </div>
                    </div>
                </div>
                :
                <div key={"cp_" + item.Id} className="cpBlockMain">
                    <div className={'cpBlock' + (item.Check ? ' Check' : '') + (history.location.pathname === "/workplace/transactions/transaction/" + transactionid + "/cp/" + item.Id ? " Active" : "")} onClick={() => history.push("/workplace/transactions/transaction/" + transactionid + "/cp/" + item.Id)}>
                        <div className='cpText'>{t("workplace.menuitems.commercialProposal.cpTitle")}</div>
                        <div className='cpText'>{item.Title}</div>
                    </div>
                </div>
        )
        ))
    }

    useEffect(() => {
        setFocuseTask(parseInt(taskfocuseid))
    }, [taskfocuseid])

    return (
        <div id="transactionblock">
            <div id="TransactionDataBlock">
                <div id="TransactionDataBlockHeader">
                    <div id="TransactionDataBlockHeaderContent">
                        <div id="TransactionDataBlockHeaderTitleBlock">
                            <input placeholder={t("workplace.menuitems.transaction.Placeholdertitle")} onBlur={() => editTransaction()} className="transactioninput" id="TransactionDataBlockHeaderTitle" defaultValue={title} onChange={(e) => setTitle(e.target.value)} type="text" />
                            <div id="TransactionDataBlockHeaderTitleDeleteBlock">
                                <AiOutlineDelete id="TransactionDataBlockHeaderTitleDeleteIcon" onClick={() => (setRemoveBlock(!removeBlock))} />
                                <div id="TransactionDataBlockHeaderTitleConfirmDeleteBlock" className={removeBlock ? "Open" : null}>
                                    <div>{t("workplace.menuitems.transaction.RemoveConfirm")}</div>
                                    <div id="TransactionDataBlockHeaderTitleConfirmDeleteButtonsBlock">
                                        <div onClick={() => RemoveTransaction()} className="TransactionDataBlockHeaderTitleConfirmDeleteButton" id="TransactionDataBlockHeaderTitleConfirmDeleteYes"><IoMdCheckmark /></div>
                                        <div onClick={() => setRemoveBlock(!removeBlock)} className="TransactionDataBlockHeaderTitleConfirmDeleteButton" id="TransactionDataBlockHeaderTitleConfirmDeleteNo"><IoMdClose /></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="TransactionDataBlockHeaderNumber">{transaction ? "№ " + transaction.Transaction.Number : "Загрузка данных"}</div>
                        <div id="TransactionDataBlockHeaderProgressBlock" onClick={() => setStepblock(!stepblock)}>
                            <div id="TransactionDataBlockHeaderProgressTitleBlock">
                                <div id="TransactionDataBlockHeaderProgressTitle">{board ? board : "Загрузка данных"}</div>
                                <IoIosArrowDown id="TransactionDataBlockHeaderProgressTitleIcon" className={stepblock ? 'Open' : null} />
                            </div>
                            <div id="TransactionDataBlockHeaderProgress">
                                <div style={{ width: procent ? procent : "0 %" }} id="TransactionDataBlockHeaderProgressValue"></div>
                            </div>
                            {
                                boards
                                    ?
                                    <div id="TransactionDataBlockHeaderProgressStepBlock" hidden={stepblock ? null : 'hidden'}>
                                        {
                                            boards.map(board => (<div onClick={() => { ChangeBoard(board.Id); setBoard(board.Title) }} key={board.Id} className="TransactionDataBlockHeaderProgressStep">{board.Title}</div>))
                                        }
                                    </div>
                                    : null
                            }
                        </div>
                    </div>
                </div>
                <div className="TransactionDataBlockHeaderBlock">
                    <div className="transactiontitle">{t("workplace.menuitems.transaction.Responsible")}</div>
                    <div className="transactiondata">{transaction ? transaction.Transaction.Manager.Peoples.FullName : "Загрузка данных"}</div>
                    <div></div>
                </div>
                <div className="TransactionDataBlockHeaderBlock">
                    <div className="transactiontitle">{t("workplace.menuitems.transaction.Budget")}</div>
                    {
                        currency === ""
                            ? null
                            : <CurrencyInput suffix={" " + currency} className="transactioninput transactiondata" onBlur={() => editTransaction()} value={price} onChange={(e) => setPrice(e.target.value)} placeholder={t("workplace.menuitems.transaction.NoBudget")} type="text" />
                    }
                </div>
                <div className="TransactionDataBlockHeaderBlock">
                    <div className="transactiontitle">{t("workplace.menuitems.transaction.Description")}</div>
                    <input placeholder={t("workplace.menuitems.transaction.NoDescription")} onBlur={() => editTransaction()} className="transactioninput transactiondata" value={description} onChange={(e) => setDescription(e.target.value)} type="text" />
                </div>
                <hr id="TransactionDataBlockHeaderLine" />
                {transaction
                    ?
                    client
                        ?
                        <div>
                            <div className="TransactionDataBlockHeaderBlock">
                                <div id="TransactionClietSelectBlock">
                                    <div>
                                        <div className="transactiontitle">{t("workplace.menuitems.transaction.Client")}</div>
                                        <div id="TransactionDataBlockHeaderClientBlock">
                                            <div id="TransactionDataBlockHeaderClientNameBlock" onClick={() => history.push("/workplace/clients/" + client.Id)}>
                                                {/* <div id="TransactionDataBlockHeaderClientNameBlock" onClick={() => setClientsblock(!clientsblock)}> */}
                                                <img id="TransactionDataBlockHeaderPhotoClient" src={client.Photo ? client.Photo : DefaultPhoto} alt="" />
                                                <div className="transactiondata">{transaction ? client.Name : "Загрузка данных"}</div>
                                            </div>
                                            <div id="TransactionDataBlockHeaderClientNameButton" onClick={() => setTransactionClientDataDetalization(!transactionClientDataDetalization)} className={transactionClientDataDetalization ? "Open" : ""}>
                                                <IoIosArrowDown id="TransactionDataBlockHeaderClientNameButtonArrow" />
                                            </div>
                                        </div>
                                    </div>

                                    <div id="TransactionClietSelectClientsBlock" hidden={clientsblock ? null : 'hidden'}>
                                        {clients.map(client => {
                                            return <div onClick={() => { setClientsblock(!clientsblock); setClientId(client.Id) }} className="TransactionClietSelectClients" key={client.Id}>
                                                {client.Photo ? <img className="TransactionClietSelectClientsPhoto" src={client.Photo} alt="" /> : <img className="TransactionClietSelectClientsPhoto" src={DefaultPhoto} alt="" />}
                                                {client.Name}
                                            </div>
                                        })
                                        }
                                    </div>
                                </div>
                            </div>
                            <div id='TransactionClientDataDetalization' className={transactionClientDataDetalization ? "Open" : ""}>
                                <div className="TransactionDataBlockHeaderBlock">
                                    <div className="transactiontitle">{t("workplace.menuitems.transaction.Firm")}</div>
                                    <div className="transactiondata">
                                        {
                                            client.Company && client.Company !== ""
                                                ? client.Company
                                                : " -"
                                        }
                                    </div>
                                </div>
                                <div className="TransactionDataBlockHeaderBlock">
                                    <div className="transactiontitle">{t("workplace.menuitems.transaction.Address")}</div>
                                    <div className="transactiondata">
                                        {
                                            client.Address && client.Address !== ""
                                                ? client.Address
                                                : "-"
                                        }
                                    </div>
                                </div>
                                <div className="TransactionDataBlockHeaderBlock">
                                    <div className="transactiontitle">{t("workplace.menuitems.transaction.Phone")}</div>
                                    <div className="transactiondata">
                                        {
                                            client.Phone && client.Phone !== ""
                                                ? client.Phone
                                                : "-"
                                        }
                                    </div>
                                </div>
                                <div className="TransactionDataBlockHeaderBlock">
                                    <div className="transactiontitle">E-mail</div>
                                    <div className="transactiondata">
                                        {
                                            client.Email && client.Email !== ""
                                                ? client.Email
                                                : "-"
                                        }</div>
                                </div>
                                <div id='TransactionClientDataDetalizationBottomLine'></div>
                            </div>
                        </div>
                        :
                        <div>
                            {clients
                                ?
                                <div id="TransactionClietSelectBlock">
                                    <div id="TransactionClietSelectButtonBlock" onClick={() => setClientsblock(!clientsblock)}>
                                        <div>{t("workplace.menuitems.transaction.SelectClient")}</div>
                                        <IoIosArrowDown id="TransactionClietSelectButtonBlockIcon" className={clientsblock ? 'Open' : null} />
                                    </div>
                                    <div id="TransactionClietSelectClientsBlock" hidden={clientsblock ? null : 'hidden'}>
                                        {clients.map(client => {
                                            return <div onClick={() => { setClientsblock(!clientsblock); setClientId(client.Id) }} className="TransactionClietSelectClients" key={client.Id}>
                                                {client.Photo ? <img className="TransactionClietSelectClientsPhoto" src={client.Photo} alt="" /> : <img className="TransactionClietSelectClientsPhoto" src={DefaultPhoto} alt="" />}
                                                {client.Name}
                                            </div>
                                        })
                                        }
                                    </div>
                                </div>

                                : "Не найдено ни одного клиента"
                            }
                        </div>
                    : "Загрузка данных"
                }
                <div id='TransactionAddCPButton' onClick={() => addcp()} className='AddButton'>{t("workplace.menuitems.commercialProposal.addcp")}</div>
                {
                    commercialProposals !== undefined
                        ?
                        <div id='TransactionCPBLock'>
                            <div id='TransactionCPContent'>
                                <CommercialPropoaslItems />
                            </div>
                        </div>
                        : null
                }
            </div>
            <div id="TransitionTaskPostponeModalBlock" className={postponeBlock ? "Open" : null}>
                <div id="TransitionTaskPostponeModal">
                    <div className='transitionTaskPostponeModalRowBlock'>
                        <div className='transitionTaskPostponeModalLabel'>{t("workplace.menuitems.tasks.postponetitle")}</div>
                        <div id="TransitionTaskPostponeModalTitleTask">{postponeCurrentTask ? postponeCurrentTask.Tasks.Title : null}</div>
                    </div>
                    <div className='transitionTaskPostponeModalInputBlock'>
                        <div className="transitionTaskPostponeModalLabel">{t("workplace.menuitems.tasks.newtaskdate")}</div>
                        <input id="TransitionTaskPostponeModalDate" type='datetime-local' value={postponeDate} onChange={e => { setPostponeValidDate(false); setPostponedDate(e.target.value); }} />
                        <div className={"transitionTaskPostponeModalValidText" + (postponeValidDate ? " Invalid" : "")}>{t("workplace.menuitems.tasks.newtaskdatevalid")}</div>
                    </div>
                    <div className='transitionTaskPostponeModalInputBlock'>
                        <div className="transitionTaskPostponeModalLabel">{t("workplace.menuitems.tasks.reasontransfer")}</div>
                        <textarea name="" id="TransitionTaskPostponeModalTextArea" value={postponeNote} onChange={e => { setPostponeValidNote(false); setPostponeNote(e.target.value); }}></textarea>
                        <div className={"transitionTaskPostponeModalValidText" + (postponeValidNote ? " Invalid" : "")}>{t("workplace.menuitems.tasks.reasontransfervalid")}</div>
                    </div>
                    <div className='transitionTaskPostponeModalInputBlock' id="TransitionTaskPostponeModalButtonsBlock">
                        <div id="TransitionTaskPostponeModalCancelButton" onClick={() => { setPostponeBlock(false); setPostponeCurrentTask(); setPostponedDate(""); setPostponeValidDate(false); setPostponeNote(""); setPostponeValidNote(false); }}>{t("buttons.cancel")}</div>
                        <div id="TransitionTaskPostponeModalSuccessButton" onClick={() => PostponeTask()}>{t("buttons.ready")}</div>
                    </div>
                </div>
            </div>
            <div id="TransactionHistoryBlock">
                <Route path="/workplace/transactions/transaction/:transactionid/cp/:cpid">
                    <CommercialProposal commercialProposals={commercialProposals} setCommercialProposals={setCommercialProposals} />
                </Route>
                <Route exact path="/workplace/transactions/transaction/:transactionid">
                    <div id="TransactionHistory">
                        {/* //Задачи */}
                        <div className="transactionHistoryItem">
                            <div className="transactionHistoryItemHeader">{t("workplace.menuitems.transaction.tasks")}</div>
                            <div className="transactionHistoryItemContent">
                                <div id="TaskListBlock">
                                    {
                                        transitionTasks.map(task =>
                                            <div key={"TransactionTask_" + task.Tasks.Id} className={"transactionTaskItemBlock " + (task.Overdue || task.Today ? task.Overdue ? "taskOverdue" : task.Today ? "taskToday" : "taskWork" : "taskWork") + (focuseTask === task.Tasks.Id ? " taskFocuse" : "")}>
                                                <div className={"taskClockIconBlock taskItem " + (task.Overdue || task.Today ? task.Overdue ? "taskRed" : task.Today ? "taskYellow" : "taskGreen" : "taskGreen")}>
                                                    <HiClock className="taskClockIcon" />
                                                </div>
                                                <div className={"taskItem " + (task.Overdue || task.Today ? task.Overdue ? "taskTextRed" : task.Today ? "taskTextYellow" : "taskTextGreen" : "taskTextGreen")}>
                                                    <div className='taskTime' onClick={() => { setPostponeBlock(true); setPostponeCurrentTask(task); }}>
                                                        {
                                                            ("0" + new Date(task.Tasks.DateTime).getDate()).slice(-2) + "." + ("0" + (new Date(task.Tasks.DateTime).getMonth() + 1)).slice(-2) + "." +
                                                            new Date(task.Tasks.DateTime).getFullYear() + " " + ("0" + new Date(task.Tasks.DateTime).getHours()).slice(-2) + ":" + ("0" + new Date(task.Tasks.DateTime).getMinutes()).slice(-2)
                                                        }
                                                    </div>
                                                </div>
                                                <div className="taskItem taskItemInputBlock">
                                                    <div className="taskItemInputTitleBlock">
                                                        <div className="taskItemInputTitle">{task.Tasks.Manager.Peoples.FullName}</div>
                                                        <div className="taskItemInputTitle">{t("workplace.menuitems.transaction.task")} "{task.Tasks.Title}"</div>
                                                    </div>
                                                    <div className="taskItemInputButtonBlock">
                                                        {
                                                            focuseTask === task.Tasks.Id
                                                                ? <input autoFocus placeholder={t("workplace.menuitems.transaction.resultplaceholder")} className="taskItemInput" onChange={e => changeResultTask(e.target.value, task.Tasks.Id)} onBlur={() => setFocuseTask(0)} />
                                                                : <input placeholder={t("workplace.menuitems.transaction.resultplaceholder")} className="taskItemInput" onChange={e => changeResultTask(e.target.value, task.Tasks.Id)} onBlur={() => setFocuseTask(0)} />
                                                        }
                                                        <div className={"taskItemButton " + (task.Overdue || task.Today ? task.Overdue ? "taskItemButtonRed" : task.Today ? "taskItemButtonYellow" : "taskItemButtonGreen" : "taskItemButtonGreen")} onClick={() => successTask(task.Tasks.Id)}>{t("workplace.menuitems.transaction.success")}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="transactionHistoryItem">
                            <div className="transactionHistoryItemHeader">{t("workplace.menuitems.transaction.history")}</div>
                            <div className="transactionHistoryItemContent">
                                {
                                    transactionitems
                                        ?
                                        <div id="TransactionHistoryContent">
                                            {transactionitems.map((item, index) => {
                                                return (
                                                    <div className="TransactionHistoryContentItem" key={index}>
                                                        <div className="TransactionHistoryDateBlock">{item.Date}</div>
                                                        {
                                                            item.HistoryItems.map((itemHistory, index) =>

                                                                itemHistory.Transaction
                                                                    ?
                                                                    //История по сделке 
                                                                    <div className={itemHistory.TransactionsItems.ClassItem} key={itemHistory.TransactionsItems.Id}>
                                                                        {
                                                                            icons[itemHistory.TransactionsItems.ClassItem]
                                                                                ? <img className="TransactionHistoryIcon" src={icons[itemHistory.TransactionsItems.ClassItem]} alt="" />
                                                                                : <div className="TransactionHistoryBorder">
                                                                                    <div className="TransactionHistoryBorderPoint"></div>
                                                                                </div>
                                                                        }
                                                                        <div className="transactionHistoryDateTime">
                                                                            {
                                                                                ("0" + new Date(itemHistory.TransactionsItems.InsertedDate).getDate()).slice(-2) + "." + ("0" + (new Date(itemHistory.TransactionsItems.InsertedDate).getMonth() + 1)).slice(-2) + "." +
                                                                                new Date(itemHistory.TransactionsItems.InsertedDate).getFullYear() + " " + ("0" + new Date(itemHistory.TransactionsItems.InsertedDate).getHours()).slice(-2) + ":" + ("0" + new Date(itemHistory.TransactionsItems.InsertedDate).getMinutes()).slice(-2)
                                                                            }
                                                                        </div>
                                                                        <div>
                                                                            <div className="TransactionHistoryBlock">
                                                                                <div className="TransactionHistoryPeople">
                                                                                    {itemHistory.TransactionsItems.InsertedLUP.Peoples.FullName}
                                                                                </div>
                                                                                {
                                                                                    itemHistory.TransactionsItems.HistoryTransactions
                                                                                        ?
                                                                                        <div className="TransactionHistoryText">
                                                                                            {itemHistory.TransactionsItems.HistoryTransactions.CreateTransaction ? t("workplace.menuitems.transaction.CreateTransaction") : null}
                                                                                            {itemHistory.TransactionsItems.HistoryTransactions.Title && itemHistory.TransactionsItems.HistoryTransactions.Create && !itemHistory.TransactionsItems.HistoryTransactions.Remove ? t("workplace.menuitems.transaction.TitleAdd") : null}
                                                                                            {itemHistory.TransactionsItems.HistoryTransactions.Title && !itemHistory.TransactionsItems.HistoryTransactions.Create && !itemHistory.TransactionsItems.HistoryTransactions.Remove ? t("workplace.menuitems.transaction.TitleChange") : null}
                                                                                            {itemHistory.TransactionsItems.HistoryTransactions.Title && !itemHistory.TransactionsItems.HistoryTransactions.Create && itemHistory.TransactionsItems.HistoryTransactions.Remove ? t("workplace.menuitems.transaction.TitleRemove") : null}
                                                                                            {itemHistory.TransactionsItems.HistoryTransactions.Description && itemHistory.TransactionsItems.HistoryTransactions.Create && !itemHistory.TransactionsItems.HistoryTransactions.Remove ? t("workplace.menuitems.transaction.DescriptionAdd") : null}
                                                                                            {itemHistory.TransactionsItems.HistoryTransactions.Description && !itemHistory.TransactionsItems.HistoryTransactions.Create && !itemHistory.TransactionsItems.HistoryTransactions.Remove ? t("workplace.menuitems.transaction.DescriptionChange") : null}
                                                                                            {itemHistory.TransactionsItems.HistoryTransactions.Description && !itemHistory.TransactionsItems.HistoryTransactions.Create && itemHistory.TransactionsItems.HistoryTransactions.Remove ? t("workplace.menuitems.transaction.DescriptionRemove") : null}
                                                                                            {itemHistory.TransactionsItems.HistoryTransactions.Budget && itemHistory.TransactionsItems.HistoryTransactions.Create && !itemHistory.TransactionsItems.HistoryTransactions.Remove ? t("workplace.menuitems.transaction.BudgetAdd") : null}
                                                                                            {itemHistory.TransactionsItems.HistoryTransactions.Budget && !itemHistory.TransactionsItems.HistoryTransactions.Create && !itemHistory.TransactionsItems.HistoryTransactions.Remove ? t("workplace.menuitems.transaction.BudgetChange") : null}
                                                                                            {itemHistory.TransactionsItems.HistoryTransactions.Budget && !itemHistory.TransactionsItems.HistoryTransactions.Create && itemHistory.TransactionsItems.HistoryTransactions.Remove ? t("workplace.menuitems.transaction.BudgetRemove") : null}
                                                                                            {itemHistory.TransactionsItems.HistoryTransactions.Client && itemHistory.TransactionsItems.HistoryTransactions.Create && !itemHistory.TransactionsItems.HistoryTransactions.Remove ? t("workplace.menuitems.transaction.ClientAdd") : null}
                                                                                            {itemHistory.TransactionsItems.HistoryTransactions.Client && !itemHistory.TransactionsItems.HistoryTransactions.Create && !itemHistory.TransactionsItems.HistoryTransactions.Remove ? t("workplace.menuitems.transaction.ClientChange") : null}
                                                                                            {itemHistory.TransactionsItems.HistoryTransactions.Board && !itemHistory.TransactionsItems.HistoryTransactions.Create && !itemHistory.TransactionsItems.HistoryTransactions.Remove ? t("workplace.menuitems.transaction.StageChange") : null}
                                                                                        </div>
                                                                                        : null
                                                                                }
                                                                                {
                                                                                    itemHistory.TransactionsItems.HistoryTransactions && !itemHistory.TransactionsItems.HistoryTransactions.Remove
                                                                                        ? <div className="TransactionHistoryText">
                                                                                            {
                                                                                                itemHistory.TransactionsItems.HistoryTransactions
                                                                                                    ?
                                                                                                    <div className="TransactionHistoryTextChangValueBlock">
                                                                                                        <div>{itemHistory.TransactionsItems.HistoryTransactions.OldValue}</div>
                                                                                                        {
                                                                                                            itemHistory.TransactionsItems.HistoryTransactions.ClientsNew
                                                                                                                ? <NavLink to={"/workplace/clients/" + itemHistory.TransactionsItems.HistoryTransactions.ClientsNewId} className="textHistoryLink">{itemHistory.TransactionsItems.HistoryTransactions.ClientsNew.Name}</NavLink>
                                                                                                                : null
                                                                                                        }
                                                                                                        {!itemHistory.TransactionsItems.HistoryTransactions.Create && !itemHistory.TransactionsItems.HistoryTransactions.Remove && !itemHistory.TransactionsItems.HistoryTransactions.CreateTransaction ? <AiOutlineRight className="TransactionHistoryTextChangValueArrow" /> : null}
                                                                                                        <div>{itemHistory.TransactionsItems.HistoryTransactions.NewValue}</div>
                                                                                                    </div>
                                                                                                    : null
                                                                                            }
                                                                                        </div>
                                                                                        : null
                                                                                }
                                                                            </div>
                                                                            <div className="TransactionHistoryNote">{itemHistory.TransactionsItems.Note}</div>
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    //История по задачам
                                                                    itemHistory.Task
                                                                        ?
                                                                        <div className='TransactionHistoryClassEdit' key={"TaskHistory_" + index}>
                                                                            <div className="TransactionHistoryBorder">
                                                                                <div className="TransactionHistoryBorderPoint"></div>
                                                                            </div>
                                                                            <div className="transactionHistoryDateTime">
                                                                                {
                                                                                    ("0" + new Date(itemHistory.InsertedDate).getDate()).slice(-2) + "." + ("0" + (new Date(itemHistory.InsertedDate).getMonth() + 1)).slice(-2) + "." +
                                                                                    new Date(itemHistory.InsertedDate).getFullYear() + " " + ("0" + new Date(itemHistory.InsertedDate).getHours()).slice(-2) + ":" + ("0" + new Date(itemHistory.InsertedDate).getMinutes()).slice(-2)
                                                                                }
                                                                            </div>
                                                                            <div>
                                                                                <div className="TransactionHistoryBlock">
                                                                                    <div className="TransactionHistoryPeople">
                                                                                        {itemHistory.InsertedLUP.Peoples.FullName}
                                                                                    </div>
                                                                                    {
                                                                                        itemHistory.Task
                                                                                            ? <div className="ClientHistoryText">
                                                                                                {itemHistory.CreateTask ? <span>{t("workplace.menuitems.tasks.createtask")} <span className="clientHistoryBoldText">{itemHistory.TaskTitle}</span></span> : null}
                                                                                                {itemHistory.CompleteTask ? <span><span className="clientHistoryBoldText">{itemHistory.TaskTitle}</span> {t("workplace.menuitems.tasks.completetask")} <span className="clientHistoryBoldText">{itemHistory.ResultTask}</span></span> : null}
                                                                                                {itemHistory.PostponeTask ? <span>{t("workplace.menuitems.tasks.postponetask")} <span className="clientHistoryBoldText">{itemHistory.PostponeNote}</span></span> : null}
                                                                                            </div>
                                                                                            : null
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        : null
                                                            )
                                                        }
                                                    </div>
                                                )
                                            })}
                                        </div>
                                        :
                                        <div>Ничего не найдено</div>
                                }
                            </div>
                        </div>
                    </div>
                    <div id="TransactionHistoryInputBlock">
                        <div className="TransactionHistoryInputTitleBlock">
                            <div className={tasksBlock ? "TransactionHistoryInputTitle" : "TransactionHistoryInputTitle Active"} onClick={() => setTasksBlock(false)}>{t("workplace.menuitems.transaction.Note")}</div>
                            <div className={tasksBlock ? "TransactionHistoryInputTitle Active" : "TransactionHistoryInputTitle"} onClick={() => setTasksBlock(true)}>{t("workplace.menuitems.transaction.Task")}</div>
                        </div>
                        <div id="TransactionHistoryInputArea">
                            {
                                tasksBlock
                                    ? <div>
                                        <div id="TransactionTaskAddBlock">
                                            <div className="transactionTaskAddText">{t("workplace.menuitems.transaction.taskfor")}</div>
                                            <div className="transactionTaskAddInputBlock">
                                                <input id="TransactionTaskDateTimeInput" value={dateTimeTask} onChange={e => { setDateTimeTask(e.target.value); setTransitionTasksDateValid(true); }} type="datetime-local" />
                                                <div className={transitionTasksDateValid ? "transactionTaskAddLabel" : "transactionTaskAddLabel Open"}>{t("workplace.menuitems.transaction.taskDateValid")}</div>
                                            </div>
                                            <div className="transactionTaskAddText">{t("workplace.menuitems.transaction.for")}</div>
                                            <div id="TransactionTaskAddManagerBlock">
                                                <div id="TransactionTaskAddManagerList" className={transitionTaskAddManagerBlock ? "Open" : null}>
                                                    {
                                                        transitionLUP.map(item => {
                                                            return <div className="transactionAddTaskManagerItem" onClick={() => { setTransitionTaskAddCurrentManager(item); setTransitionTaskAddManagerBlock(false); }}>{item.Peoples.FullName}</div>
                                                        })
                                                    }
                                                </div>
                                                <div id="TransactionTaskAddManagerText" onClick={() => setTransitionTaskAddManagerBlock(!transitionTaskAddManagerBlock)}>{transitionTaskAddCurrentManager ? transitionTaskAddCurrentManager.Peoples.FullName : "Загрузка данных"}</div>
                                            </div>
                                            <div className="transactionTaskAddText">:</div>
                                            <div className="transactionTaskAddInputBlock">
                                                <input id="TransactionTaskAddTitleInput" value={titleTask} onChange={e => { setTitleTask(e.target.value); setTransitionTasksTextValid(true); }} placeholder={t("workplace.menuitems.transaction.tasktext")} type="text" />
                                                <div className={transitionTasksTextValid ? "transactionTaskAddLabel" : "transactionTaskAddLabel Open"}>{t("workplace.menuitems.transaction.taskTextValid")}</div>
                                            </div>
                                        </div>
                                        <div className="transactionFooterInputAddButton" onClick={() => { addTask(); }}>{t("workplace.menuitems.transaction.Add")}</div>
                                    </div>
                                    : <div>
                                        <div><input value={note} autoFocus onChange={(e) => setNote(e.target.value)} placeholder={t("workplace.menuitems.transaction.EnterText")} className="TransactionHistoryInput" type="text" /></div>
                                        <div className="transactionFooterInputAddButton" onClick={() => { AddNote(); setNote("") }}>{t("workplace.menuitems.transaction.Add")}</div>
                                    </div>
                            }

                        </div>
                    </div>
                </Route>
            </div>
        </div>
    )
}
