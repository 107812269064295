import { React, useState, useEffect } from 'react'
import { IoIosArrowBack } from 'react-icons/io'
import './addworksitems.css'
import Addworksitemspart from './addworksitemspart'
import Addworksitemsprofessionspart from './addworksitemsprofessionspart'
import { RiArrowGoBackLine } from 'react-icons/ri'
import { AiOutlineClear } from 'react-icons/ai'
import { GoCheck } from 'react-icons/go'
import axios from 'axios'
import { useTranslation } from 'react-i18next'

export default function Addworksitems({ setAddWorksItemsBlock, worksId, stages, setStages }) {

    const { t } = useTranslation();
    const [descritpion, setDescription] = useState("");
    const [fotofixierung, setFotofixierung] = useState(false);
    const [photoList, setPhotoList] = useState([]);
    const [professionsList, setProfessionsList] = useState([]);
    const [addprofessionsBlock, setAddProfessionsBlock] = useState(false);
    const [professionSelectItemsDefault, setProfessionSelectItemsDefault] = useState([]);
    const [professionSelectItems, setProfessionSelectItems] = useState([]);
    const [addProfessionId, setAddProfessionId] = useState(0);
    const [addProfessionText, setAddProfessionText] = useState();
    const [addProfessionCount, setAddProfessionCount] = useState(1);

    const addphotoitem = () => {
        setPhotoList([...photoList, { Photo: null, Description: "", Note: "" }]);
    }
    const addprofessionsitem = () => {
        setProfessionsList([...professionsList, { ProfessionsId: addProfessionId, Title: addProfessionText, Count: addProfessionCount }]);
        let ListProfessionsAdd = professionSelectItems;
        ListProfessionsAdd.splice(professionSelectItems.findIndex(x => x.Id === parseInt(addProfessionId)), 1);
        setProfessionSelectItems(ListProfessionsAdd);
    }
    const changeitem = (id, value, parametr) => {
        let g = photoList[id];
        g[parametr] = value;
        setPhotoList([...photoList.slice(0, id), g, ...photoList.slice(id + 1)]);
    }
    const deleteprofessionitem = (index) => {
        var itemDelete = professionsList[index];
        var itemSelectAdd = professionSelectItemsDefault[professionSelectItemsDefault.findIndex(x => x.Id === parseInt(itemDelete.ProfessionsId))]
        setProfessionsList([...professionsList.slice(0, index), ...professionsList.slice(index + 1)]);
        setProfessionSelectItems([...professionSelectItems, itemSelectAdd]);
    }
    const deletephotoitem = (index) => {
        setPhotoList([...photoList.slice(0, index), ...photoList.slice(index + 1)]);
    }
    useEffect(() => {
        axios.get("/professionscontractorslinklist/" + worksId).then(response => response.data).then(data => {setProfessionSelectItemsDefault(data)})
    }, [worksId])
    useEffect(() => {
        setProfessionSelectItems([...professionSelectItemsDefault])    
    }, [professionSelectItemsDefault])
    const savenewitem = () => {
        let formData = new FormData();
        formData.append('Description', descritpion);
        formData.append('WorksId', worksId);
        formData.append('Fotofixierung', fotofixierung);
        professionsList.map((item, index) => {
            formData.append("ProfessionsList[" + index + "].ProfessionsId", item.ProfessionsId);
            formData.append("ProfessionsList[" + index + "].Count", item.Count);
            return "";
        });
        photoList.map((item, index) => {
            formData.append("PhotoList[" + index + "].Photo", item.Photo);
            formData.append("PhotoList[" + index + "].Description", item.Description);
            formData.append("PhotoList[" + index + "].Note", item.Note);
            return "";
        });
        axios.post("/addworksitems", formData, { headers: { 'Content-Type': 'multipart/form-data' } }).then(response => response.data).then(data => { setStages([...stages, data]); setAddWorksItemsBlock(false); });
    }

    return (
        <div id="AddWorksItemsMainBlock">
            <div id="AddWorksItemsBlock">
                <IoIosArrowBack id="AddWorksItemsBackIcon" onClick={() => { setAddWorksItemsBlock(false); }} />
                <div id="AddWorksItemsTitle">{t("workplace.menuitems.works.addingastage")}</div>
                <div className="forminputblock">
                    <textarea placeholder=" " value={descritpion} onChange={e => setDescription(e.target.value)} id="AddWorksItemsDescription" className="forminput AddWorksItemsTextArea" />
                    <label htmlFor="AddWorksItemsDescription" className="formlabel">{t("workplace.menuitems.works.itemdescription")}</label>
                </div>
                <div id="AddWorksItemsFotoFixedBlock">
                    <input value={fotofixierung} onChange={e => setFotofixierung(e.target.checked)} id="AddWorksItemsPhotoFixedInput" type="checkbox" />
                    <label htmlFor="AddWorksItemsPhotoFixedInput">
                        {t("workplace.menuitems.works.weneedphotofixationofthestage")}
                    </label>
                </div>
                <div>
                    {
                        addprofessionsBlock
                            ? <div id="AddWorksItemsProfessionsAddBlock">
                                <select value={addProfessionId} onChange={(e) => { setAddProfessionId(e.target.value); setAddProfessionText(e.target.options[e.target.selectedIndex].text); }} id="WorksItemProfessionsTableAddSelect">
                                    <option value="0" hidden disabled selected>{t("workplace.menuitems.works.profession")}</option>
                                    {
                                        professionSelectItems.map(item =>
                                            <option key={"ProfessionSelect_" + item.Id} value={item.Id}>{item.Title}</option>
                                        )
                                    }
                                </select>
                                <input id="WorksItemProfessionsTableAddCount" min="1" value={addProfessionCount} onChange={(e) => setAddProfessionCount(e.target.value)} type="number" />
                                <GoCheck className="ProfessionsAddBlockIcons" onClick={() => { addprofessionsitem(); setAddProfessionId(0); setAddProfessionCount(1); setAddProfessionsBlock(false); }} />
                                <AiOutlineClear className="ProfessionsAddBlockIcons" onClick={() => { setAddProfessionId(0); setAddProfessionCount(1) }} />
                                <RiArrowGoBackLine className="ProfessionsAddBlockIcons" onClick={() => { setAddProfessionsBlock(false); setAddProfessionId(0); setAddProfessionCount(1) }} />
                            </div>
                            : <div className="AddWorksItemsAddButton" onClick={() => setAddProfessionsBlock(true)}>{t("workplace.menuitems.works.addspecialist")}</div>
                    }
                </div>
                {
                    professionsList.length > 0
                        ? <div id="AddWorksItemsProfessionsBlock">
                            {
                                professionsList.map((item, index) => <Addworksitemsprofessionspart key={index} item={item} index={index} deleteprofessionitem={deleteprofessionitem}/>)
                            }
                        </div>
                        : null
                }
                <div className="AddWorksItemsAddButton" onClick={() => addphotoitem()}>{t("workplace.menuitems.works.addphoto")}</div>
                <div id="AddWorksItemsPhotoListBlock">
                    <div id="AddWorksItemsPhotoListContentBlock">
                        {
                            photoList.map((item, index) =>
                            <Addworksitemspart key={"PhotoItem_"+index} item={item} index={index} changeitem={changeitem} deletephotoitem={deletephotoitem} />)
                        }
                    </div>
                </div>

            </div>
            <div id="AddWorksItemsPhotoButtonsBlock">
                <div className="WorksItemAddButton" onClick={() => savenewitem()}>{t("workplace.menuitems.works.save")}</div>
                <div id="AddWorksItemsPhotoCancelButton" onClick={() => { setAddWorksItemsBlock(false); }}>{t("workplace.menuitems.works.cancel")}</div>
            </div>
        </div>
    )
}
