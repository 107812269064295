import {React, useState} from 'react'
import { useTranslation } from 'react-i18next'
import { BiLogInCircle } from 'react-icons/bi'
import Cookies from 'universal-cookie'
import {BiLogOutCircle} from 'react-icons/bi'
import {IoPersonCircleOutline} from 'react-icons/io5'
import { useHistory } from 'react-router-dom'
import axios from 'axios'

export default function Checklogin({setloginblock, setlanguageblock, loginblock}){

    const cookies = new Cookies();
    const history = useHistory();
    const {t} = useTranslation();
    const [token] = useState(cookies.get("token"));

    function logout()
    {
        const cookies = new Cookies();
        cookies.remove('token', { path: '/'} );
        return window.location.href = '/'
    }

    return (
    <div id="checkloginblock">
        {
        token
        ?<div id="CheckloginBlockPersCab">
            <div className="CheckloginBlockPersCabBlock" onClick={(e) => {e.preventDefault(); axios.get('/firstpath').then(response => history.push('/workplace/' + response.data));}}><IoPersonCircleOutline className="CheckloginBlockPersCabIcon"/>{t("mainsite.header.personalcabinet")}</div>
            <div className="CheckloginBlockPersCabBlock" onClick={()=>logout()}><BiLogOutCircle className="CheckloginBlockPersCabIcon"/>{t("mainsite.header.logout")}</div>
        </div>
        :<button id="MSheaderPCMenuLoginButton" onClick={() => {setloginblock(!loginblock); setlanguageblock(false)}}>
            <div className="MSheaderPCMenuLoginButtonTitle">{t("mainsite.header.login")}</div>
            <BiLogInCircle id="MSheaderPCMenuLoginButtonTitleIcon"/>
        </button> 
        }
        </div>
    )
}
