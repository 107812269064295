import { React, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import { BiDownArrow } from 'react-icons/bi'

export default function RequisitesClient({ setRequisitesBlock, clientid, requisites, setRequisites }) {

    const { t } = useTranslation();

    const [individual, setIndividual] = useState(false);
    const [companyName, setCompanyName] = useState("");
    const [contactName, setContactName] = useState("");
    const [contactNameValid, setContactNameValid] = useState(false);
    const [locality, setLocality] = useState("");
    const [localityValid, setLocalityValid] = useState(false);
    const [address, setAddress] = useState("");
    const [addressValid, setAddressValid] = useState(false);
    const [index, setIndex] = useState("");
    const [country, setCountry] = useState("");
    const [indexValid, setIndexValid] = useState(false);
    const [contactNames, setContactNames] = useState([]);
    const [contactNamesBlock, setContactNamesBlock] = useState(false);

    const closeForm = () => {
        setContactNameValid(false);
        setLocalityValid(false);
        setAddressValid(false);
        setIndexValid(false);
        setRequisitesBlock(false);
    }

    const setrequisitesdefault = () => {
        setCompanyName(requisites.CompanyName);
        setContactName(requisites.ContactName);
        setLocality(requisites.Locality);
        setAddress(requisites.Address);
        setIndex(requisites.Index);
        setIndividual(requisites.Individual);
        setContactNames(requisites.ContactNames);
        setCountry(requisites.Country);
    }

    useEffect(() => {
        if (requisites === undefined || requisites === null) return;
        else setrequisitesdefault();
    }, [requisites])// eslint-disable-line react-hooks/exhaustive-deps

    const editclientrequisites = () => {
        if (contactName.trim() === "" || locality.trim() === "" || address.trim() === "" || index.trim() === "") {
            if (contactName.trim() === "") setContactNameValid(true);
            if (locality.trim() === "") setLocalityValid(true);
            if (address.trim() === "") setAddressValid(true);
            if (index.trim() === "") setIndexValid(true);
            return;
        }

        axios.post("/editrequisitesclient", { ClientId: clientid, Country: country, CompanyName: companyName, ContactName: contactName, Locality: locality, Address: address, Index: index, Individual: individual })
            .then(() => { axios.get("/getrequisitesclient/" + clientid).then(response => setRequisites(response.data)); closeForm(); })
    }

    return (
        <div id="RequisitesModal">
            <div id="RequisitesModalHeader">
                <div id="RequisitesModalHeaderTitle">{t("workplace.menuitems.requisites.titleclientmodal")}</div>
            </div>
            <div className='forminputblock'>
                <input value={individual} onChange={e => { setIndividual(!individual); }} id="RequisitesClientIndividual" type='checkbox' checked={individual ? true : false} />
                <label htmlFor="RequisitesClientIndividual" id='RequisitesClientIndividualLabel'>{t("workplace.menuitems.requisites.individual")}</label>
            </div>
            {
                individual
                    ? null
                    : <div className='forminputblock'>
                        <input value={companyName} onChange={e => { setCompanyName(e.target.value); }} placeholder=" " id="RequisitesClientCompanyName" className="forminput" />
                        <label className='formlabel' htmlFor="RequisitesClientCompanyName">{t("workplace.menuitems.requisites.companyname")}</label>
                    </div>
            }
            <div id='RequisitesClientContactNamesBlock'>
                <div className='forminputblock' style={{ flexGrow: 1 }}>
                    <div style={{display:'flex'}}>
                        <div style={{flexGrow:1}}>
                            <input value={contactName} list="contactnames" onChange={e => { setContactName(e.target.value); setContactNameValid(false); }} placeholder=" " id="RequisitesClientContactName" className="forminput" />
                            <label className='formlabel' htmlFor="RequisitesClientContactName">{t("workplace.menuitems.requisites.contactname")}</label>
                        </div>
                        {
                            contactNames ?
                                contactNames.length > 0
                                    ?
                                    <BiDownArrow id='RequisitesClientContactNamesArrowIcon' className={contactNamesBlock ? 'Open' : ''} onClick={() => setContactNamesBlock(!contactNamesBlock)} />
                                    : null
                                : null
                        }
                    </div>
                    <div className={"forminputvalid formclientinputvalid" + (contactNameValid ? " Invalid" : "")}>{t("workplace.menuitems.requisites.validcontactname")}</div>
                </div>
                <div id='RequisitesClientContactNamesListBlock' className={contactNamesBlock ? 'Open' : ''}>
                    {
                        contactNames.map((item, index) => (
                            <div key={"contactname_" + index} className='requisitesClientContactNameItem' onClick={() => { setContactName(item); setContactNameValid(false); setContactNamesBlock(false); }}>{item}</div>
                        ))
                    }
                </div>

            </div>
            <div className='forminputblock'>
                <input value={country} onChange={e => { setCountry(e.target.value); }} placeholder=" " id="RequisitesClientCountry" className="forminput" />
                <label className='formlabel' htmlFor="RequisitesClientCountry">{t("workplace.menuitems.requisites.country")}</label>
            </div>
            <div className='forminputblock'>
                <input value={locality} onChange={e => { setLocality(e.target.value); setLocalityValid(false); }} placeholder=" " id="RequisitesClientLocality" className="forminput" />
                <label className='formlabel' htmlFor="RequisitesClientLocality">{t("workplace.menuitems.requisites.locality")}</label>
                <div className={"forminputvalid formclientinputvalid" + (localityValid ? " Invalid" : "")}>{t("workplace.menuitems.requisites.validlocality")}</div>
            </div>
            <div className='forminputblock'>
                <input value={address} onChange={e => { setAddress(e.target.value); setAddressValid(false); }} placeholder=" " id="RequisitesClientAddress" className="forminput" />
                <label className='formlabel' htmlFor="RequisitesClientAddress">{t("workplace.menuitems.requisites.address")}</label>
                <div className={"forminputvalid formclientinputvalid" + (addressValid ? " Invalid" : "")}>{t("workplace.menuitems.requisites.validaddress")}</div>
            </div>
            <div className='forminputblock'>
                <input value={index} onChange={e => { setIndex(e.target.value); setIndexValid(false); }} placeholder=" " id="RequisitesIndex" className="forminput" />
                <label className='formlabel' htmlFor="RequisitesIndex">{t("workplace.menuitems.requisites.index")}</label>
                <div className={"forminputvalid formclientinputvalid" + (indexValid ? " Invalid" : "")}>{t("workplace.menuitems.requisites.validindex")}</div>
            </div>

            <div id="RequisitesAddButtonBlock">
                <div id="RequisitesModalCancel" onClick={() => { setRequisitesBlock(false); setrequisitesdefault(); setContactNamesBlock(false); }}>{t("buttons.cancel")}</div>
                <div id="RequisitesAddButton" onClick={() => { editclientrequisites(); setContactNamesBlock(false); }}>{t("buttons.save")}</div>
            </div>
        </div>
    )
}
