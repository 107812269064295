import { React, useState, useEffect } from 'react'
import { RiArrowGoBackLine } from 'react-icons/ri'
import { AiOutlineClear } from 'react-icons/ai'
import { GoCheck } from 'react-icons/go'
import { useTranslation } from 'react-i18next'
import axios from 'axios'

export default function Worksitemprofessions({ professionsAddList, setProfessionsAddList, setProfessionsWorks, professionsWorks, selectWorkItem, selectLanguageId, defaultLanguageId }) {

    const { t } = useTranslation();
    const [professionsAddBlock, setProfessionsAddBlock] = useState(false);
    const [addProfessionId, setAddProfessionId] = useState(0);
    const [addProfessionCount, setAddProfessionCount] = useState(1);

    function AddProfessionsWorks() {
        if (addProfessionId === 0) return;

        axios.post("/addprofessionscontractorsworks", { ProfessionsContractorsId: addProfessionId, ProfessionsContractorCount: addProfessionCount, WorksId: selectWorkItem.WorksId }).then(response => response.data)
            .then(data => {
                setProfessionsAddBlock(false);
                setProfessionsWorks([...professionsWorks, data.ProfessionsContractorItem]);
                let ListProfessionsAdd = professionsAddList;
                ListProfessionsAdd.splice(professionsAddList.findIndex(x => x.Id === parseInt(addProfessionId)), 1);
                setProfessionsAddList(ListProfessionsAdd);
                setAddProfessionId(0);
                setAddProfessionCount(1);
            });
    }

    useEffect(() => {
        setProfessionsAddBlock(false);
        setAddProfessionId(0);
    }, [selectWorkItem])

    return (
        <div className="WorksItemContentBlock">
            <div className="WorksItemContentTitle">{t("workplace.menuitems.works.specialists")}</div>
            <div id="WorksItemProfessionsTableHeaderBlock">
                <div className="WorksItemProfessionsTableNumber">№</div>
                <div className="WorksItemProfessionsTableProfession">{t("workplace.menuitems.works.profession")}</div>
                <div className="WorksItemProfessionsTableCount">{t("workplace.menuitems.works.numberofpersons")}</div>
            </div>
            {
                professionsWorks.length > 0
                    ?
                    professionsWorks.map((item, index) =>
                        <div key={item.Id} className="WorksItemProfessionsTableItemBlock">
                            <div className="WorksItemProfessionsTableNumber">{index + 1}</div>
                            <div className="WorksItemProfessionsTableProfession">{item.ProfessionsContractors.Title}</div>
                            <div className="WorksItemProfessionsTableCount">{item.Count}</div>
                        </div>
                    )
                    : <div id="WorksItemProfessionsNull">{t("workplace.menuitems.works.nospecialistsfound")}</div>
            }
            {
                professionsAddList.length > 0
                    ?
                    professionsAddBlock
                        ? <div id="WorksItemProfessionsTableAddBlock">
                            <select value={addProfessionId} onChange={(e) => setAddProfessionId(e.target.value)} id="WorksItemProfessionsTableAddSelect">
                                <option value="0" hidden disabled selected>{t("workplace.menuitems.works.profession")}</option>
                                {
                                    professionsAddList.map(item =>
                                        <option key={item.Id} value={item.Id}>{item.Title}</option>
                                    )
                                }
                            </select>
                            <input id="WorksItemProfessionsTableAddCount" min="1" value={addProfessionCount} onChange={(e) => setAddProfessionCount(e.target.value)} type="number" />
                            <GoCheck className="ProfessionsAddBlockIcons" onClick={() => { AddProfessionsWorks() }} />
                            <AiOutlineClear className="ProfessionsAddBlockIcons" onClick={() => { setAddProfessionId(0); setAddProfessionCount(1); }} />
                            <RiArrowGoBackLine className="ProfessionsAddBlockIcons" onClick={() => { setProfessionsAddBlock(false); setAddProfessionId(0); setAddProfessionCount(1); }} />
                        </div>
                        : 
                        selectLanguageId === defaultLanguageId
                        ?<div onClick={() => setProfessionsAddBlock(true)} className="WorksItemAddButton">{t("workplace.menuitems.works.addspecialist")}</div>
                        :null
                    : null
            }
        </div>
    )
}
