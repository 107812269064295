import { React, useState, useEffect } from 'react'
import Menu from './menu/menu'
import Header from './header/header'
import './workplace.css'
import { useTranslation } from 'react-i18next'
import { Switch, Route } from 'react-router-dom'
import Localization from './menuitems/localization/localization'
import Contractors from './menuitems/contractors/contractors'
import Addcontractor from './menuitems/contractors/addcontractor/addcontractor'
import Transactions from './menuitems/transactions/transactions'
import Clients from './menuitems/clients/clients'
import Transaction from './menuitems/transactions/transaction/transaction'
import Works from './menuitems/works/works'
import Professions from './menuitems/professions/professions'
import Client from './menuitems/client/client'
import Contractor from './menuitems/contractor/contractor'
import SettingsContractors from './menuitems/settingsContractors/settingsContractors'
import Tasks from './menuitems/tasks/tasks'
import GoodsServices from './menuitems/goodsservices/goodsservices'
import AddGoodsServices from './menuitems/goodsservices/addgoodsservices'
import '../App.css'
import axios from 'axios'
import Cookies from 'universal-cookie'

export default function Workplace() {

    const { i18n } = useTranslation();
    const [menuItems, setMenuItems] = useState([]);
    const cookies = new Cookies();
    var languagesave = localStorage.getItem('language');
    if (languagesave == null) {
        localStorage.setItem('language', 'de');
        languagesave = localStorage.getItem('language');
    }

    useEffect(() => { i18n.changeLanguage(languagesave); }, [i18n, languagesave]);

    useEffect(() => {
        axios.get("/checktoken").then(response => {
            if (!response.data)
                cookies.remove('token', { path: '/' });
            else
                axios.get("/accessmenuitems").then(response => setMenuItems(response.data))
        });

        const script = document.createElement("script");
        script.src = "//code-sb1.jivosite.com/widget/4jAHQWRBiV";
        script.async = true;
        document.head.appendChild(script);
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    window.jivo_onLoadCallback = function () { axios.get("/LUPAuth").then(response => response.data).then(data => { window.jivo_api.setContactInfo({ "name": data.lup.Peoples.FullName, "email": data.lup.Peoples.Email, "phone": data.lup.Peoples.Phone, "description": "Контрагент: " + data.contractors }); }); }

    return (
        <div id="workplace">
            <Menu />
            <div id="WorkplaceArea">
                <Header />
                <div id="WorkplaceAreaBlock">
                    {
                        menuItems.includes("localization")
                            ? <Route exact path="/workplace/localization" component={Localization} />
                            : null
                    }
                    {
                        menuItems.includes("contractors")
                            ? <Switch>
                                <Route exact path="/workplace/contractors" component={Contractors} />
                                <Route exact path="/workplace/contractors/custom/:contractorid" component={Contractor} />
                                <Route exact path="/workplace/contractors/addcontractor" component={Addcontractor} />
                            </Switch>
                            : null
                    }
                    {
                        menuItems.includes("transactions")
                            ? <Switch>
                                <Route exact path="/workplace/transactions" component={Transactions} />
                                <Route exact path="/workplace/transactions/transaction/:transactionid" component={Transaction} />
                                <Route exact path="/workplace/transactions/transaction/:transactionid/cp/:cpid" component={Transaction} />
                                <Route exact path="/workplace/transactions/transaction/:transactionid/:taskfocuseid" component={Transaction} />
                            </Switch>
                            : null
                    }
                    {
                        menuItems.includes("clients")
                            ? <Switch>
                                <Route exact path="/workplace/clients" component={Clients} />
                                <Route exact path="/workplace/clients/:clientid" component={Client} />
                            </Switch>
                            : null
                    }
                    {
                        menuItems.includes("works")
                            ? <Route exact path="/workplace/works" component={Works} />
                            : null
                    }
                    {
                        menuItems.includes("professions")
                            ? <Route exact path="/workplace/professions" component={Professions} />
                            : null
                    }
                    {
                        menuItems.includes("settingsContractors")
                            ? <Route path="/workplace/settingscontractors" component={SettingsContractors} />
                            : null
                    }
                    {
                        menuItems.includes("tasks")
                            ? <Route path="/workplace/tasks" component={Tasks} />
                            : null
                    }
                    {
                        menuItems.includes("goodsservices")
                            ? <Switch>
                                <Route exact path="/workplace/goodsservices" component={GoodsServices} />
                                <Route path="/workplace/goodsservices/addgoodsservices/" component={AddGoodsServices} />
                                <Route path="/workplace/goodsservices/addgoodsservices/:categoryid" component={AddGoodsServices} />
                            </Switch>

                            : null
                    }
                </div>
            </div>
        </div>
    )
}