import { React, useState, useEffect } from 'react'
import './documentsSettings.css'
import axios from 'axios';
import { useTranslation } from 'react-i18next'
import { MdOutlineAddAPhoto, MdOutlineDeleteOutline } from 'react-icons/md'

export default function DocumentsSettings() {

    const { t } = useTranslation();

    const [logo, setLogo] = useState();
    const [typeLogo, setTypeLogo] = useState();
    const [edit, setEdit] = useState(false);
    const [file1, setFile1] = useState();
    const [preview1, setPreview1] = useState();
    const [previewPath1, setPreviewPath1] = useState();
    const [file2, setFile2] = useState();
    const [preview2, setPreview2] = useState();
    const [previewPath2, setPreviewPath2] = useState();

    useEffect(() => {
        axios.get('/documentsettings').then(response => response.data).then(data => {
            setLogo(data.Logo);
            setTypeLogo(data.TypeLogo);
            setPreviewPath1(data.LogoTopRight);
            setPreviewPath2(data.LogoTop);
        })
    }, []);

    const saveDocumentsSettings = () => {
        axios.post('/documentsettingssave', { Logo: logo, TypeLogo: typeLogo })
    }

    useEffect(() => {
        if (edit) {
            saveDocumentsSettings();
            setEdit(false);
        }
    }, [logo, typeLogo])// eslint-disable-line react-hooks/exhaustive-deps

    const previewfunc1 = (e) => {
        const reader = new FileReader();
        reader.onload = function () {
            setPreview1(reader.result);
        }
        reader.readAsDataURL(e);
    }

    const previewfunc2 = (e) => {
        const reader = new FileReader();
        reader.onload = function () {
            setPreview2(reader.result);
        }
        reader.readAsDataURL(e);
    }

    useEffect(() => {
        if (file1 === undefined) { return; }
        previewfunc1(file1);
        let formData = new FormData();
        formData.append('FileType', 1);
        formData.append('File', file1);
        axios.post("/documentsettingsloadlogo", formData, { headers: { 'Content-Type': 'multipart/form-data' } });
    }, [file1]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (file2 === undefined) { return; }
        previewfunc2(file2);
        let formData = new FormData();
        formData.append('FileType', 2);
        formData.append('File', file2);
        axios.post("/documentsettingsloadlogo", formData, { headers: { 'Content-Type': 'multipart/form-data' } });
    }, [file2]);// eslint-disable-line react-hooks/exhaustive-deps

    const deleteLogo = (value) => {
        axios.post("/documentsettingsremovelogo", { FileType: value }).then(() => {
            if (value === 1) {
                setFile1();
                setPreview1();
                setPreviewPath1();
            }
            else {
                setFile2();
                setPreview2();
                setPreviewPath2();
            }
        })
    }

    return (
        <div id='documentsSettings'>
            <div id='DocumentsSettingsTitle'>{t("workplace.menuitems.settingsDocuments.title")}</div>
            <div className='documentsSettingsRadioBlock'>
                <div className='documentsSettingsRadioItem'>
                    <input type='radio' name='logoType_1' id='LogoType_1' className='documentsSettingsRadioItemInput' onChange={() => { setLogo(0); setEdit(true); }} checked={logo === 0 ? true : false} />
                    <label htmlFor='LogoType_1' className='documentsSettingsRadioItemLabel documentsSettingsText'>{t("workplace.menuitems.settingsDocuments.nologo")}</label>
                </div>
                <div>
                    <input type='radio' name='logoType_1' id='LogoType_2' className='documentsSettingsRadioItemInput' onChange={() => { setLogo(1); setEdit(true); }} checked={logo === 1 ? true : false} />
                    <label htmlFor='LogoType_2' className='documentsSettingsRadioItemLabel documentsSettingsText'>{t("workplace.menuitems.settingsDocuments.logo")}</label>
                    {
                        logo === 1
                            ?
                            <div className='documentsSettingsRadioBlock'>
                                <div className='documentsSettingsRadioItem documentsSettingsRadioTwoLevel'>
                                    <input type='radio' name='logoType_2' id='LogoType_3' className='documentsSettingsRadioItemInput' onChange={() => { setTypeLogo(0); setEdit(true); }} checked={typeLogo === 0 ? true : false} />
                                    <label htmlFor='LogoType_3' className='documentsSettingsRadioItemLabel' >
                                        <div className='documentsSettingsText'>{t("workplace.menuitems.settingsDocuments.logotopright")}</div>
                                        <div className='documentsSettingsTopImageBlock'>
                                            <div id='DocumentsSettingsTopRightImageBlock'></div>
                                        </div>
                                    </label>
                                    <div className='documentsSettingsRadioTwoLevelInputLogoBlock'>
                                        <input style={{ display: "none" }} id={"DocumentsSettingsTopRightImageInput"} type="file" onChange={e => { setFile1(e.target.files[0]); }} accept="image/png, image/jpeg" />
                                        <label id="ClientPhotoInputLabel" htmlFor={"DocumentsSettingsTopRightImageInput"}>
                                            {
                                                preview1
                                                    ? <div><img src={preview1} alt="" className='documentsSettingsImage' /></div>
                                                    : previewPath1
                                                        ? <img src={previewPath1} alt="" className='documentsSettingsImage' />
                                                        : <div className='documentsSettingsPhotoNullBlock'>
                                                            <MdOutlineAddAPhoto className='documentsSettingsPhotoNull' />
                                                            <div>{t("workplace.menuitems.settingsDocuments.addlogobutton")}</div>
                                                        </div>
                                            }
                                        </label>
                                        {
                                            preview1 || previewPath1
                                                ? <MdOutlineDeleteOutline className='documentsSettingsRadioTwoLevelInputLogoRemoveIcon' onClick={() => deleteLogo(1)} />
                                                : null
                                        }
                                    </div>
                                </div>
                                <div className='documentsSettingsRadioTwoLevel'>
                                    <input type='radio' name='logoType_2' id='LogoType_4' className='documentsSettingsRadioItemInput' onChange={() => { setTypeLogo(1); setEdit(true); }} checked={typeLogo === 1 ? true : false} />
                                    <label htmlFor='LogoType_4' className='documentsSettingsRadioItemLabel' >
                                        <div className='documentsSettingsText'>{t("workplace.menuitems.settingsDocuments.logotop")}</div>
                                        <div className='documentsSettingsTopImageBlock'>
                                            <div id='DocumentsSettingsTopImageBlock'></div>
                                        </div>
                                    </label>
                                    <div className='documentsSettingsRadioTwoLevelInputLogoBlock'>
                                        <input style={{ display: "none" }} id={"DocumentsSettingsTopImageInput"} type="file" onChange={e => { setFile2(e.target.files[0]); }} accept="image/png, image/jpeg" />
                                        <label id="ClientPhotoInputLabel" htmlFor={"DocumentsSettingsTopImageInput"}>
                                            {
                                                preview2
                                                    ? <img src={preview2} alt="" className='documentsSettingsImage' />
                                                    : previewPath2
                                                        ? <img src={previewPath2} alt="" className='documentsSettingsImage' />
                                                        : <div className='documentsSettingsPhotoNullBlock'>
                                                        <MdOutlineAddAPhoto className='documentsSettingsPhotoNull' />
                                                        <div>{t("workplace.menuitems.settingsDocuments.addlogobutton")}</div>
                                                    </div>
                                            }
                                        </label>
                                        {
                                            preview2 || previewPath2
                                                ? <MdOutlineDeleteOutline className='documentsSettingsRadioTwoLevelInputLogoRemoveIcon' onClick={() => deleteLogo(2)} />
                                                : null
                                        }
                                    </div>
                                </div>
                            </div>
                            : null
                    }
                </div>
            </div>
        </div>
    )
}
