import { React, useState, useEffect } from 'react'
import { RiArrowDownSLine } from 'react-icons/ri'
import { useTranslation } from 'react-i18next'
import { BiFilterAlt } from 'react-icons/bi'

export default function FilterTasks(
    {
        filter, setFilter,
        filterComplete, setFilterComplete,
        filterOverdue, setFilterOverdue,
        filterToday, setFilterToday,
        filterTommorow, setFilterTommorow,
        filterManagersList, filterBoardsList
    }) {

    const { t } = useTranslation();
    const [managersFilter, setManagersFilter] = useState(false);
    const [allManagersFilter, setAllManagersFilter] = useState(true);
    const [tasksFilter, setTasksFilter] = useState(false);
    const [allTasksFilter, setAllTasksFilter] = useState(true);
    const [boardsFilter, setBoardsFilter] = useState(false);
    const [allBoardsFilter, setAllBoardsFilter] = useState(true);
    // const [text, setText] = useState("");

    //Скрытие фильтров
    const hideFilters = () => {
        setManagersFilter(false);
        setTasksFilter(false);
        setBoardsFilter(false);
    }
    //Сохранение фильтра
    const saveFilter = () => {
        setFilter(
            {
                managers: filter.managers.map(item => { return item }),
                boards: filter.boards.map(item => { return item })
            }
        );
    }
    //Фильтр сотрудников
    const managerCheck = (managerId) => {
        if (filter.managers.includes(managerId))
            filter.managers.splice(filter.managers.findIndex(x => x === managerId), 1);
        else
            filter.managers.push(managerId);
        saveFilter();
    }
    const managerChecked = (managerId) => {
        if (filter.managers.includes(managerId)) return false;
        return true;
    }
    const allManagersChange = () => {
        if (allManagersFilter) filterManagersList.map(item => {return filter.managers.push(item.Id) })
        else filter.managers.splice(0, filter.managers.length);
        saveFilter();    
    }
    //Фильтр задач
    useEffect(() => {
        if (filterComplete === true && filterOverdue === true && filterToday === true && filterTommorow === true)
            setAllTasksFilter(true);
        else
            setAllTasksFilter(false);
    }, [filterComplete, setFilterComplete, filterOverdue, setFilterOverdue, filterToday, setFilterToday, filterTommorow, setFilterTommorow])
    const allTasksChange = () => {
        if (allTasksFilter) { setFilterComplete(false); setFilterOverdue(false); setFilterToday(false); setFilterTommorow(false); }
        else { setFilterComplete(true); setFilterOverdue(true); setFilterToday(true); setFilterTommorow(true); }
    }
    //Фильтр этапов
    const boardsCheck = (boardId) => {
        if (filter.boards.includes(boardId))
            filter.boards.splice(filter.boards.findIndex(x => x === boardId), 1);
        else
            filter.boards.push(boardId);
        saveFilter();
    }
    const boardsChecked = (boardId) => {
        if (filter.boards.includes(boardId)) return false;
        return true;
    }
    const allBoardsChange = () => {
        if (allBoardsFilter) filterBoardsList.map(item => {return filter.boards.push(item.Id) })
        else filter.boards.splice(0, filter.boards.length);
        saveFilter();
    }
    useEffect(() => {
        if (filter.managers.length === 0) setAllManagersFilter(true);
        else setAllManagersFilter(false);
        if (filter.boards.length === 0) setAllBoardsFilter(true);
        else setAllBoardsFilter(false);
    }, [filter])
    const managersFilterElement = document.getElementById("ManagerssFilter");
    document.addEventListener("mousedown", (event) => { if (managersFilterElement != null && !managersFilterElement.contains(event.target)) setManagersFilter(false) });
    const tasksFilterElement = document.getElementById("TasksFilter");
    document.addEventListener("mousedown", (event) => { if (tasksFilterElement != null && !tasksFilterElement.contains(event.target)) setTasksFilter(false) });
    const boardsFilterElement = document.getElementById("BoardsFilter");
    document.addEventListener("mousedown", (event) => { if (boardsFilterElement != null && !boardsFilterElement.contains(event.target)) setBoardsFilter(false) });

    return (

        <div id="TasksFilterBlock">
            <div>
                <div className={managersFilter ? "tasksFilterInputBlock Active" : "tasksFilterInputBlock"} id="ManagerssFilter">
                    <div className="tasksFilterInputTitle" onClick={() => { hideFilters(); setManagersFilter(!managersFilter); }}>
                        {t("workplace.menuitems.tasks.employeefilter")}
                        {
                            allManagersFilter
                                ? null
                                : <BiFilterAlt className="tasksFilterInputTitleFilterIcon" />
                        }
                        <RiArrowDownSLine className="tasksFilterInputArrow" />
                    </div>
                    <div className="tasksFilterContentBlock">
                        <div className="tasksFilterItemBlock">
                            <input checked={allManagersFilter ? true : false} onClick={() => allManagersChange()} onChange={() => setAllManagersFilter(!allManagersFilter)} className="tasksFilterInput" type="checkbox" id="allManagersCheckFilter" />
                            <label className="tasksFilterLabel" htmlFor="allManagersCheckFilter">{t("workplace.menuitems.tasks.allemployee")}</label>
                        </div>
                        {
                            filterManagersList.map(manager =>
                                <div className="tasksFilterItemBlock" key={"Manager_" + manager.Id}>
                                    <input onChange={() => managerCheck(manager.Id)} checked={managerChecked(manager.Id)} className="tasksFilterInput" type="checkbox" id={"ManagersCheckFilter_" + manager.Id} />
                                    <label className="tasksFilterLabel" htmlFor={"ManagersCheckFilter_" + manager.Id}>{manager.Peoples.FullName}</label>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
            <div>
                <div className={tasksFilter ? "tasksFilterInputBlock Active" : "tasksFilterInputBlock"} id="TasksFilter">
                    <div className="tasksFilterInputTitle" onClick={() => { hideFilters(); setTasksFilter(!tasksFilter); }}>
                        {t("workplace.menuitems.tasks.statusfilter")}
                        {
                            allTasksFilter
                                ? null
                                : <BiFilterAlt className="tasksFilterInputTitleFilterIcon" />
                        }
                        <RiArrowDownSLine className="tasksFilterInputArrow" />
                    </div>
                    <div className="tasksFilterContentBlock">
                        <div className="tasksFilterItemBlock">
                            <input checked={allTasksFilter ? true : false} onClick={() => allTasksChange()} onChange={() => setAllTasksFilter(!allTasksFilter)} className="tasksFilterInput" type="checkbox" id="allTasksCheckFilter" />
                            <label className="tasksFilterLabel" htmlFor="allTasksCheckFilter">{t("workplace.menuitems.tasks.alltasks")}</label>
                        </div>
                        <div className="tasksFilterItemBlock">
                            <input checked={filterComplete ? true : false} onChange={() => setFilterComplete(!filterComplete)} className="tasksFilterInput" type="checkbox" id="completeTasksCheckFilter" />
                            <label className="tasksFilterLabel" htmlFor="completeTasksCheckFilter">{t("workplace.menuitems.tasks.completedtasks")}</label>
                        </div>
                        <div className="tasksFilterItemBlock">
                            <input checked={filterOverdue ? true : false} onChange={() => setFilterOverdue(!filterOverdue)} className="tasksFilterInput" type="checkbox" id="overdueTasksCheckFilter" />
                            <label className="tasksFilterLabel" htmlFor="overdueTasksCheckFilter">{t("workplace.menuitems.tasks.overduetasks")}</label>
                        </div>
                        <div className="tasksFilterItemBlock">
                            <input checked={filterToday ? true : false} onChange={() => setFilterToday(!filterToday)} className="tasksFilterInput" type="checkbox" id="todayTasksCheckFilter" />
                            <label className="tasksFilterLabel" htmlFor="todayTasksCheckFilter">{t("workplace.menuitems.tasks.taskstoday")}</label>
                        </div>
                        <div className="tasksFilterItemBlock">
                            <input checked={filterTommorow ? true : false} onChange={() => setFilterTommorow(!filterTommorow)} className="tasksFilterInput" type="checkbox" id="tomorrowTasksCheckFilter" />
                            <label className="tasksFilterLabel" htmlFor="tomorrowTasksCheckFilter">{t("workplace.menuitems.tasks.scheduledtasks")}</label>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div className={boardsFilter ? "tasksFilterInputBlock Active" : "tasksFilterInputBlock"} id="BoardsFilter">
                    <div className="tasksFilterInputTitle" onClick={() => { hideFilters(); setBoardsFilter(!boardsFilter); }}>
                        {t("workplace.menuitems.tasks.boardsfilter")}
                        {
                            allBoardsFilter
                                ? null
                                : <BiFilterAlt className="tasksFilterInputTitleFilterIcon" />
                        }
                        <RiArrowDownSLine className="tasksFilterInputArrow" />
                    </div>
                    <div className="tasksFilterContentBlock">
                        <div className="tasksFilterItemBlock">
                            <input checked={allBoardsFilter ? true : false} onClick={() => allBoardsChange()} onChange={() => setAllBoardsFilter(!allBoardsFilter)} className="tasksFilterInput" type="checkbox" id="allBoardsCheckFilter" />
                            <label className="tasksFilterLabel" htmlFor="allBoardsCheckFilter">{t("workplace.menuitems.tasks.allboards")}</label>
                        </div>
                        {
                            filterBoardsList.map(board =>
                                <div className="tasksFilterItemBlock" key={"Board_" + board.Id}>
                                    <input onChange={() => boardsCheck(board.Id)} checked={boardsChecked(board.Id)} className="tasksFilterInput" type="checkbox" id={"Board_sCheckFilter_" + board.Id} />
                                    <label className="tasksFilterLabel" htmlFor={"Board_sCheckFilter_" + board.Id}>{board.Title}</label>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
            {/* <div className="tasksFilterInput">Текст</div>
            <div>Сбросить</div> */}
        </div>
    )
}
