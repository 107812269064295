import { React, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

export default function Addcontractorrequisites({ openrequisites, setExistRequisites, companyName, setCompanyName, contactName, setContactName, contactNameValid, setContactNameValid, locality, setLocality, localityValid, setLocalityValid, addressRequisites, setAddressRequisites, addressRequisitesValid, setAddressRequisitesValid, index, setIndex, indexValid, setIndexValid, emailRequisites, setEmailRequisites, emailRequisitesValid, setEmailRequisitesValid, phoneRequisites, setPhoneRequisites, phoneRequisitesValid, setPhoneRequisitesValid, fax, setFax, faxValid, setFaxValid, setExistRequisitesValid, department, setDepartment, inn, setINN, offerperiod, setOfferPeriod }) {

    useEffect(() => {
        if (contactName.trim() !== "" || locality.trim() !== "" || addressRequisites.trim() !== "" || index.trim() !== "" || emailRequisites.trim() !== "" || phoneRequisites.trim() !== "" || fax.trim() !== "")
            setExistRequisites(true)
        else
            setExistRequisites(false)
        if (contactName.trim() === "" && locality.trim() === "" && addressRequisites.trim() === "" && index.trim() === "" && emailRequisites.trim() === "" && phoneRequisites.trim() === "" && fax.trim() === "") {
            setContactNameValid(false);
            setLocalityValid(false);
            setAddressRequisitesValid(false);
            setIndexValid(false);
            setEmailRequisitesValid(false);
            setPhoneRequisitesValid(false);
            setFaxValid(false);
        }
        setExistRequisitesValid(false);
    }, [contactName, locality, addressRequisites, index, emailRequisites, phoneRequisites, fax, setExistRequisites,
        setExistRequisitesValid, setContactNameValid, setLocalityValid, setAddressRequisitesValid, setIndexValid, setEmailRequisitesValid, setPhoneRequisitesValid, setFaxValid
    ])

    const { t } = useTranslation();

    return (
        <div className={"addcontractorform" + (openrequisites ? ' Active' : '')}>
            <div className="addcontractorforminputblock">
                <input value={companyName} onChange={e => { setCompanyName(e.target.value); }} placeholder=" " id="RequisitesCompanyName" className="addcontractorforminput" />
                <label htmlFor="RequisitesCompanyName" className="addcontractorformlabel">{t("workplace.menuitems.requisites.companyname")}</label>
            </div>
            <div className="addcontractorforminputblock">
                <input value={contactName} onChange={e => { setContactName(e.target.value); setContactNameValid(false); }} placeholder=" " id="RequisitesContactName" className="addcontractorforminput" />
                <label htmlFor="RequisitesContactName" className="addcontractorformlabel">{t("workplace.menuitems.requisites.contactname")}</label>
                <div className={contactNameValid ? "addcontractorforminputvalid Invalid" : "addcontractorforminputvalid"}>{t("workplace.menuitems.requisites.validcontactname")}</div>
            </div>
            <div className="addcontractorforminputblock">
                <input value={locality} onChange={e => { setLocality(e.target.value); setLocalityValid(false); }} placeholder=" " id="RequisitesLocality" className="addcontractorforminput" />
                <label htmlFor="RequisitesLocality" className="addcontractorformlabel">{t("workplace.menuitems.requisites.locality")}</label>
                <div className={localityValid ? "addcontractorforminputvalid Invalid" : "addcontractorforminputvalid"}>{t("workplace.menuitems.requisites.validlocality")}</div>
            </div>
            <div className="addcontractorforminputblock">
                <input value={addressRequisites} onChange={e => { setAddressRequisites(e.target.value); setAddressRequisitesValid(false); }} placeholder=" " id="RequisitesAddress" className="addcontractorforminput" />
                <label htmlFor="RequisitesAddress" className="addcontractorformlabel">{t("workplace.menuitems.requisites.address")}</label>
                <div className={addressRequisitesValid ? "addcontractorforminputvalid Invalid" : "addcontractorforminputvalid"}>{t("workplace.menuitems.requisites.validaddress")}</div>
            </div>
            <div className="addcontractorforminputblock">
                <input value={department} onChange={e => { setDepartment(e.target.value); }} placeholder=" " id="RequisitesDepartment" className="addcontractorforminput" />
                <label htmlFor="RequisitesDepartment" className="addcontractorformlabel">{t("workplace.menuitems.requisites.department")}</label>
            </div>
            <div className="addcontractorforminputgroupblock">
                <div className="addcontractorforminputblock">
                    <input value={index} onChange={e => { setIndex(e.target.value); setIndexValid(false); }} placeholder=" " id="RequisitesIndex" className="addcontractorforminput" />
                    <label htmlFor="RequisitesIndex" className="addcontractorformlabel">{t("workplace.menuitems.requisites.index")}</label>
                    <div className={indexValid ? "addcontractorforminputvalid Invalid" : "addcontractorforminputvalid"}>{t("workplace.menuitems.requisites.validindex")}</div>
                </div>
                <div className="addcontractorforminputblock">
                    <input value={emailRequisites} onChange={e => { setEmailRequisites(e.target.value); setEmailRequisitesValid(false); }} placeholder=" " id="RequisitesEmail" className="addcontractorforminput" />
                    <label htmlFor="RequisitesEmail" className="addcontractorformlabel">E-mail</label>
                    <div className={emailRequisitesValid ? "addcontractorforminputvalid Invalid" : "addcontractorforminputvalid"}>{t("workplace.menuitems.requisites.validemail")}</div>
                </div>
            </div>
            <div className="addcontractorforminputgroupblock">
                <div className="addcontractorforminputblock">
                    <input value={phoneRequisites} onChange={e => { setPhoneRequisites(e.target.value); setPhoneRequisitesValid(false); }} placeholder=" " id="RequisitesPhone" className="addcontractorforminput" />
                    <label htmlFor="RequisitesPhone" className="addcontractorformlabel">{t("workplace.menuitems.requisites.phone")}</label>
                    <div className={phoneRequisitesValid ? "addcontractorforminputvalid Invalid" : "addcontractorforminputvalid"}>{t("workplace.menuitems.requisites.validphone")}</div>
                </div>
                <div className="addcontractorforminputblock">
                    <input value={fax} onChange={e => { setFax(e.target.value); setFaxValid(false); }} placeholder=" " id="RequisitesFax" className="addcontractorforminput" />
                    <label htmlFor="RequisitesFax" className="addcontractorformlabel">{t("workplace.menuitems.requisites.fax")}</label>
                    <div className={faxValid ? "addcontractorforminputvalid Invalid" : "addcontractorforminputvalid"}>{t("workplace.menuitems.requisites.validfax")}</div>
                </div>
            </div>
            <div className="addcontractorforminputgroupblock">
                <div className="addcontractorforminputblock">
                    <input value={inn} onChange={e => { setINN(e.target.value); }} placeholder=" " id="RequisitesINN" className="addcontractorforminput" />
                    <label htmlFor="RequisitesINN" className="addcontractorformlabel">{t("workplace.menuitems.requisites.inn")}</label>
                </div>
                <div className="addcontractorforminputblock">
                    <input value={offerperiod} onChange={e => { setOfferPeriod(e.target.value); }} placeholder=" " id="RequisitesOfferPeriod" className="addcontractorforminput" />
                    <label htmlFor="RequisitesOfferPeriod" className="addcontractorformlabel">{t("workplace.menuitems.requisites.offerperiod")}</label>
                </div>
            </div>
        </div>
    )
}
