import { React } from 'react'
import MaskedInput from 'react-text-mask'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'

const defaultMaskOptions = {
    prefix: '',
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: ' ',
    allowDecimal: false,
    decimalSymbol: '.',
    decimalLimit: 0, // how many digits allowed after the decimal
    integerLimit: 10, // limit length of integer numbers
    allowNegative: false,
    allowLeadingZeroes: false,
}

const CurrencyInput = ({ maskOptions, ...inputProps }) => {
    defaultMaskOptions.suffix = inputProps.suffix;
    const currencyMask = createNumberMask({ ...defaultMaskOptions, ...maskOptions, })
    return <MaskedInput mask={currencyMask} {...inputProps} />
}

CurrencyInput.defaultProps = {
    inputMode: 'numeric',
    maskOptions: {},
}

export default CurrencyInput