import { React, useEffect, useState } from 'react'
import './localization.css'
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import { NavLink } from 'react-router-dom'
import  { IoIosArrowDropdown }  from 'react-icons/io'

export default function Localization() {

    const { t } = useTranslation();
    const [items, setItems] = useState([]);

    useEffect(() => {
        axios.get("/localization")
            .then((response) => { setItems(response.data.Items); });
    }, [setItems]);

    const Tree = ({item}) => {
        return (
            <div className={item.Translations.length > 0 ? "LocalizationItemChild" : "LocalizationItem" }>
                <div className={item.Parent ? "TextPhrase Parent" : "TextPhrase"} onClick={(e)=>{e.target.classList.toggle("Open")}}>{item.Phrases.TextPhrase}{item.Parent ? <IoIosArrowDropdown className="LocalizationItemArrow"/> : null}</div>
                {
                    item.Parent
                    ?<div className="LocalizationChildBlock">
                        {
                            item.ChildList.map(child=>
                                (
                                    <Tree item={child} key={child.Phrases.Id}/>
                                )
                            )
                        }
                    </div>
                    : null
                }
                {
                    item.Translations.length > 0
                    ?<div className="LocalizationChildBlockTranslate">
                        {
                            item.Translations.map(translate=>
                                (
                                    <div key={translate.Id} className="LocalizationChildBlockTranslateItem">
                                        <div className="LocalizationChildBlockTranslateItemLanguage">{translate.Languages.FullName.TextPhrase}</div>
                                        <div>{translate.TranslationText}</div>
                                    </div>
                                )
                            )
                        } 
                    </div>
                    : null
                }
            </div>
        );
    }

    return (
        <div id="localization">
            <NavLink to="/workplace/addcontractor" className="AddButton">{t("workplace.menuitems.localization.addbutton")}</NavLink>
            <div>
                {
                    items.length > 0
                        ?
                        <div>
                        {
                            items.map(item => (
                                <Tree item={item} key={item.Phrases.Id}/>
                            ))
                        }                    
                        </div>
                        : <div>Нет данных</div>
                }
            </div>
        </div>
    )
}
