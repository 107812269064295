import { React, useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import axios from 'axios'
import { useTranslation } from 'react-i18next'

export default function EditUsersContractors() {

    const { lupid } = useParams();
    const history = useHistory();
    const { t } = useTranslation();
    const [name, setName] = useState("");
    const [nameValid, setNameValid] = useState(true);
    const [post, setPost] = useState("");
    const [postValid, setPostValid] = useState(true);
    const [phone, setPhone] = useState("");
    const [phoneValid, setPhoneValid] = useState(true);
    const [email, setEmail] = useState("");
    const [emailValid, setEmailValid] = useState(true);
    const [role, setRole] = useState(0);
    const [roleValid, setRoleValid] = useState(true);
    const [login, setLogin] = useState("");
    const [password, setPassword] = useState("");
    const [passwordValid, setPasswordValid] = useState(true);
    const [confirmPassword, setConfirmPassword] = useState("");
    const [confirmPasswordValid, setConfirmPasswordValid] = useState(true);

    const editUser = () => {
        var valid = true;
        if (name.trim().length < 1 || post.trim().length < 1 || phone.trim().length < 1 || email.trim().length < 1) {
            if (name.trim().length < 1) setNameValid(false);
            if (post.trim().length < 1) setPostValid(false);
            if (phone.trim().length < 1) setPhoneValid(false);
            if (email.trim().length < 1) setEmailValid(false);
            valid = false;
        }
        if(password.length>0)
        {
            if(password.length<6)
            {
                setPasswordValid(false)
                valid = false;
            }
            if(password !== confirmPassword)
            {
                setConfirmPasswordValid(false)
                valid = false;
            }
        }
        if (!valid) return;
        axios.post("/edituserscontractors", { LUPId: lupid, FullName: name, Post: post, Phone: phone, Email: email, Role:role, Password:password }).then(()=>history.goBack());
    }

    useEffect(() => {
        axios.get("/edituserscontractors/" + lupid).then(response => {
            setName(response.data.Peoples.FullName?response.data.Peoples.FullName:"");
            setPost(response.data.Peoples.Post?response.data.Peoples.Post:"");
            setPhone(response.data.Peoples.Phone?response.data.Peoples.Phone:"");
            setEmail(response.data.Peoples.Email?response.data.Peoples.Email:"");
            setLogin(response.data.User.UserName);
            setRole(response.data.TypeUsers.Id);
        })
    }, [lupid])

    return (
        <div id="addUsersContractors">
            <div id="AddUsersContractorsForm">
                <div id="AddUsersContractorsTitle">
                    <div>{t("workplace.menuitems.users.editusertitle")}</div>
                </div>
                <div className="forminputmonoblock">
                <div className="forminputblock">
                        <input autoFocus autoComplete="new-password" id="NameUser" value={name} onChange={e => { setName(e.target.value); setNameValid(true); }} placeholder=" " className={nameValid ? "forminput" : "forminput Invalid"}/>
                        <label htmlFor="NameUser" className="formlabel">{t("workplace.menuitems.users.nameuser")}</label>
                        <div className={nameValid ? "forminputvalid" : "forminputvalid Invalid"}>{t("workplace.menuitems.users.nameuservalid")}</div>
                    </div>
                </div>
                <div className="forminputgroupblock">
                    <div className="forminputblock">
                        <input autoComplete="new-password" id="PostUser" value={post} onChange={e => { setPost(e.target.value); setPostValid(true); }} placeholder=" " className={postValid ? "forminput" : "forminput Invalid"} />
                        <label htmlFor="PostUser" className="formlabel">{t("workplace.menuitems.users.postuser")}</label>
                        <div className={postValid ? "forminputvalid" : "forminputvalid Invalid"}>{t("workplace.menuitems.users.postuservalid")}</div>
                    </div>
                    <div className="forminputblock">
                        <input autoComplete="new-password" id="PhoneUser" value={phone} onChange={e => { setPhone(e.target.value); setPhoneValid(true); }} placeholder=" " className={phoneValid ? "forminput" : "forminput Invalid"} />
                        <label htmlFor="PhoneUser" className="formlabel">{t("workplace.menuitems.users.phoneuser")}</label>
                        <div className={phoneValid ? "forminputvalid" : "forminputvalid Invalid"}>{t("workplace.menuitems.users.phoneuservalid")}</div>
                    </div>
                </div>
                <div className="forminputgroupblock">
                    <div className="forminputblock">
                        <input autoComplete="new-password" id="EmailUser" value={email} onChange={e => { setEmail(e.target.value); setEmailValid(true); }} placeholder=" " className={emailValid ? "forminput" : "forminput Invalid"} />
                        <label htmlFor="EmailUser" className="formlabel">E-mail</label>
                        <div className={emailValid ? "forminputvalid" : "forminputvalid Invalid"}>{t("workplace.menuitems.users.emailvalid")}</div>
                    </div>
                    <div className="forminputblock select">
                        <select id="RoleUser" placeholder=" " className="forminput select" value={role} onChange={e => { setRole(e.target.value); setRoleValid(true); }} >
                            <option hidden>{t("workplace.menuitems.users.roleuservalid")}</option>
                            <option value={3}>{t("workplace.menuitems.users.admin_contractor")}</option>
                            <option value={4}>{t("workplace.menuitems.users.manager_contractor")}</option>
                            <option value={5}>{t("workplace.menuitems.users.engineer_contractor")}</option>
                        </select>
                        <div htmlFor="RoleUser" className="formlabel">{t("workplace.menuitems.users.roleuser")}</div>
                        <div className={roleValid ? "forminputvalid" : "forminputvalid Invalid"}>{t("workplace.menuitems.users.roleuservalid")}</div>
                    </div>
                </div>
                <div className="forminputmonoblock">
                    <div className="forminputblock select">
                        <input disabled autoComplete="new-password" id="LoginUser" value={login} className="forminput disabled" />
                        <label htmlFor="LoginUser" className="formlabel">{t("workplace.menuitems.users.loginuseredit")}</label>
                    </div>
                </div>
                <div className="forminputgroupblock">
                    <div className="forminputblock">
                        <input autoComplete="new-password" id="PasswordUser" value={password} onChange={e => { setPassword(e.target.value); setPasswordValid(true); setConfirmPasswordValid(true); }} placeholder=" " type="password" className={passwordValid ? "forminput" : "forminput Invalid"} />
                        <label htmlFor="PasswordUser" className="formlabel">{t("workplace.menuitems.users.passworduser")}</label>
                        <div className={passwordValid ? "forminputvalid" : "forminputvalid Invalid"}>{t("workplace.menuitems.users.passworduservalid")}</div>
                    </div>
                    <div className="forminputblock">
                        <input autoComplete="new-password" id="PasswordConfirmUser" value={confirmPassword} onChange={e => { setConfirmPassword(e.target.value); setConfirmPasswordValid(true); }} placeholder=" " type="password" className={confirmPasswordValid ? "forminput" : "forminput Invalid"} />
                        <label htmlFor="PasswordConfirmUser" className="formlabel">{t("workplace.menuitems.users.passwordconfirmuser")}</label>
                        <div className={confirmPasswordValid ? "forminputvalid" : "forminputvalid Invalid"}>{t("workplace.menuitems.users.passwordconfirmuservalid")}</div>
                    </div>
                </div>
            </div>
            <div id="AddUsersContractorsButtonsBlock">
                <div id="AddUsersContractorsButtonAdd" onClick={() => editUser()}>{t("buttons.save")}</div>
                <div id="AddUsersContractorsButtonBack" onClick={() => history.goBack()}>{t("buttons.back")}</div>
            </div>
        </div>
    )
}
