import { React, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { MdArrowBackIosNew } from 'react-icons/md'

export default function ClientTransaction({ item }) {

  const { t } = useTranslation();
  const [clientTransactionDetalization, setClientTransactionDetalization] = useState(false);

  return (
    <div className='clientTransactionCPBlock' key={"Transaction_" + item.Transitions.Id}>
      <NavLink className='clientTransactionNavLink' to={"/workplace/transactions/transaction/" + item.Transitions.Id}>
        <div className="clientTransactionBlock">
          <div className='clientTransactionTitleBlock'>
            <div className='clientTransactionTitle'>{item.Transitions.Title}</div>
            <div className={"clientTransactionTitleCircle" + (item.Overdue ? " clientTransactionTitleRedCircle" : item.Today ? "clientTransactionTitleYellowCircle" : "") + (clientTransactionDetalization ? " hidden" : "")}>            </div>
          </div>
          <div className={clientTransactionDetalization ? "clientTransactionDetalization Open" : "clientTransactionDetalization"}>
            <div className="clientTransactionItem clientTransactionItemTaskBlock">
              <div>{t("workplace.menuitems.client.budget")}:
                {
                  item.Transitions.Price && item.Transitions.Price !== ""
                    ? item.Transitions.Price
                    : " -"
                }
              </div>
              <div className=
                {
                  item.NotTasks
                    ? "clientTransactionItemTaskColorBlock clientTransactionItemTaskGray"
                    : item.Overdue
                      ? "clientTransactionItemTaskColorBlock clientTransactionItemTaskRed"
                      : item.Today
                        ? "clientTransactionItemTaskColorBlock clientTransactionItemTaskYellow"
                        : "clientTransactionItemTaskColorBlock clientTransactionItemTaskGreen"
                }>
                {
                  item.NotTasks
                    ? t("workplace.menuitems.client.notasks")
                    : item.Overdue
                      ? t("workplace.menuitems.client.overdue")
                      : item.Today
                        ? t("workplace.menuitems.client.today")
                        : item.Tomorrow
                          ? t("workplace.menuitems.client.tomorrow")
                          : <span>{item.Days} {t("workplace.menuitems.client.days")}</span>
                }
              </div>
            </div>
            <div className="clientTransactionItem">{t("workplace.menuitems.client.stage")}: {item.Transitions.Boards.Title}</div>
          </div>
        </div>
      </NavLink>
      <div onClick={() => setClientTransactionDetalization(!clientTransactionDetalization)}>
        <MdArrowBackIosNew className={"clientTransactionDetalizationButton" + (clientTransactionDetalization ? " Open" : "")} />
      </div>
      {/* <div className='clientTransactionCPButton' onClick={() => addcp(item.Transitions.Id)}>{t("workplace.menuitems.client.cpbutton")}</div> */}
    </div>)
}
