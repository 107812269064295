import React, { useState } from 'react'
import "./header.css"
import "../../App.css"
import { useTranslation } from 'react-i18next'
// import maintitle from "../../img/icons/mainsite/logo.svg"
import { Link } from "react-scroll"
import { BiLogInCircle } from 'react-icons/bi'
import { HiMenuAlt3 } from 'react-icons/hi'
import { BsX } from 'react-icons/bs'
import Login from '../login/login'
import Check from './checklogin'
import Cookies from 'universal-cookie'

export default function Header() {

    const cookies = new Cookies();

    const { t, i18n } = useTranslation();
    const [languageblock, setlanguageblock] = useState(false);
    const [languageblockMobile, setlanguageblockMobile] = useState(false);
    const [mobilemenulist, setmobilemenulist] = useState(false);
    const [loginblock, setloginblock] = useState(false);

    onscroll = () => {
        setlanguageblock(false);
        setlanguageblockMobile(false);
        setmobilemenulist(false);
    };

    const changeLanguage = (language) => {
        i18n.changeLanguage(language);
        localStorage.setItem('language', language);
    };

    const languageBlockElement = document.getElementById("MSheaderPCMenuItemLanguageBlock");
    document.addEventListener("mousedown", (event) => { if (languageBlockElement != null && !languageBlockElement.contains(event.target)) setlanguageblock(false) });

    return (
        <div id="MSheaderlogin">

            <div id="MSheader">
                <div id="MSHeaderMobileMenuList" className={mobilemenulist ? 'Open MobileBlock' : null + ' MobileBlock'}>
                    <div className="MSHeaderMobileMenuItem MSHeaderMobileMenuItemZUP"><Link activeClass="active" to="MSBodySecondBlock" spy={true} smooth={true} offset={-117} duration={500}>{t("mainsite.header.possibilities")}</Link></div>
                    <div className="MSHeaderMobileMenuItem MSHeaderMobileMenuItemZUP"><Link activeClass="active" to="MSBodyThirdBlock" spy={true} smooth={true} offset={-117} duration={500}>{t("mainsite.header.link")}</Link></div>
                    <div className="MSHeaderMobileMenuItem MSHeaderMobileMenuItemZUP"><Link activeClass="active" to="MSfooter" spy={true} smooth={true} offset={-117} duration={500}>{t("mainsite.header.aboutus")}</Link></div>
                    <div onClick={() => setlanguageblockMobile(!languageblockMobile)} className="MSHeaderMobileMenuItem MSHeaderMobileMenuItemZUP">{t("mainsite.header.language")}</div>
                    <div id="MSHeaderMobileMenuLanguageList" className={languageblockMobile ? 'Open' : null}>
                        <div className="MSHeaderMobileMenuItem" onClick={() => { changeLanguage("en"); setmobilemenulist(false); }}>{t("mainsite.languages.en")}</div>
                        {cookies.get(['url'])!=='https://api.crm-de.com'?<div className="MSHeaderMobileMenuItem" onClick={() => { changeLanguage("ru"); setmobilemenulist(false); }}>{t("mainsite.languages.ru")}</div>:null}
                        <div className="MSHeaderMobileMenuItem" onClick={() => { changeLanguage("de"); setmobilemenulist(false); }}>{t("mainsite.languages.de")}</div>
                    </div>
                </div>

                <div className="PCBlock CRMcontainer">
                    <div id="MSheaderPC">

                        {/* <Link activeClass="active" to="MainSiteBody" spy={true} offset={-110} smooth={true} duration={500}><img id="MSheaderPCLogo" src={maintitle} alt="" /></Link> */}

                        <Link activeClass="active" to="MainSiteBody" spy={true} offset={-110} smooth={true} duration={500}>
                            <div id="MSheaderPCLogoBlock">
                                <div id="MSheaderPCLogoWhiteText">CRM</div>
                                <div id="MSheaderPCLogoTextBlock">
                                    <div id="MSheaderPCLogoColorBlock">
                                        <div id="MSheaderPCLogoColorBlack" className="msheaderPCLogoColor"></div>
                                        <div id="MSheaderPCLogoColorRed" className="msheaderPCLogoColor"></div>
                                        <div id="MSheaderPCLogoYellow" className="msheaderPCLogoColor"></div>
                                    </div>
                                    <div id="MSheaderPCLogoBlackText">DE</div>
                                </div>
                            </div>
                        </Link>

                        <div id="MSheaderPCMenu">
                            <div className="MSheaderPCMenuItem"><Link activeClass="active" to="MSBodySecondBlock" spy={true} smooth={true} offset={-113} duration={500}>{t("mainsite.header.possibilities")}</Link></div>
                            <div className="MSheaderPCMenuItem"><Link activeClass="active" to="MSBodyThirdBlock" spy={true} smooth={true} offset={-113} duration={500}>{t("mainsite.header.link")}</Link></div>
                            <div className="MSheaderPCMenuItem"><Link activeClass="active" to="MSfooter" spy={true} smooth={true} offset={-113} duration={500}>{t("mainsite.header.aboutus")}</Link></div>
                            <div onClick={() => setlanguageblock(!languageblock)} id="MSheaderPCMenuItemLanguageMainBlock" className="MSheaderPCMenuItem">
                                <div>{t("mainsite.header.language")}</div>
                                <div id="MSheaderPCMenuItemLanguageBlock" className={languageblock ? 'Open' : null}>
                                    <div className="MSheaderPCMenuItemLanguageBlockItem" onClick={() => changeLanguage("en")}>{t("mainsite.languages.en")}</div>
                                    {cookies.get(['url'])!=='https://api.crm-de.com'?<div className="MSheaderPCMenuItemLanguageBlockItem" onClick={() => changeLanguage("ru")}>{t("mainsite.languages.ru")}</div>:null}
                                    <div className="MSheaderPCMenuItemLanguageBlockItem" onClick={() => changeLanguage("de")}>{t("mainsite.languages.de")}</div>
                                </div>
                            </div>
                            <Check setloginblock={setloginblock} setlanguageblock={setlanguageblock} loginblock={loginblock} />
                        </div>
                    </div>
                </div>
                <div className="MobileBlock" id="MSheaderMobileBlock">
                    <div id="MSheaderMobile">
                        <div onClick={() => { setmobilemenulist(!mobilemenulist); setloginblock(false); }}>{mobilemenulist ? <BsX className="MSheaderMobileMenuButton" /> : <HiMenuAlt3 className="MSheaderMobileMenuButton" />}</div>
                        <div>
                            <div id="MSheaderPCLogoBlock">
                                <div id="MSheaderPCLogoWhiteText">CRM</div>
                                <div id="MSheaderPCLogoTextBlock">
                                    <div id="MSheaderPCLogoColorBlock">
                                        <div id="MSheaderPCLogoColorBlack" className="msheaderPCLogoColor"></div>
                                        <div id="MSheaderPCLogoColorRed" className="msheaderPCLogoColor"></div>
                                        <div id="MSheaderPCLogoYellow" className="msheaderPCLogoColor"></div>
                                    </div>
                                    <div id="MSheaderPCLogoBlackText">DE</div>
                                </div>
                            </div>
                            {/* <Link activeClass="active" to="MainSiteBody" spy={true} offset={-110} smooth={true} duration={500}><img src={maintitle} alt="" /></Link> */}
                        </div>
                        <div>{loginblock ? <div id="MSheaderMobileMenuLoginButtonCross" onClick={() => { setmobilemenulist(false); setloginblock(!loginblock); }}><BsX className="MSheaderMobileMenuButton" /></div> : <button id="MSheaderMobileMenuLoginButton" onClick={() => { setmobilemenulist(false); setloginblock(!loginblock); }}><BiLogInCircle id="MSheaderPCMenuLoginButtonTitleIcon" /></button>}</div>
                        <div id="MSheaderMobileMenuItemList"></div>
                    </div>
                </div>
            </div>

            <Login loginblock={loginblock} setloginblock={setloginblock} />

        </div>
    )
}
