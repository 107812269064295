import { React, useState, useEffect } from 'react'
import './addcontractor.css'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import Addcontractordata from './addcontractordata'
import Addcontractorrequisites from './addcontractorrequisites'
import { BsExclamationCircleFill } from 'react-icons/bs'

export default function Addcontractor() {

    const history = useHistory();
    const { t } = useTranslation();

    const [data, setData] = useState([]);

    const [dataFormValid, setDataFormValid] = useState(false);
    const [name, setName] = useState("");
    const [nameValid, setNameValid] = useState(false);
    const [phone, setPhone] = useState("");
    const [phoneValid, setPhoneValid] = useState(false);
    const [email, setEmail] = useState("");
    const [emailValid, setEmailValid] = useState(false);
    const [country, setCountry] = useState(0);
    const [countryValid, setCountryValid] = useState(false);
    const [language, setLanguage] = useState(0);
    const [languageValid, setLanguageValid] = useState(false);
    const [currency, setCurrency] = useState(0);
    const [currencyValid, setCurrencyValid] = useState(false);
    const [address, setAddress] = useState("");
    const [addressValid, setAddressValid] = useState(false);

    const [access, setAccess] = useState(false);
    const [namePeople, setNamePeople] = useState("");
    const [namePeopleValid, setNamePeopleValid] = useState(false);
    const [login, setLogin] = useState("");
    const [loginValid, setLoginValid] = useState(false);
    const [password, setPassword] = useState("");
    const [passwordValid, setPasswordValid] = useState(false);
    const [passwordConfirm, setPasswordConfirm] = useState("");
    const [passwordConfirmValid, setPasswordConfirmValid] = useState(false);
    const [checkFreeLogin, setCheckFreeLogin] = useState(true);

    const [openrequisites, setOpenRequisetes] = useState(false);
    const [existRequisites, setExistRequisites] = useState(false);
    const [existRequisitesValid, setExistRequisitesValid] = useState(false);
    const [companyName, setCompanyName] = useState("");
    const [contactName, setContactName] = useState("");
    const [contactNameValid, setContactNameValid] = useState(false);
    const [locality, setLocality] = useState("");
    const [localityValid, setLocalityValid] = useState(false);
    const [addressRequisites, setAddressRequisites] = useState("");
    const [addressRequisitesValid, setAddressRequisitesValid] = useState(false);
    const [index, setIndex] = useState("");
    const [indexValid, setIndexValid] = useState(false);
    const [emailRequisites, setEmailRequisites] = useState("");
    const [emailRequisitesValid, setEmailRequisitesValid] = useState(false);
    const [phoneRequisites, setPhoneRequisites] = useState("");
    const [phoneRequisitesValid, setPhoneRequisitesValid] = useState(false);
    const [department, setDepartment] = useState("");
    const [inn, setINN] = useState("");
    const [offerperiod, setOfferPeriod] = useState("");
    const [fax, setFax] = useState("");
    const [faxValid, setFaxValid] = useState(false);

    useEffect(() => {
        axios.get("/addcontractor")
            .then(
                (result) => {
                    setData(result.data);
                })
    }, []);

    function AddContractorSubmit() {

        var valid = true;

        if (name === "" || phone === "" || email === "" || address === "" || country === 0 || language === 0 || currency === 0) {
            if (name === "")
                setNameValid(true);
            if (phone === "")
                setPhoneValid(true);
            if (email === "")
                setEmailValid(true);
            if (address === "")
                setAddressValid(true);
            if (country === 0)
                setCountryValid(true);
            if (language === 0)
                setLanguageValid(true);
            if (currency === 0)
                setCurrencyValid(true);
            valid = false;
        }

        if (access && (namePeople === "" || login.length < 3 || password.length < 6 || passwordConfirm === "" || password !== passwordConfirm)) {
            if (namePeople === "")
                setNamePeopleValid(true);
            if (login.length < 3)
                setLoginValid(true);
            if (password.length < 6)
                setPasswordValid(true);
            if (password !== passwordConfirm)
                setPasswordConfirmValid(true);

            valid = false;
        }

        if (access && !checkFreeLogin) valid = false;

        if (!valid) setDataFormValid(true);

        if (contactName.trim() !== "" || locality.trim() !== "" || addressRequisites.trim() !== "" || index.trim() !== "" || emailRequisites.trim() !== "" || phoneRequisites.trim() !== "" || fax.trim() !== "") {
            if (contactName.trim() === "") { setContactNameValid(true); valid = false; setExistRequisitesValid(true); }
            if (locality.trim() === "") { setLocalityValid(true); valid = false; setExistRequisitesValid(true); }
            if (addressRequisites.trim() === "") { setAddressRequisitesValid(true); valid = false; setExistRequisitesValid(true); }
            if (index.trim() === "") { setIndexValid(true); valid = false; setExistRequisitesValid(true); }
            if (emailRequisites.trim() === "") { setEmailRequisitesValid(true); valid = false; setExistRequisitesValid(true); }
            if (phoneRequisites.trim() === "") { setPhoneRequisitesValid(true); valid = false; setExistRequisitesValid(true); }
            if (fax.trim() === "") { setFaxValid(true); valid = false; setExistRequisitesValid(true); }
        }

        if (!valid) return;

        let formData = new FormData();
        formData.append('Title', name);
        formData.append('Address', address);
        formData.append('Email', email);
        formData.append('Phone', phone);
        formData.append('CountriesId', country);
        formData.append('LanguagesId', language);
        formData.append('CurrencyId', currency);
        formData.append('AccessSystem', access);
        formData.append('Name', name);
        formData.append('Login', login);
        formData.append('Password', password);
        if (existRequisites) {
            formData.append('Requisites', true);
            formData.append('CompanyName', companyName);
            formData.append('ContactName', contactName);
            formData.append('Locality', locality);
            formData.append('AddressRequisites', addressRequisites);
            formData.append('Index', index);
            formData.append('EmailRequisites', emailRequisites);
            formData.append('PhoneRequisites', phoneRequisites);
            formData.append('Fax', fax);
            formData.append('Department', department);
            formData.append('INN', inn);
            formData.append('OfferPeriod', offerperiod);
            // formData.append('Logo', logo);
        }
        axios.post("/addcontractor", formData, { headers: { 'Content-Type': 'multipart/form-data' } }).then(() => window.location.href = "/workplace/contractors");
    }

    return (
        <div id="addcontractor">

            <div id='Addcontractorpageblock'>
                <div onClick={() => setOpenRequisetes(false)} className={'addcontractorpage' + (openrequisites ? "" : ' Active')}>
                    <BsExclamationCircleFill className={"addcontractorforminputvalid addcontractorValidIcon" + (dataFormValid ? " Invalid" : "")} />
                    <div>{t("workplace.menuitems.contractor.data")}</div>
                </div>
                <div onClick={() => setOpenRequisetes(true)} className={'addcontractorpage' + (openrequisites ? ' Active' : "")}>
                    <BsExclamationCircleFill className={"addcontractorforminputvalid addcontractorValidIcon" + (existRequisitesValid ? " Invalid" : "")} />
                    <div>{t("workplace.menuitems.contractor.requisites")}</div>
                </div>
            </div>

            <Addcontractordata
                setDataFormValid={setDataFormValid}
                openrequisites={openrequisites}
                data={data}
                name={name} setName={setName}
                nameValid={nameValid} setNameValid={setNameValid}
                phone={phone} setPhone={setPhone}
                phoneValid={phoneValid} setPhoneValid={setPhoneValid}
                email={email} setEmail={setEmail}
                emailValid={emailValid} setEmailValid={setEmailValid}
                country={country} setCountry={setCountry}
                countryValid={countryValid} setCountryValid={setCountryValid}
                language={language} setLanguage={setLanguage}
                languageValid={languageValid} setLanguageValid={setLanguageValid}
                currency={currency} setCurrency={setCurrency}
                currencyValid={currencyValid} setCurrencyValid={setCurrencyValid}
                address={address} setAddress={setAddress}
                addressValid={addressValid} setAddressValid={setAddressValid}

                access={access} setAccess={setAccess}
                namePeople={namePeople} setNamePeople={setNamePeople}
                namePeopleValid={namePeopleValid} setNamePeopleValid={setNamePeopleValid}
                login={login} setLogin={setLogin}
                loginValid={loginValid} setLoginValid={setLoginValid}
                password={password} setPassword={setPassword}
                passwordValid={passwordValid} setPasswordValid={setPasswordValid}
                passwordConfirm={passwordConfirm} setPasswordConfirm={setPasswordConfirm}
                passwordConfirmValid={passwordConfirmValid} setPasswordConfirmValid={setPasswordConfirmValid}
                checkFreeLogin={checkFreeLogin} setCheckFreeLogin={setCheckFreeLogin}
            />

            <Addcontractorrequisites
                openrequisites={openrequisites}
                setExistRequisitesValid={setExistRequisitesValid}
                setExistRequisites={setExistRequisites}
                companyName={companyName} setCompanyName={setCompanyName}
                contactName={contactName} setContactName={setContactName}
                contactNameValid={contactNameValid} setContactNameValid={setContactNameValid}
                locality={locality} setLocality={setLocality}
                localityValid={localityValid} setLocalityValid={setLocalityValid}
                addressRequisites={addressRequisites} setAddressRequisites={setAddressRequisites}
                addressRequisitesValid={addressRequisitesValid} setAddressRequisitesValid={setAddressRequisitesValid}
                index={index} setIndex={setIndex}
                indexValid={indexValid} setIndexValid={setIndexValid}
                emailRequisites={emailRequisites} setEmailRequisites={setEmailRequisites}
                emailRequisitesValid={emailRequisitesValid} setEmailRequisitesValid={setEmailRequisitesValid}
                phoneRequisites={phoneRequisites} setPhoneRequisites={setPhoneRequisites}
                phoneRequisitesValid={phoneRequisitesValid} setPhoneRequisitesValid={setPhoneRequisitesValid}
                department={department} setDepartment={setDepartment}
                inn={inn} setINN={setINN}
                offerperiod={offerperiod} setOfferPeriod={setOfferPeriod}
                fax={fax} setFax={setFax}
                faxValid={faxValid} setFaxValid={setFaxValid}
            />


            <div id="addcontractorbuttonsblock">
                <div id="addcontractorsavebutton" onClick={(e) => { e.preventDefault(); AddContractorSubmit(); }}>{t("buttons.add")}</div>
                <div id="addcontractorcancelbutton" onClick={() => history.goBack()}>{t("buttons.back")}</div>
            </div>
        </div>
    )
}
