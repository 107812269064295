import { React, useState, useEffect, useRef } from 'react'
import './commercialProposal.css'
import { NavLink, useHistory, useParams } from 'react-router-dom'
import { IoIosArrowBack } from 'react-icons/io'
import axios from 'axios'
import Cookies from 'universal-cookie'
import RequisitesContractor from '../../../contractor/requisitesContractor'
import RequisitesClient from '../../../client/requisitesClient'
import { useTranslation } from 'react-i18next'
import i18n from 'i18next'
import { BsPlusCircle } from 'react-icons/bs'
import CommercialProposalTableRow from './commercialProposalTableRow'
import { MdOutlineFullscreen, MdOutlineFullscreenExit } from 'react-icons/md'
import EditVAT from './editVAT'
import { BsFillPrinterFill } from 'react-icons/bs'
import { HiDownload } from 'react-icons/hi'
// import AngleTopRightCPModal from './angleTopRightCPModal'
// import FooterCPModal from './footerCPModal'

export default function CommercialProposal({ commercialProposals, setCommercialProposals }) {

    const history = useHistory();
    const cookies = new Cookies();
    const { t } = useTranslation();

    const { cpid } = useParams();
    const { transactionid } = useParams();
    const Page = useRef();
    const [widthPage, setWidthPage] = useState(0);

    const [contractorid, setContractorId] = useState(0);
    const [requisitesContractorBlock, setRequisitesContractorBlock] = useState(false);
    // const [angleTopRightBlock, setAngleTopRightBlock] = useState(false);
    // const [footerCPModal, setFooterCPModal] = useState(false);
    const [requisitesContractorCP, setRequisitesContractorCP] = useState();

    const [clientId, setClientId] = useState(0);
    const [requisitesClientBlock, setRequisitesClientBlock] = useState(false);
    const [requisitesClientCP, setRequisitesClientCP] = useState();

    const [commercialProposalItems, setCommercialProposalItems] = useState(
        {
            CPId: cpid,
            Sender: "",
            Date: "",
            LetterSubject: "",
            Greetings: "",
            Preamble: "",
            VAT: true,
            VATValue: 20,
            TableSubtotal: "",
            TableVATTitle: "",
            TableVAT: "",
            TableGrandTotal: "",
            ImportantConditions: "",
            Sincerely: "",
            Footer: "",
            Logo: 0,
            LogoType: 0,
            LogoPathTopRight: "",
            LogoPathTop: "",
            LinkCheckCP: undefined,
            Check: false,
            Currency: ""
        });

    const [rowsTable, setRowsTable] = useState([]);

    const [columnFooter, setColumnFooter] = useState([]);

    //#region 

    //Отправитель
    const [leftSender, setLeftSender] = useState("0px");
    const [topSender, setTopSender] = useState("0px");

    //Кому
    const [leftToWhom, setLeftToWhom] = useState("0px");
    const [topToWhom, setTopToWhom] = useState("0px");

    //Реквизиты контрагента
    const [leftRequisitesContractor, setLeftRequisitesContractor] = useState("0px");
    const [topRequisitesContractor, setTopRequisitesContractor] = useState("0px");

    const [fontSize, setFontSize] = useState("0px");

    const [fullScreen, setFullScreen] = useState(true);

    //#endregion   

    const [editVATBlock, setEditVATBlock] = useState(false);

    const [edit, setEdit] = useState(false);

    useEffect(() => {
        if (parseInt(clientId) === 0) return;
        axios.get("/getrequisitesclient/" + clientId).then(response => { setRequisitesClientCP(response.data); });
    }, [clientId])

    useEffect(() => {
        changeSize()
    }, [fullScreen])// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setEdit(false);
        UpdateData();
    }, [cpid])// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => { if (edit === true) { UpdateData(); } }, [requisitesClientCP])// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (edit === true) summTotal()
    }, [rowsTable])// eslint-disable-line react-hooks/exhaustive-deps

    const UpdateData = () => {
        axios.get("/getcpitem/" + cpid).then(response => response.data)
            .then(data => {
                setClientId(data.ClientsId);
                setContractorId(data.ContractorsId);
                setCommercialProposalItems({ VAT: data.VAT, VATValue: data.VATValue, Currency: data.Currency, CPId: data.CPId, LinkCheckCP: data.LinkCheckCP, Check: data.Check, Sender: data.Sender, Date: data.Date, Number: data.Number, ClientNumber: data.ClientNumber, LetterSubject: data.LetterSubject, Greetings: data.Greetings, Preamble: data.Preamble, TableSubtotal: data.TableSubtotal, TableVATTitle: data.TableVATTitle, TableVAT: data.TableVAT, TableGrandTotal: data.TableGrandTotal, ImportantConditions: data.ImportantConditions, Sincerely: data.Sincerely, Footer: data.Footer, Logo: data.Logo, LogoType: data.LogoType, LogoPathTopRight: data.LogoPathTopRight, LogoPathTop: data.LogoPathTop });
                setRowsTable(data.TableRows);
                changeSize();
                axios.get("/getrequisitescontractor/" + cpid).then(response => { setRequisitesContractorCP(response.data); });
                window.addEventListener("resize", changeSize, false);
                setEdit(true);
                setColumnFooter(data.ColumnsFooter);
            });
    }

    const changeCP = () => {
        axios.post("/changecp", commercialProposalItems);
    }

    const valuePixel = (value) => {
        return (Page.current !== undefined && Page.current !== null ? Page.current.clientHeight / 297 * value : 0) + "px";
    }

    const valuePixelHeight = (value) => {
        return (Page.current !== undefined && Page.current !== null ? Page.current.clientWidth / 210 * value : 0) + "px";
    }

    const ptToPX = (value) => {
        const mm = value * 0.3527777778;
        return (Page.current !== undefined && Page.current !== null ? fullScreen ? Page.current.clientWidth / 210 * mm : Page.current.clientHeight / 297 * mm : 0) + "px";
    }

    const changeSize = () => {

        setFontSize(ptToPX(10));

        if (fullScreen) {
            setWidthPage(valuePixelHeight(210));

            setLeftSender(valuePixelHeight(25));
            setTopSender(valuePixelHeight(27));

            setLeftToWhom(valuePixelHeight(25));
            setTopToWhom(valuePixelHeight(44.7));

            setLeftRequisitesContractor(valuePixelHeight(125));
            setTopRequisitesContractor(valuePixelHeight(32));
        }
        else {
            setWidthPage(valuePixel(210));

            setLeftSender(valuePixel(25));
            setTopSender(valuePixel(27));

            setLeftToWhom(valuePixel(25));
            setTopToWhom(valuePixel(44.7));

            setLeftRequisitesContractor(valuePixel(125));
            setTopRequisitesContractor(valuePixel(32));
        }
    }

    const size = (value) => {
        if (fullScreen)
            return valuePixelHeight(value);
        else
            return valuePixel(value);
    }

    const addRow = () => {
        axios.post("/addrowcp/" + cpid).then(response => { rowsTable.push({ Id: null, CPTIId: response.data, Number: (rowsTable.length + 1).toString(), Title: "", Count: "", UnitOfMeasurement: "", Price: "", Total: "" }); setRowsTable([...rowsTable]); })
    }

    const summTotal = () => {
        let summ = 0;
        rowsTable.map(item =>
            item.Total
                ? summ = summ + parseFloat(item.Total.replace(/\s/g, ''))
                : null
        );
        commercialProposalItems.TableSubtotal = summ.toLocaleString(undefined, { minimumFractionDigits: 2 }) + " " + commercialProposalItems.Currency;
        if (commercialProposalItems.VAT)
            commercialProposalItems.TableVAT = (summ * parseFloat(commercialProposalItems.VATValue === 0 ? 19 : commercialProposalItems.VATValue) / 100).toLocaleString('ru-RU', { minimumFractionDigits: 2 }) + " " + commercialProposalItems.Currency;
        else
            commercialProposalItems.TableVAT = '0';
        commercialProposalItems.TableGrandTotal = (parseFloat(summ) + (summ * (commercialProposalItems.VAT ? parseFloat(commercialProposalItems.VATValue === 0 ? 19 : commercialProposalItems.VATValue) / 100 : 0))).toLocaleString('ru-RU', { minimumFractionDigits: 2 }) + " " + commercialProposalItems.Currency;
        setCommercialProposalItems({ ...commercialProposalItems });
        changeCP();
    }

    const addCheck = () => {
        axios.get("/addcheck/" + cpid).then(response => { commercialProposals[commercialProposals.findIndex(x => x.Id === parseInt(cpid))].LinkCheckCP = response.data.Id; commercialProposals.push(response.data); setCommercialProposalItems({ ...commercialProposalItems }); history.push("/workplace/transactions/transaction/" + transactionid + "/cp/" + response.data.Id); })
    }

    const RequisitesContractorTableRow = ({ item }) => {
        switch (item.NameParameter) {
            case "CompanyName":
                return requisitesContractorCP.CompanyName ? <div key={'ContractorRequisitesLinks_' + item.Id} style={{ marginBottom: size(item.MarginBottomCountRows * 6), fontSize: item.FontSize === 0 ? fontSize : ptToPX(item.FontSize) + 'pt' }} className={(item.Bold ? ' bold' : '') + (item.Italic ? ' italic' : '')}>{requisitesContractorCP.CompanyName}</div> : null
            case "ContactName":
                return <div key={'ContractorRequisitesLinks_' + item.Id} style={{ marginBottom: size(item.MarginBottomCountRows * 6), fontSize: item.FontSize === 0 ? fontSize : ptToPX(item.FontSize) }} className={(item.Bold ? ' bold' : '') + (item.Italic ? ' italic' : '')}>{requisitesContractorCP.ContactName}</div>
            case "Locality":
                return <div key={'ContractorRequisitesLinks_' + item.Id} style={{ marginBottom: size(item.MarginBottomCountRows * 6), fontSize: item.FontSize === 0 ? fontSize : ptToPX(item.FontSize) }} className={(item.Bold ? ' bold' : '') + (item.Italic ? ' italic' : '')}>{requisitesContractorCP.Locality}</div>
            case "Address":
                return <div key={'ContractorRequisitesLinks_' + item.Id} style={{ marginBottom: size(item.MarginBottomCountRows * 6), fontSize: item.FontSize === 0 ? fontSize : ptToPX(item.FontSize) }} className={(item.Bold ? ' bold' : '') + (item.Italic ? ' italic' : '')}>{requisitesContractorCP.Address}</div>
            case "Index":
                return <div key={'ContractorRequisitesLinks_' + item.Id} style={{ marginBottom: size(item.MarginBottomCountRows * 6), fontSize: item.FontSize === 0 ? fontSize : ptToPX(item.FontSize) }} className={(item.Bold ? ' bold' : '') + (item.Italic ? ' italic' : '')}>{requisitesContractorCP.Index}</div>
            case "Email":
                return <div key={'ContractorRequisitesLinks_' + item.Id} style={{ marginBottom: size(item.MarginBottomCountRows * 6), fontSize: item.FontSize === 0 ? fontSize : ptToPX(item.FontSize) }} className={'contractorRequisitesLinksBlock' + (item.Bold ? ' bold' : '') + (item.Italic ? ' italic' : '')}>
                    <div style={{ marginRight: size(1) }}>{t("workplace.menuitems.commercialProposal.emaillabel")}</div>
                    <div>{requisitesContractorCP.Email}</div>
                </div>
            case "Phone":
                return <div key={'ContractorRequisitesLinks_' + item.Id} style={{ marginBottom: size(item.MarginBottomCountRows * 6), fontSize: item.FontSize === 0 ? fontSize : ptToPX(item.FontSize) }} className={'contractorRequisitesLinksBlock' + (item.Bold ? ' bold' : '') + (item.Italic ? ' italic' : '')}>
                    <div style={{ marginRight: size(1) }}>{t("workplace.menuitems.commercialProposal.phonelabel")}</div>
                    <div>{requisitesContractorCP.Phone}</div>
                </div>
            case "Fax":
                return <div key={'ContractorRequisitesLinks_' + item.Id} style={{ marginBottom: size(item.MarginBottomCountRows * 6), fontSize: item.FontSize === 0 ? fontSize : ptToPX(item.FontSize) }} className={'contractorRequisitesLinksBlock' + (item.Bold ? ' bold' : '') + (item.Italic ? ' italic' : '')}>
                    <div style={{ marginRight: size(1) }}>{t("workplace.menuitems.commercialProposal.faxlabel")}</div>
                    <div>{requisitesContractorCP.Fax}</div>
                </div>
            case "Department":
                return <div key={'ContractorRequisitesLinks_' + item.Id} style={{ marginBottom: size(item.MarginBottomCountRows * 6), fontSize: item.FontSize === 0 ? fontSize : ptToPX(item.FontSize) }} className={'contractorRequisitesLinksBlock' + (item.Bold ? ' bold' : '') + (item.Italic ? ' italic' : '')}>
                    <div style={{ marginRight: size(1) }}>{t("workplace.menuitems.commercialProposal.departmentlabel")}</div>
                    <div>{requisitesContractorCP.Department}</div>
                </div>
            case "INN":
                return <div key={'ContractorRequisitesLinks_' + item.Id} style={{ marginBottom: size(item.MarginBottomCountRows * 6), fontSize: item.FontSize === 0 ? fontSize : ptToPX(item.FontSize) }} className={'contractorRequisitesLinksBlock' + (item.Bold ? ' bold' : '') + (item.Italic ? ' italic' : '')}>
                    <div style={{ marginRight: size(1) }}>{t("workplace.menuitems.commercialProposal.inn")}</div>
                    <div>{requisitesContractorCP.INN}</div>
                </div>
            case "Date":
                return <div key={'ContractorRequisitesLinks_' + item.Id} style={{ marginBottom: size(item.MarginBottomCountRows * 6), fontSize: item.FontSize === 0 ? fontSize : ptToPX(item.FontSize) }} className={'contractorRequisitesLinksBlock' + (item.Bold ? ' bold' : '') + (item.Italic ? ' italic' : '')}>
                    {commercialProposalItems.Date ? commercialProposalItems.Date : ""}
                </div>
            case "OfferPeriod":
                return <div key={'ContractorRequisitesLinks_' + item.Id} style={{ marginBottom: size(item.MarginBottomCountRows * 6), fontSize: item.FontSize === 0 ? fontSize : ptToPX(item.FontSize) }} className={'contractorRequisitesLinksBlock' + (item.Bold ? ' bold' : '') + (item.Italic ? ' italic' : '')}>
                    <div style={{ marginRight: size(1) }}>{t("workplace.menuitems.commercialProposal.offerperiod")}</div>
                    <div>{requisitesContractorCP.OfferPeriod}</div>
                </div>
            case "TypeDocument":
                return <div key={'ContractorRequisitesLinks_' + item.Id} style={{ marginBottom: size(item.MarginBottomCountRows * 6), fontSize: item.FontSize === 0 ? fontSize : ptToPX(item.FontSize) }} className={'contractorRequisitesLinksBlock' + (item.Bold ? ' bold' : '') + (item.Italic ? ' italic' : '')}>
                    {commercialProposalItems.Check ?
                        t("workplace.menuitems.toprightangle.Check") :
                        t("workplace.menuitems.toprightangle.CP")
                    }
                </div>
            case "NumberDocument":
                return <div key={'ContractorRequisitesLinks_' + item.Id} style={{ marginBottom: size(item.MarginBottomCountRows * 6), fontSize: item.FontSize === 0 ? fontSize : ptToPX(item.FontSize) }} className={'contractorRequisitesLinksBlock' + (item.Bold ? ' bold' : '') + (item.Italic ? ' italic' : '')}>
                    <div style={{ marginRight: size(1) }}>{t("workplace.menuitems.commercialProposal.number")}</div>
                    <div>{commercialProposalItems.Number}</div>
                </div>
            case "NumberClient":
                return <div key={'ContractorRequisitesLinks_' + item.Id} style={{ marginBottom: size(item.MarginBottomCountRows * 6), fontSize: item.FontSize === 0 ? fontSize : ptToPX(item.FontSize) }} className={'contractorRequisitesLinksBlock' + (item.Bold ? ' bold' : '') + (item.Italic ? ' italic' : '')}>
                    <div style={{ marginRight: size(1) }}>{t("workplace.menuitems.commercialProposal.clientnumber")}</div>
                    <div>{commercialProposalItems.ClientNumber}</div>
                </div>
            case "Bank":
                return <div key={'ContractorRequisitesLinks_' + item.Id} style={{ marginBottom: size(item.MarginBottomCountRows * 6), fontSize: item.FontSize === 0 ? fontSize : ptToPX(item.FontSize) }} className={'contractorRequisitesLinksBlock' + (item.Bold ? ' bold' : '') + (item.Italic ? ' italic' : '')}>
                    <div style={{ marginRight: size(1) }}>{t("workplace.menuitems.commercialProposal.bank")}</div>
                    <div>{requisitesContractorCP.Bank}</div>
                </div>
            case "CodeBank":
                return <div key={'ContractorRequisitesLinks_' + item.Id} style={{ marginBottom: size(item.MarginBottomCountRows * 6), fontSize: item.FontSize === 0 ? fontSize : ptToPX(item.FontSize) }} className={'contractorRequisitesLinksBlock' + (item.Bold ? ' bold' : '') + (item.Italic ? ' italic' : '')}>
                    <div style={{ marginRight: size(1) }}>{t("workplace.menuitems.commercialProposal.codebank")}</div>
                    <div>{requisitesContractorCP.CodeBank}</div>
                </div>
            case "BankAccount":
                return <div key={'ContractorRequisitesLinks_' + item.Id} style={{ marginBottom: size(item.MarginBottomCountRows * 6), fontSize: item.FontSize === 0 ? fontSize : ptToPX(item.FontSize) }} className={'contractorRequisitesLinksBlock' + (item.Bold ? ' bold' : '') + (item.Italic ? ' italic' : '')}>
                    <div style={{ marginRight: size(1) }}>{t("workplace.menuitems.commercialProposal.bankaccount")}</div>
                    <div>{requisitesContractorCP.BankAccount}</div>
                </div>
            case "CompanyAccount":
                return <div key={'ContractorRequisitesLinks_' + item.Id} style={{ marginBottom: size(item.MarginBottomCountRows * 6), fontSize: item.FontSize === 0 ? fontSize : ptToPX(item.FontSize) }} className={'contractorRequisitesLinksBlock' + (item.Bold ? ' bold' : '') + (item.Italic ? ' italic' : '')}>
                    <div style={{ marginRight: size(1) }}>{t("workplace.menuitems.commercialProposal.companyaccount")}</div>
                    <div>{requisitesContractorCP.CompanyAccount}</div>
                </div>
            case "BIK":
                return <div key={'ContractorRequisitesLinks_' + item.Id} style={{ marginBottom: size(item.MarginBottomCountRows * 6), fontSize: item.FontSize === 0 ? fontSize : ptToPX(item.FontSize) }} className={'contractorRequisitesLinksBlock' + (item.Bold ? ' bold' : '') + (item.Italic ? ' italic' : '')}>
                    <div style={{ marginRight: size(1) }}>{t("workplace.menuitems.commercialProposal.bik")}</div>
                    <div>{requisitesContractorCP.BIK}</div>
                </div>
            case "BankINN":
                return <div key={'ContractorRequisitesLinks_' + item.Id} style={{ marginBottom: size(item.MarginBottomCountRows * 6), fontSize: item.FontSize === 0 ? fontSize : ptToPX(item.FontSize) }} className={'contractorRequisitesLinksBlock' + (item.Bold ? ' bold' : '') + (item.Italic ? ' italic' : '')}>
                    <div style={{ marginRight: size(1) }}>{t("workplace.menuitems.commercialProposal.bankinn")}</div>
                    <div>{requisitesContractorCP.BankINN}</div>
                </div>
            case "GoverningBody":
                return <div key={'ContractorRequisitesLinks_' + item.Id} style={{ marginBottom: size(item.MarginBottomCountRows * 6), fontSize: item.FontSize === 0 ? fontSize : ptToPX(item.FontSize) }} className={'contractorRequisitesLinksBlock' + (item.Bold ? ' bold' : '') + (item.Italic ? ' italic' : '')}>
                    <div style={{ marginRight: size(1) }}>{t("workplace.menuitems.commercialProposal.governingbody")}</div>
                    <div>{requisitesContractorCP.GoverningBody}</div>
                </div>
            case "Supervisor":
                return <div key={'ContractorRequisitesLinks_' + item.Id} style={{ marginBottom: size(item.MarginBottomCountRows * 6), fontSize: item.FontSize === 0 ? fontSize : ptToPX(item.FontSize) }} className={'contractorRequisitesLinksBlock' + (item.Bold ? ' bold' : '') + (item.Italic ? ' italic' : '')}>
                    <div style={{ marginRight: size(1) }}>{t("workplace.menuitems.commercialProposal.supervisor")}</div>
                    <div>{requisitesContractorCP.Supervisor}</div>
                </div>
            case "ChairmanSupervisoryBoard":
                return <div key={'ContractorRequisitesLinks_' + item.Id} style={{ marginBottom: size(item.MarginBottomCountRows * 6), fontSize: item.FontSize === 0 ? fontSize : ptToPX(item.FontSize) }} className={'contractorRequisitesLinksBlock' + (item.Bold ? ' bold' : '') + (item.Italic ? ' italic' : '')}>
                    <div style={{ marginRight: size(1) }}>{t("workplace.menuitems.commercialProposal.chairmansupervisoryboard")}</div>
                    <div>{requisitesContractorCP.ChairmanSupervisoryBoard}</div>
                </div>
            case "RegisteredCourt":
                return <div key={'ContractorRequisitesLinks_' + item.Id} style={{ marginBottom: size(item.MarginBottomCountRows * 6), fontSize: item.FontSize === 0 ? fontSize : ptToPX(item.FontSize) }} className={'contractorRequisitesLinksBlock' + (item.Bold ? ' bold' : '') + (item.Italic ? ' italic' : '')}>
                    <div style={{ marginRight: size(1) }}>{t("workplace.menuitems.commercialProposal.registeredcourt")}</div>
                    <div>{requisitesContractorCP.RegisteredCourt}</div>
                </div>
            case "RegistrationNumber":
                return <div key={'ContractorRequisitesLinks_' + item.Id} style={{ marginBottom: size(item.MarginBottomCountRows * 6), fontSize: item.FontSize === 0 ? fontSize : ptToPX(item.FontSize) }} className={'contractorRequisitesLinksBlock' + (item.Bold ? ' bold' : '') + (item.Italic ? ' italic' : '')}>
                    <div style={{ marginRight: size(1) }}>{t("workplace.menuitems.commercialProposal.registrationnumber")}</div>
                    <div>{requisitesContractorCP.RegistrationNumber}</div>
                </div>
            case "Website":
                return <div key={'ContractorRequisitesLinks_' + item.Id} style={{ marginBottom: size(item.MarginBottomCountRows * 6), fontSize: item.FontSize === 0 ? fontSize : ptToPX(item.FontSize) }} className={'contractorRequisitesLinksBlock' + (item.Bold ? ' bold' : '') + (item.Italic ? ' italic' : '')}>
                    <div style={{ marginRight: size(1) }}>{t("workplace.menuitems.commercialProposal.website")}</div>
                    <div>{requisitesContractorCP.Website}</div>
                </div>
            default:
                <div></div>
        }
        return null
    }

    const FooterCP = () => {
        return <div id="CPFooterBlock" onClick={() => setRequisitesContractorBlock(true)}>
            {
                requisitesContractorCP
                    ? columnFooter.map((column, index) => {
                        return <div style={{ width: 'calc(100% / ' + columnFooter.length + ')', marginTop: size(15), marginBottom: size(5) }} key={"Footer_" + index}>
                            {
                                column.Items.map((item, indexItem) => {
                                    return <RequisitesContractorTableRow item={item} key={"FooterItem_" + indexItem} />
                                })
                            }
                        </div>
                    }
                    )
                    : null
            }
        </div>
    }

    useEffect(() => { if (edit === true) { summTotal(); } }, [commercialProposalItems.VAT, commercialProposalItems.VATValue])// eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div id='cp' className={commercialProposalItems.Check ? " check" : ""}>

            <div className={"requisitesCPModalBlock" + (editVATBlock ? " Open" : "")}>
                <EditVAT setEditVATBlock={setEditVATBlock} VAT={commercialProposalItems.VAT} VATValue={commercialProposalItems.VATValue} setCommercialProposalItems={setCommercialProposalItems} />
            </div>

            <div className={"requisitesCPModalBlock" + (requisitesContractorBlock ? " Open" : "")}>
                <RequisitesContractor commercialProposalItems={commercialProposalItems} setCommercialProposalItems={setCommercialProposalItems} setRequisitesBlock={setRequisitesContractorBlock} contractorid={contractorid} requisites={requisitesContractorCP} setRequisites={setRequisitesContractorCP} dateInput={true} cpid={cpid} />
            </div>

            {/* <div className={"requisitesCPModalBlock" + (angleTopRightBlock ? " Open" : "")}>
                <AngleTopRightCPModal/>
            </div>

            <div className={"requisitesCPModalBlock" + (footerCPModal ? " Open" : "")}>
                <FooterCPModal/>
            </div> */}

            <div className={"requisitesCPModalBlock" + (requisitesClientBlock ? " Open" : "")}>
                <RequisitesClient setRequisitesBlock={setRequisitesClientBlock} clientid={clientId} requisites={requisitesClientCP} setRequisites={setRequisitesClientCP} />
            </div>

            <div id="CPBack" onClick={() => history.push('/workplace/transactions/transaction/' + transactionid)}><IoIosArrowBack />{t("workplace.menuitems.commercialProposal.back")}</div>
            {
                fullScreen
                    ? <div id='CPFullScreen' onClick={() => setFullScreen(!fullScreen)}>
                        <MdOutlineFullscreenExit id='CPFullScreenIcon' />
                        <div>{t("workplace.menuitems.commercialProposal.miniaturemode")}</div>
                    </div>
                    : <div id='CPFullScreen' onClick={() => setFullScreen(!fullScreen)}>
                        <MdOutlineFullscreen id='CPFullScreenIcon' />
                        <div>{t("workplace.menuitems.commercialProposal.fullscreen")}</div>
                    </div>
            }

            <div id='CPTitleBlock' style={{ width: fullScreen ? "100%" : widthPage }}>
                <div>{commercialProposalItems.Number}</div>
            </div>

            <div id='CPPageBlock' ref={Page} style={{ width: fullScreen ? "100%" : widthPage, flexGrow: 1 }}>
                <div id='CPPage' style={{ paddingRight: size(10), paddingLeft: size(25), paddingBottom: 0, paddingTop: size(27), boxSizing: 'border-box' }}>
                    {/* Лого */}
                    {
                        commercialProposalItems.Logo === 0
                            ? null
                            : commercialProposalItems.LogoType === 0
                                ? <img style={{ width: 'calc(' + size(27) + ' / ' + size(75) + ')', height: size(27), top: '0', left: size(125), position: 'absolute' }} id='CPLogoTopRight' src={commercialProposalItems.LogoPathTopRight} alt="" />
                                : <img style={{ width: 'calc(' + size(27) + ' / ' + size(210) + ')', height: size(27), top: '0', left: '0', position: 'absolute' }} id='CPLogoTopRight' src={commercialProposalItems.LogoPathTop} alt="" />
                    }

                    {/* Шапка */}
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div>
                            {/* Отправитель */}
                            <textarea id="Sender" placeholder={t("workplace.menuitems.commercialProposal.sender")} className='cpTextArea' style={{ width: size(75), left: leftSender, top: topSender, fontSize: ptToPX(8) }} value={commercialProposalItems.Sender} onChange={e => { setCommercialProposalItems(item => ({ ...item, Sender: e.target.value })) }} onBlur={() => changeCP()}></textarea>

                            {/* Реквизиты клиента */}
                            {
                                requisitesClientCP
                                    ?
                                    requisitesClientCP.ClientId === 0
                                        ? <div id="ToWhom" onClick={() => setRequisitesClientBlock(true)} style={{ left: leftToWhom, top: topToWhom, fontSize: fontSize, width: size(75) }}>{t("workplace.menuitems.commercialProposal.requisitesclient")}</div>
                                        : <div onClick={() => setRequisitesClientBlock(true)} id="ToWhom" style={{ left: leftToWhom, top: topToWhom, fontSize: fontSize, width: size(75) }}>
                                            {!requisitesClientCP.Individual ? <div style={{ fontSize: fontSize }}>{requisitesClientCP.CompanyName}</div> : null}
                                            <div style={{ fontSize: fontSize }}>{requisitesClientCP.ContactName}</div>
                                            <div style={{ fontSize: fontSize }}>{requisitesClientCP.Address}</div>
                                            <div className="cpRequisitesFlex">
                                                <div style={{ fontSize: fontSize, marginRight: size(1) }}>{requisitesClientCP.Index}</div>
                                                <div style={{ fontSize: fontSize }}>{requisitesClientCP.Locality}</div>
                                            </div>
                                            <div style={{ fontSize: fontSize }}>{requisitesClientCP.Country}</div>
                                        </div>
                                    : null
                            }
                        </div>
                        {/* Реквизиты контрагента */}
                        <div id="RequisitesContractor" style={{ left: leftRequisitesContractor, top: topRequisitesContractor, fontSize: fontSize, width: size(75) }}>
                            {
                                requisitesContractorCP && requisitesContractorCP.ContactName !== ""
                                    ? <table onClick={() => setRequisitesContractorBlock(true)}>
                                        <tbody>
                                            {
                                                requisitesContractorCP.ContractorRequisitesLinks.map((item, index) => {
                                                    return <RequisitesContractorTableRow item={item} key={"RCCP_" + index} />
                                                }
                                                )
                                            }
                                        </tbody>
                                    </table>
                                    : <div>{t("workplace.menuitems.commercialProposal.requisitescontractor")}</div>
                            }
                        </div>
                    </div>

                    {/* Тело */}
                    <div style={{ marginTop: size(15) }}>
                        {/* Тема письма */}
                        <input id='LetterSubject' className='pageInput' placeholder={t("workplace.menuitems.commercialProposal.lettersubject")} style={{ fontSize: fontSize, width: size(165), marginTop: ptToPX(13) }} value={commercialProposalItems.LetterSubject} onChange={e => { setCommercialProposalItems(item => ({ ...item, LetterSubject: e.target.value })) }} onBlur={() => changeCP()} />

                        {/* Приветствие */}
                        <input id='Greetings' className='pageInput' placeholder={t("workplace.menuitems.commercialProposal.greetings")} style={{ fontSize: fontSize, width: size(165), marginTop: ptToPX(13) }} value={commercialProposalItems.Greetings} onChange={e => { setCommercialProposalItems(item => ({ ...item, Greetings: e.target.value })) }} onBlur={() => changeCP()} />

                        {/* Преамбула */}
                        <textarea id='Preamble' className='cpTextArea' placeholder={t("workplace.menuitems.commercialProposal.preamble")} style={{ fontSize: fontSize, marginTop: ptToPX(13), width: size(165), height: size(15) }} value={commercialProposalItems.Preamble} onChange={e => { setCommercialProposalItems(item => ({ ...item, Preamble: e.target.value })) }} onBlur={() => changeCP()} ></textarea>

                        {/* Таблица */}
                        <div style={{ marginTop: size(5), width: size(165), fontSize: fontSize }}>
                            <div className='rowTable'>
                                <div style={{ width: size(10), maxWidth: size(10) }}>
                                    <div className='tableCPColHeader' >{t("workplace.menuitems.commercialProposal.tablenumber")}</div>
                                </div>
                                <div style={{ width: size(68.1), maxWidth: size(68.1) }}>
                                    <div className='tableCPColHeader'>{t("workplace.menuitems.commercialProposal.tabletitle")}</div>
                                </div>
                                <div style={{ width: size(24.2), maxWidth: size(24.2), textAlign: "end" }}>
                                    <div className='tableCPColHeader'>{t("workplace.menuitems.commercialProposal.tablecount")}</div>
                                </div>
                                <div style={{ width: size(24.2), maxWidth: size(24.2), textAlign: "end" }}>
                                    <div className='tableCPColHeader'>{t("workplace.menuitems.commercialProposal.tableunitofmeasurement")}</div>
                                </div>
                                <div style={{ width: size(24.2), maxWidth: size(24.2), textAlign: "end" }}>
                                    <div className='tableCPColHeader'>{t("workplace.menuitems.commercialProposal.tableprice")}</div>
                                </div>
                                <div style={{ width: size(24.2), maxWidth: size(24.2), textAlign: "end" }}>
                                    <div className='tableCPColHeader'>{t("workplace.menuitems.commercialProposal.tabletotal")}</div>
                                </div>
                            </div>
                            <div>
                                {
                                    rowsTable.map((row, index) =>
                                        <CommercialProposalTableRow key={"Row_" + index} row={row} valuePixel={size} fontsize={fontSize} rowsTable={rowsTable} setRowsTable={setRowsTable} size={size} />
                                    )
                                }
                            </div>
                        </div>
                        <div id='AddLineButton' style={{ fontSize: fontSize }} onClick={() => addRow()}>
                            <BsPlusCircle id='AddLineIconButton' />
                            <div>{t("workplace.menuitems.commercialProposal.addrow")}</div>
                        </div>
                        {/* Итог */}
                        <div id='Total' style={{ width: size(165), fontSize: fontSize }}>
                            {
                                commercialProposalItems.VAT
                                    ?
                                    <div className='totalItem totalTextBold' style={{ marginTop: size(2) }}>
                                        <div>{t("workplace.menuitems.commercialProposal.tablesubtotal")}</div>
                                        <div>{commercialProposalItems.TableSubtotal}</div>
                                    </div>
                                    : null
                            }
                            {
                                commercialProposalItems.VAT
                                    ? <div className='totalItem' id='totalWAT' style={{ marginTop: size(2), paddingBottom: size(1), borderBottom: "1px solid black" }}>
                                        <div>
                                            {commercialProposalItems.VATValue}{t("workplace.menuitems.commercialProposal.VAT")}{commercialProposalItems.TableSubtotal}
                                        </div>
                                        <div>
                                            {commercialProposalItems.TableVAT}
                                        </div>
                                    </div>
                                    : null
                            }
                            <div className='totalItem totalTextBold' style={{ marginTop: size(2) }}>
                                <div>{t("workplace.menuitems.commercialProposal.tablegrandtotal")}</div>
                                <div>
                                    {commercialProposalItems.TableGrandTotal}
                                </div>
                            </div>
                        </div>

                        {/* Важные условия */}
                        <textarea id='ImportantConditions' className='cpTextArea' placeholder={t("workplace.menuitems.commercialProposal.importantconditions")} style={{ fontSize: fontSize, marginTop: ptToPX(13), width: size(165), height: size(15) }} value={commercialProposalItems.ImportantConditions} onChange={e => { setCommercialProposalItems(item => ({ ...item, ImportantConditions: e.target.value })) }} onBlur={() => changeCP()} ></textarea>

                        {/* С уважением */}
                        <textarea id='Sincerely' className='cpTextArea' placeholder={t("workplace.menuitems.commercialProposal.sincerely")} style={{ fontSize: fontSize, marginTop: ptToPX(13), width: size(165), height: size(15) }} value={commercialProposalItems.Sincerely} onChange={e => { setCommercialProposalItems(item => ({ ...item, Sincerely: e.target.value })) }} onBlur={() => changeCP()} ></textarea>
                    </div>

                    {/* Футер */}
                    <FooterCP />
                    {/* <textarea id='CPFooter' className='cpTextArea' placeholder={t("workplace.menuitems.commercialProposal.footer")} style={{ left: leftFooterDocument, top: topFooterDocument, fontSize: fontSize, width: size(165), height: size(20), marginTop: size(15) }} value={commercialProposalItems.Footer} onChange={e => { setCommercialProposalItems(item => ({ ...item, Footer: e.target.value })) }} onBlur={() => changeCP()} ></textarea> */}
                </div>
            </div>

            <div className='cpPrintButtonBlock'>
                {
                    commercialProposalItems.Check ?
                        null
                        : commercialProposalItems.LinkCheckCP
                            ? <NavLink to={"/workplace/transactions/transaction/" + transactionid + "/cp/" + commercialProposalItems.LinkCheckCP} className='cpPrintButton'>{t("workplace.menuitems.commercialProposal.check")}</NavLink>
                            : <div className='cpPrintButton' onClick={() => addCheck()}>{t("workplace.menuitems.commercialProposal.createcheck")}</div>
                }
                {
                    requisitesClientCP ?
                        requisitesClientCP.Individual
                            ? null
                            : <div className='cpPrintButton' onClick={() => setEditVATBlock(!editVATBlock)}>{t("workplace.menuitems.commercialProposal.editVAT")}</div>
                        : null
                }

                {/* <a className='cpPrintButton' href={cookies.get(['url']) + '/commercialproposal/' + cpid + "/" + i18n.language} target='_blank' rel='noopener noreferrer'>{t("workplace.menuitems.commercialProposal.printbutton")}</a> */}
                <a className='cpPrintButton' href={cookies.get(['url']) + '/commercialproposal/' + cpid + "/" + i18n.language + "/false/" + commercialProposalItems.Number} target='_blank' rel='noopener noreferrer'><BsFillPrinterFill /></a>
                <a className='cpPrintButton' href={cookies.get(['url']) + '/commercialproposal/' + cpid + "/" + i18n.language + "/true/" + commercialProposalItems.Number} target='_blank' rel='noopener noreferrer'><HiDownload /></a>
            </div>
        </div >
    )
}
