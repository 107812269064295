import {React, useState} from 'react'
import { useTranslation } from 'react-i18next'
import { MdArrowBackIosNew } from 'react-icons/md'

export default function ContactPersons({item}) {

    const { t } = useTranslation();
    const [contactPersonsDetalization, setContactPersonsDetalization] = useState(false);

    return (
        <div className='contactPersonsItemBlock'>
            <div className="contactPersonsDataItemBlock contactPersonsTitleBlock">
                <div className='contactPersonsItemData contactPersonsTitle'>{item.FullName}</div>
                <div onClick={()=>setContactPersonsDetalization(!contactPersonsDetalization)} className="contactPersonsDetalizationButton">
                    <MdArrowBackIosNew className={"contactPersonsDetalizationButtonIcon"+(contactPersonsDetalization?" Open":"")}/>
                </div>
            </div>
            <div className={"contactPersonsDetalizationBlock" + (contactPersonsDetalization ? " Open" : "")}>
            <div className="contactPersonsDataItemBlock">
                <div className="clientinfotitle">{t("default.phone")}</div>
                <div>{item.Phone!= null && item.Phone.length>0 ? item.Phone : "-"}</div>
            </div>
            <div className="contactPersonsDataItemBlock">
                <div className="clientinfotitle">{t("default.post")}</div>
                <div>{item.Post!= null && item.Post.length>0 ? item.Post : "-"}</div>
            </div>
            <div className="contactPersonsDataItemBlock">
                <div className="clientinfotitle">E-mail</div>
                <div>{item.Email!= null && item.Email.length>0 ? item.Email : "-"}</div>
            </div>
            <div className="contactPersonsDataItemBlock">
                <div className="clientinfotitle">{t("default.note")}</div>
                <div>{item.Note!= null && item.Note.length>0 ? item.Note : "-"}</div>
            </div>
            </div>
        </div>
    )
}
