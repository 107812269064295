import React from 'react'
import { useTranslation } from 'react-i18next'

export default function EditVAT({ setEditVATBlock, VAT, VATValue, setCommercialProposalItems }) {

    const { t } = useTranslation();

    return (
        <div id='editVAT'>
            <div id='EditVATTitle'>{t("workplace.menuitems.commercialProposal.editVATTitle")}</div>
            <div id='EditVATBlock'>
                <input id='EditVATYes' type='radio' name='editVATMain' className='editVATInputRadio' checked={VAT ? true : false} onChange={() => setCommercialProposalItems(item => ({ ...item, VAT: true }))} />
                <label htmlFor="EditVATYes" className='editVATInputLabel'>{t("workplace.menuitems.commercialProposal.VATOn")}</label>
                {/* <div id='EditVATValueBlock'>
                    <div id='EditVAT19Block'>
                        <input id='EditVAT19' type='radio' name='editVATValue' className='editVATInputRadio' checked={VATValue === 19 ? true : false} disabled={!VAT ? true : false} onChange={() => setCommercialProposalItems(item => ({ ...item, VATValue: 19 }))} />
                        <label htmlFor="EditVAT19" className='editVATInputLabel'>19%</label>
                    </div>
                    <div>
                        <input id='EditVAT20' type='radio' name='editVATValue' className='editVATInputRadio' checked={VATValue === 20 ? true : false} disabled={!VAT ? true : false} onChange={() => setCommercialProposalItems(item => ({ ...item, VATValue: 20 }))} />
                        <label htmlFor="EditVAT20" className='editVATInputLabel'>20%</label>
                    </div>
                </div> */}
            </div>
            <div>
                <input id='EditVATNo' type='radio' name='editVATMain' className='editVATInputRadio' checked={!VAT ? true : false} onChange={() => setCommercialProposalItems(item => ({ ...item, VAT: false }))} />
                <label htmlFor="EditVATNo" className='editVATInputLabel'>{t("workplace.menuitems.commercialProposal.VATOff")}</label>
            </div>
            <div id="EditVATButtonBlock">
                {/* <div id="RequisitesModalCancel" onClick={() => { setEditVATBlock(false); setCommercialProposalItems(item => ({ ...item, VAT: vat })); setCommercialProposalItems(item => ({ ...item, VATValue: vatValue })) }}>{t("buttons.cancel")}</div> */}
                <div id="RequisitesAddButton" onClick={() => { setEditVATBlock(false); }}>{t("buttons.ready")}</div>
            </div>
        </div>
    )
}
