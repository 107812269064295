import { React, useState, useEffect } from 'react'
import "./header.css"
import { useTranslation } from 'react-i18next'
import Cookies from 'universal-cookie'
import axios from 'axios'
import { IoLanguage } from 'react-icons/io5';
import { Route, useHistory } from 'react-router-dom';
import { IoIosArrowBack } from 'react-icons/io';
import PeopleIcon from "../../img/icons/workplace/People.svg"
import LogOutIcon from "../../img/icons/workplace/LogOut.svg"

export default function Header() {

    const cookies = new Cookies();

    const { t, i18n } = useTranslation();
    const history = useHistory();
    const changeLanguage = (language) => {
        i18n.changeLanguage(language);
        localStorage.setItem('language', language);
    };
    const [name, setName] = useState("");
    const [languageblock, setlanguageblock] = useState(false);

    function logout() {
        const cookies = new Cookies();
        cookies.remove('token', { path: '/' });
        return window.location.href = '/'
    }

    useEffect(() => {
        axios.get("/LUPAuth")
            .then(response => response.data)
            .then(data => { data.lup.Peoples ? setName(data.lup.Peoples.FullName) : setName("Ошибка загрузки имени"); });
    }, []);

    const languageBlockElement = document.getElementById("HeaderLanguageSelectorBlock");
    document.addEventListener("mousedown", (event) => { if (languageBlockElement != null && !languageBlockElement.contains(event.target)) setlanguageblock(false) });

    return (
        <div id="header">
            <div id="HeaderTitle">
                <Route path="/workplace/localization">
                    <div>{t("workplace.menuitems.localization.title")}</div>
                </Route>

                <Route exact path="/workplace/contractors">
                    <div>{t("workplace.menuitems.contractors.title")}</div>
                </Route>

                <Route exact path="/workplace/contractors/custom/:contractorid">
                    <IoIosArrowBack onClick={() => history.goBack()} className="backArrow" />
                </Route>
                <Route exact path="/workplace/contractors/custom/:contractorid">
                    <div>{t("workplace.menuitems.contractor.title")}</div>
                </Route>

                <Route exact path="/workplace/contractors/addcontractor">
                    <IoIosArrowBack onClick={() => history.goBack()} className="backArrow" />
                </Route>
                <Route path="/workplace/contractors/addcontractor">
                    <div>{t("workplace.menuitems.contractors.addcontractortitle")}</div>
                </Route>

                <Route exact path="/workplace/transactions">
                    <div>{t("workplace.menuitems.transactions.title")}</div>
                </Route>

                <Route exact path="/workplace/transactions/transaction">
                    <div>{t("workplace.menuitems.transactions.transactiontitle")}</div>
                </Route>

                <Route exact path="/workplace/transactions/transaction/:transactionid">
                    <IoIosArrowBack onClick={() => history.push("/workplace/transactions")} className="backArrow" />
                </Route>
                <Route exact path="/workplace/transactions/transaction/:transactionid">
                    <div>{t("workplace.menuitems.transactions.transactiontitle")}</div>
                </Route>

                <Route exact path="/workplace/transactions/transaction/:transactionid/cp/:cpid">
                <IoIosArrowBack onClick={() => history.push("/workplace/transactions")} className="backArrow" />
                </Route>
                <Route exact path="/workplace/transactions/transaction/:transactionid/cp/:cpid">
                    <div>{t("workplace.menuitems.transactions.transactiontitle")}</div>
                </Route>

                <Route exact path="/workplace/clients">
                    <div>{t("workplace.menuitems.clients.title")}</div>
                </Route>

                <Route exact path="/workplace/clients/:clientid">
                    <IoIosArrowBack onClick={() => history.goBack()} className="backArrow" />
                </Route>
                <Route exact path="/workplace/clients/:clientid">
                    <div>{t("workplace.menuitems.client.title")}</div>
                </Route>

                <Route path="/workplace/works">
                    <div>{t("workplace.menuitems.works.title")}</div>
                </Route>

                <Route path="/workplace/professions">
                    <div>{t("workplace.menuitems.professions.title")}</div>
                </Route>

                <Route path="/workplace/settingscontractors">
                    <div>{t("workplace.menuitems.settingsContractors.title")}</div>
                </Route>

                <Route path="/workplace/tasks">
                    <div>{t("workplace.menuitems.tasks.title")}</div>
                </Route>

                <Route path="/workplace/goodsservices">
                    <div>{t("workplace.menuitems.goodsservices.title")}</div>
                </Route>
            </div>
            <div id="HeaderPersData">
                <div onClick={() => setlanguageblock(!languageblock)} id="HeaderLanguageBlock">
                    <IoLanguage />
                    <div id="HeaderLanguageSelectorBlock" className={languageblock ? 'Open' : null}>
                        <div className="HeaderLanguageBlockItem" onClick={() => changeLanguage("en")}>{t("workplace.header.languages.en")}</div>
                        {cookies.get(['url'])!=='https://api.crm-de.com'?<div className="HeaderLanguageBlockItem" onClick={() => changeLanguage("ru")}>{t("workplace.header.languages.ru")}</div>:null}
                        <div className="HeaderLanguageBlockItem" onClick={() => changeLanguage("de")}>{t("workplace.header.languages.de")}</div>
                    </div>
                </div>
                <div id="HeaderName"><img src={PeopleIcon} id="HeaderPeopleIcon" alt=''/><div>{name}</div></div>
                <div id="HeaderLogOut" onClick={() => logout()}><img src={LogOutIcon} id="HeaderLogOutIcon" alt=''/><div>{t("workplace.header.logout")}</div></div>
            </div>
        </div>
    )
}
