import {React, useEffect} from 'react'
import Header from './header/header'
import Body from './body/body'
import Footer from './footer/footer'
import { useTranslation } from 'react-i18next'

export default function Mainsite() {

    const {i18n} = useTranslation();
    var languagesave = localStorage.getItem('language');
    if(languagesave==null)
    {
        localStorage.setItem('language', 'de');
        languagesave = localStorage.getItem('language');
    } 
    useEffect(() => {i18n.changeLanguage(languagesave);}, [i18n, languagesave]);
   
    return (
        <div>
            <Header/>
            <Body/>
            <Footer/>        
        </div>
    )
}
