import { React, useState, useEffect, useRef } from 'react'
import "./workstree.css"
import {AiOutlineClear} from 'react-icons/ai'
import axios from 'axios';
import { useTranslation } from 'react-i18next'

export default function Workstreeitemadd({tree, setTree}) {

    const {t} = useTranslation();
    const inputtitle = useRef();
    const [addBlock, setAddBlock] = useState(false);
    const [title, setTitle] = useState("");

    const addtypeworksparent = () =>{
        if(title.trim().length===0)
        {
            setTitle("");
            inputtitle.current.focus();
            return;
        }
        axios.post("/addtreeworks", {Title:title}).then(response=>response.data).then(data=>setTree([...tree, data]))
        setAddBlock(!addBlock);
    }
 
    useEffect(()=>(setTitle("")),[addBlock])

    return (
        <div>
            {
                addBlock
                    ? <div id="WorksTreeAddParentBlock">
                        <div id="WorksTreeAddInputBlock">
                            <input ref={inputtitle} autoFocus placeholder={t("workplace.menuitems.works.titleblocktypework")} value={title} onChange={e=>setTitle(e.target.value)} id="WorksTreeAddInput" type="text" />
                            <AiOutlineClear id="WorksTreeAddClear" onClick={() => {setTitle(""); inputtitle.current.focus();}}/>
                        </div>
                        <div id="WorksTreeAddButtonsBlock">
                            <div id="WorksTreeAddBlockParentButton" onClick={() => addtypeworksparent()}>{t("workplace.menuitems.works.add")}</div>
                            <div id="WorksTreeCancelBlockParentButton" onClick={() => setAddBlock(!addBlock)}>{t("workplace.menuitems.works.cancel")}</div>
                        </div>
                    </div>
                    : <div id="WorksTreeAddParentButton" onClick={() => setAddBlock(!addBlock)}>{t("workplace.menuitems.works.newblocktypework")}</div>
            }
        </div>
    )
}
