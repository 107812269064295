import axios from 'axios';
import { React, useState, useEffect } from 'react'
import './tasks.css'
import { HiOutlineClock } from 'react-icons/hi'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import FilterTasks from './filterTasks'

export default function Tasks() {

    const { t } = useTranslation();
    const history = useHistory();
    const [tasksDefault, setTasksDefault] = useState();
    const [tasksSuccess, setTasksSuccess] = useState([]);
    const [tasksOverdue, setTasksOverdue] = useState([]);
    const [tasksToday, setTasksToday] = useState([]);
    const [tasksTommorow, setTasksTommorow] = useState([]);

    const [filter, setFilter] = useState({ managers: [], boards: [] });
    const [filterComplete, setFilterComplete] = useState(true);
    const [filterOverdue, setFilterOverdue] = useState(true);
    const [filterToday, setFilterToday] = useState(true);
    const [filterTommorow, setFilterTommorow] = useState(true);
    const [filterManagersList, setFilterManagersList] = useState([]);
    const [filterBoardsList, setFilterBoardsList] = useState([]);

    useEffect(() => {
        var deltaTime = new Date().getTimezoneOffset() / 60;
        axios.get("/tasks/" + deltaTime).then(response => {
            setTasksSuccess(response.data.TasksSuccess);
            setTasksOverdue(response.data.TasksOverdue);
            setTasksToday(response.data.TasksToday);
            setTasksTommorow(response.data.TasksTommorow);
            setFilterManagersList(response.data.TasksFilterManagers);
            setFilterBoardsList(response.data.TasksFilterBoards);
            setTasksDefault(response.data);
        });
    }, [])

    useEffect(() => {
        if (tasksDefault === undefined) return;
        setTasksSuccess(tasksDefault.TasksSuccess.filter(x => !filter.managers.includes(x.ManagerId) && !filter.boards.includes(x.BoardsId)).map(item => { return item }));
        setTasksOverdue(tasksDefault.TasksOverdue.filter(x => !filter.managers.includes(x.ManagerId) && !filter.boards.includes(x.BoardsId)).map(item => { return item }));
        setTasksToday(tasksDefault.TasksToday.filter(x => !filter.managers.includes(x.ManagerId) && !filter.boards.includes(x.BoardsId)).map(item => { return item }));
        setTasksTommorow(tasksDefault.TasksTommorow.filter(x => !filter.managers.includes(x.ManagerId) && !filter.boards.includes(x.BoardsId)).map(item => { return item }));
    }, [tasksDefault, filter])

    const taskItemBuild = (task, classTask) => {
        return (
            <div key={"Task_" + task.Id} className="taskItemBlock" onClick={() => history.push("/workplace/transactions/transaction/" + task.Transactions.Id + "/" + task.Id)}>
                <HiOutlineClock className={classTask + " taskItemClockIcon"} />
                <div className={classTask}>
                    {
                        ("0" + new Date(task.DateTime).getDate()).slice(-2) + "." + ("0" + (new Date(task.DateTime).getMonth() + 1)).slice(-2) + "." +
                        new Date(task.DateTime).getFullYear() + " " + ("0" + new Date(task.DateTime).getHours()).slice(-2) + ":" + ("0" + new Date(task.DateTime).getMinutes()).slice(-2)
                    }
                </div>
                <div className="taskItemTitle">{task.Title}</div>
                <div className="taskGrayText taskItalicText">{t("workplace.menuitems.tasks.for")} {task.Manager.Peoples.FullName}</div>
                <div className="taskLine"></div>
                <div className="taskTransactionText">{task.Transactions.Title}</div>
                <div className="taskGrayText taskItalicText taskSeperator">{task.Clients.Name}</div>
                <div className="taskGrayText">{task.Transactions.Price ? task.Transactions.Price : "-"}</div>
                {
                    task.Success
                        ? <div className="taskResultBlock">
                            <div className="taskGrayText taskResultTitle">{t("workplace.menuitems.tasks.result")}</div>
                            <div className='taskResultText'>{task.Result}</div>
                        </div>
                        : null
                }
            </div>
        );
    }

    return (
        <div id="tasks">
            {/* Фильтр */}
            <FilterTasks
                filter={filter}
                setFilter={setFilter}
                filterComplete={filterComplete} setFilterComplete={setFilterComplete}
                filterOverdue={filterOverdue} setFilterOverdue={setFilterOverdue}
                filterToday={filterToday} setFilterToday={setFilterToday}
                filterTommorow={filterTommorow} setFilterTommorow={setFilterTommorow}
                filterManagersList={filterManagersList}
                filterBoardsList={filterBoardsList}
            />
            <div id="TasksBlock">

                {/* Выполненные задачи */}
                {
                    filterComplete
                        ? <div className="tasksBoardBlock taskGrayTopLine">
                            <div className="taskBoardHeader taskGrayText">{t("workplace.menuitems.tasks.completedtasks")}</div>
                            <div className="taskBoardCount taskGrayText">{t("workplace.menuitems.tasks.numbertasks")}: {tasksSuccess.length}</div>
                            <div className="taskBoardContentBlock">
                                <div className="taskBoardContent">
                                    {
                                        tasksSuccess.map(task => taskItemBuild(task, "taskGrayText"))
                                    }
                                </div>
                            </div>
                        </div>
                        : null
                }

                {/* Просроченные задачи */}
                {
                    filterOverdue
                        ? <div id="TaskRedTopLine" className="tasksBoardBlock">
                            <div className="taskBoardHeader taskRedText">{t("workplace.menuitems.tasks.overduetasks")}</div>
                            <div className="taskBoardCount taskGrayText">{t("workplace.menuitems.tasks.numbertasks")}: {tasksOverdue.length}</div>
                            <div className="taskBoardContentBlock">
                                <div className="taskBoardContent">
                                    {
                                        tasksOverdue.map(task => taskItemBuild(task, "taskRedText"))
                                    }
                                </div>
                            </div>
                        </div>
                        : null
                }

                {/* Задачи на сегодня */}
                {
                    filterToday
                        ? <div id="TaskYellowTopLine" className="tasksBoardBlock">
                            <div className="taskBoardHeader taskYellowText">{t("workplace.menuitems.tasks.taskstoday")}</div>
                            <div className="taskBoardCount taskGrayText">{t("workplace.menuitems.tasks.numbertasks")}: {tasksToday.length}</div>
                            <div className="taskBoardContentBlock">
                                <div className="taskBoardContent">
                                    {
                                        tasksToday.map(task => taskItemBuild(task, "taskYellowText"))
                                    }
                                </div>
                            </div>
                        </div>
                        : null
                }

                {/* Задачи на завтра */}
                {
                    filterTommorow
                        ? <div id="TaskGreenTopLine" className="tasksBoardBlock">
                            <div className="taskBoardHeader taskGreenText">{t("workplace.menuitems.tasks.scheduledtasks")}</div>
                            <div className="taskBoardCount taskGreenText">{t("workplace.menuitems.tasks.numbertasks")}: {tasksTommorow.length}</div>
                            <div className="taskBoardContentBlock">
                                <div className="taskBoardContent">
                                    {
                                        tasksTommorow.map(task => taskItemBuild(task, "taskGreenText"))
                                    }
                                </div>
                            </div>
                        </div>
                        : null
                }
            </div>
        </div>
    )
}
