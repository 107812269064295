import React from 'react'

export default function AddGoodsServices() {
  return (
    <div id='addgoodsservices'>
        addgoodsservices
        <div>ДОБАВИТЬ ПОЗИЦИЮ</div>
        <div>123</div>
        <div>Выберите категорию</div>
        </div>
  )
}
