import { React, useState, useEffect } from 'react'
import './requisitesSettings.css'
import { useTranslation } from 'react-i18next'
import axios from 'axios';

export default function RequisitesSettings() {

    const { t } = useTranslation();

    const [contractorid, setContractorId] = useState(0);

    const [companyName, setCompanyName] = useState("");
    const [contactName, setContactName] = useState("");
    const [locality, setLocality] = useState("");
    const [address, setAddress] = useState("");
    const [department, setDepartment] = useState("");
    const [index, setIndex] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [fax, setFax] = useState("");
    const [inn, setINN] = useState("");
    const [offerperiod, setOfferPeriod] = useState("");
    const [bank, setBank] = useState("");
    const [codeBank, setCodeBank] = useState("");
    const [bankAccount, setBankAccount] = useState("");
    const [companyAccount, setCompanyAccount] = useState("");
    const [bik, setBIK] = useState("");
    const [bankINN, setBankINN] = useState("");
    const [governingBody, setGoverningBody] = useState("");
    const [supervisor, setSupervisor] = useState("");
    const [chairmanSupervisoryBoard, setChairmanSupervisoryBoard] = useState("");
    const [registeredCourt, setRegisteredCourt] = useState("");
    const [registrationNumber, setRegistrationNumber] = useState("");
    const [website, setWebsite] = useState("");

    const [companyNameValid, setCompanyNameValid] = useState(true)
    const [contactNameValid, setContactNameValid] = useState(true)
    const [localityValid, setLocalityValid] = useState(true)
    const [addressValid, setAddressValid] = useState(true)
    const [departmentValid, setDepartmentValid] = useState(true)
    const [indexValid, setIndexValid] = useState(true)
    const [emailValid, setEmailValid] = useState(true)
    const [phoneValid, setPhoneValid] = useState(true)
    const [faxValid, setFaxValid] = useState(true)
    const [innValid, setINNValid] = useState(true)
    const [offerperiodValid, setOfferPeriodValid] = useState(true)
    const [bankValid, setBankValid] = useState(true)
    const [codeBankValid, setCodeBankValid] = useState(true)
    const [bankAccountValid, setBankAccountValid] = useState(true)
    const [companyAccountValid, setCompanyAccountValid] = useState(true)
    const [bikValid, setBIKValid] = useState(true)
    const [bankINNValid, setBankINNValid] = useState(true);
    const [governingBodyValid, setGoverningBodyValid] = useState(true);
    const [supervisorValid, setSupervisorValid] = useState(true);
    const [chairmanSupervisoryBoardValid, setChairmanSupervisoryBoardValid] = useState(true);
    const [registeredCourtValid, setRegisteredCourtValid] = useState(true);
    const [registrationNumberValid, setRegistrationNumberValid] = useState(true);
    const [websiteValid, setWebsiteValid] = useState(true);

    const [buttonsBlock, setButtonsBlock] = useState(false);

    const [parameters, setParameters] = useState([]);

    const [requisites, setRequisites] = useState({ CompanyName: "", ContactName: "", Locality: "", Address: "", Index: "", Email: "", Phone: "", Fax: "", Department: "", INN: "", OfferPeriod: "", Bank: "", CodeBank: "", BankAccount: "", CompanyAccount: "", BIK: "", BankINN: "", GoverningBody: "", Supervisor: "", ChairmanSupervisoryBoard: "", RegisteredCourt: "", RegistrationNumber: "", Website: "" })

    useEffect(() => {
        axios.get("/getrequisitescontractor/0").then(response => response.data).then(data => {
            setContractorId(data.ContractorId);
            setCompanyName(data.CompanyName);
            setContactName(data.ContactName);
            setLocality(data.Locality);
            setAddress(data.Address);
            setIndex(data.Index);
            setEmail(data.Email);
            setPhone(data.Phone);
            setFax(data.Fax);
            setDepartment(data.Department);
            setINN(data.INN);
            setOfferPeriod(data.OfferPeriod);
            setBank(data.Bank);
            setCodeBank(data.CodeBank);
            setBankAccount(data.BankAccount);
            setCompanyAccount(data.CompanyAccount);
            setBIK(data.BIK);
            setBankINN(data.BankINN);
            setGoverningBody(data.GoverningBody);
            setSupervisor(data.Supervisor);
            setChairmanSupervisoryBoard(data.ChairmanSupervisoryBoard);
            setRegisteredCourt(data.RegisteredCourt);
            setRegistrationNumber(data.RegistrationNumber);
            setWebsite(data.Website);
            setParameters(data.ParametersLink);
            setRequisites({
                CompanyName: data.CompanyName,
                ContactName: data.ContactName,
                Locality: data.Locality,
                Address: data.Address,
                Index: data.Index,
                Email: data.Email,
                Phone: data.Phone,
                Fax: data.Fax,
                Department: data.Department,
                INN: data.INN,
                OfferPeriod: data.OfferPeriod,
                Bank: data.Bank,
                CodeBank: data.CodeBank,
                BankAccount: data.BankAccount,
                CompanyAccount: data.CompanyAccount,
                BIK: data.BIK,
                BankINN: data.BankINN,
                GoverningBody: data.GoverningBody,
                Supervisor: data.Supervisor,
                ChairmanSupervisoryBoard: data.ChairmanSupervisoryBoard,
                RegisteredCourt: data.RegisteredCourt,
                RegistrationNumber: data.RegistrationNumber,
                Website: data.Website
            });
            setButtonsBlock(false);
        })
    }, [])

    useEffect(() => {
        if (department === requisites.Department &&
            inn === requisites.INN &&
            offerperiod === requisites.OfferPeriod &&
            companyName === requisites.CompanyName &&
            contactName === requisites.ContactName &&
            locality === requisites.Locality &&
            address === requisites.Address &&
            index === requisites.Index &&
            email === requisites.Email &&
            phone === requisites.Phone &&
            fax === requisites.Fax &&
            bank === requisites.Bank &&
            codeBank === requisites.CodeBank &&
            bankAccount === requisites.BankAccount &&
            companyAccount === requisites.CompanyAccount &&
            bankINN === requisites.BankINN &&
            bik === requisites.BIK &&
            governingBody === requisites.GoverningBody &&
            supervisor === requisites.Supervisor &&
            chairmanSupervisoryBoard === requisites.ChairmanSupervisoryBoard &&
            registeredCourt === requisites.RegisteredCourt &&
            registrationNumber === requisites.RegistrationNumber &&
            website === requisites.Website)
            setButtonsBlock(false);
        else
            setButtonsBlock(true);
    }, [companyName, contactName, locality, address, index, email, phone, fax, department, inn, offerperiod, bank, codeBank, bankAccount, companyAccount, bik, bankINN, governingBody, supervisor, chairmanSupervisoryBoard, registeredCourt, registrationNumber, website])// eslint-disable-line react-hooks/exhaustive-deps

    const cancelRequisites = () => {
        setCompanyName(requisites.CompanyName);
        setContactName(requisites.ContactName);
        setLocality(requisites.Locality);
        setAddress(requisites.Address);
        setIndex(requisites.Index);
        setEmail(requisites.Email);
        setPhone(requisites.Phone);
        setFax(requisites.Fax);
        setDepartment(requisites.Department);
        setINN(requisites.INN);
        setOfferPeriod(requisites.OfferPeriod);
        setBank(requisites.Bank);
        setCodeBank(requisites.CodeBank);
        setBankAccount(requisites.BankAccount);
        setCompanyAccount(requisites.CompanyAccount);
        setBIK(requisites.BIK);
        setBankINN(requisites.BankINN);
        setGoverningBody(requisites.GoverningBody);
        setSupervisor(requisites.Supervisor);
        setChairmanSupervisoryBoard(requisites.ChairmanSupervisoryBoard);
        setRegisteredCourt(requisites.RegisteredCourt);
        setRegistrationNumber(requisites.RegistrationNumber);
        setWebsite(requisites.Website);
        setButtonsBlock(false);
    }

    const editrequisites = () => {
        var invalid = false;
        let parametersTitle = ["CompanyName", "ContactName", "Locality", "Address", "Department", "Index", "Email", "Phone", "Fax", "INN", "OfferPeriod", "Bank", "CodeBank", "BankAccount", "CompanyAccount", "BIK", "BankINN", "GoverningBody", "Supervisor", "ChairmanSupervisoryBoard", "RegisteredCourt", "RegistrationNumber", "Website"];
        let hooksValue = [companyName, contactName, locality, address, department, index, email, phone, fax, inn, offerperiod, bank, codeBank, bankAccount, companyAccount, bik, bankINN, governingBody, supervisor, chairmanSupervisoryBoard, registeredCourt, registrationNumber, website];
        let hooksValidValue = [setCompanyNameValid, setContactNameValid, setLocalityValid, setDepartmentValid, setAddressValid, setIndexValid, setEmailValid, setPhoneValid, setFaxValid, setINNValid, setOfferPeriodValid, setBankValid, setCodeBankValid, setBankAccountValid, setCompanyAccountValid, setBIKValid, setBankINNValid, setGoverningBodyValid, setSupervisorValid, setChairmanSupervisoryBoardValid, setRegisteredCourtValid, setRegistrationNumberValid, setWebsiteValid];

        hooksValue.forEach((hookVaLue, index) => {
            if (hookVaLue.trim() === "" && parameters.includes(parametersTitle[index])) {
                invalid = true;
                hooksValidValue[index](false)
            }
        });

        if (invalid) return;

        let formData = new FormData();
        formData.append('ContractorId', contractorid);
        formData.append('CompanyName', companyName);
        formData.append('ContactName', contactName);
        formData.append('Locality', locality);
        formData.append('Address', address);
        formData.append('Index', index);
        formData.append('Email', email);
        formData.append('Phone', phone);
        formData.append('Fax', fax);
        formData.append('Department', department);
        formData.append('INN', inn);
        formData.append('OfferPeriod', offerperiod);
        formData.append('Bank', bank);
        formData.append('CodeBank', codeBank);
        formData.append('BankAccount', bankAccount);
        formData.append('CompanyAccount', companyAccount);
        formData.append('BIK', bik);
        formData.append('BankINN', bankINN);
        formData.append('GoverningBody', governingBody);
        formData.append('Supervisor', supervisor);
        formData.append('ChairmanSupervisoryBoard', chairmanSupervisoryBoard);
        formData.append('RegisteredCourt', registeredCourt);
        formData.append('RegistrationNumber', registrationNumber);
        formData.append('Website', website);

        axios.post("/editrequisitescontractor", formData, { headers: { 'Content-Type': 'multipart/form-data' } })
            .then(() => {
                setRequisites({
                    CompanyName: companyName,
                    ContactName: contactName,
                    Locality: locality,
                    Address: address,
                    Index: index,
                    Email: email,
                    Phone: phone,
                    Fax: fax,
                    Department: department,
                    INN: inn,
                    OfferPeriod: offerperiod,
                    Bank: bank,
                    CodeBank: codeBank,
                    BankAccount: bankAccount,
                    CompanyAccount: companyAccount,
                    BIK: bik,
                    BankINN: bankINN,
                    GoverningBody: governingBody,
                    Supervisor: supervisor,
                    ChairmanSupervisoryBoard: chairmanSupervisoryBoard,
                    RegisteredCourt: registeredCourt,
                    RegistrationNumber: registrationNumber,
                    Website: website
                });
                setButtonsBlock(false);
            })
    }

    return (
        <div id="requisitesSettings">
            <div id='RequisitesSettingsForm'>
                <div id='RequisitesSettingsFormBlocks'>
                    <div>
                        <div className='requisitesSettingsTitle'>
                            <div>{t("workplace.menuitems.settingsContractors.companyrequisites")}</div>
                        </div>
                        <div className="forminputmonoblock">
                            <div className="forminputblock">
                                <input autoFocus autoComplete="new-password" id="companyName" value={companyName ? companyName : ""} onChange={e => { setCompanyName(e.target.value); setCompanyNameValid(true); }} placeholder=" " className={companyNameValid ? "forminput" : "forminput Invalid"} />
                                <label htmlFor="companyName" className="formlabel">{t("workplace.menuitems.requisites.companyname")}</label>
                                <div className={companyNameValid ? "forminputvalid textValidSettingsContractors" : "forminputvalid textValidSettingsContractors Invalid"}>{t("workplace.menuitems.requisites.validcompanyname")}</div>
                            </div>
                        </div>
                        <div className="forminputmonoblock">
                            <div className="forminputblock">
                                <input autoComplete="new-password" id="contactName" value={contactName} onChange={e => { setContactName(e.target.value); setContactNameValid(true); }} placeholder=" " className={contactNameValid ? "forminput" : "forminput Invalid"} />
                                <label htmlFor="contactName" className="formlabel">{t("workplace.menuitems.requisites.contactname")}</label>
                                <div className={contactNameValid ? "forminputvalid textValidSettingsContractors" : "forminputvalid textValidSettingsContractors Invalid"}>{t("workplace.menuitems.requisites.validcontactname")}</div>
                            </div>
                        </div>
                        <div className="forminputmonoblock">
                            <div className="forminputblock">
                                <input autoComplete="new-password" id="locality" value={locality} onChange={e => { setLocality(e.target.value); setLocalityValid(true); }} placeholder=" " className={localityValid ? "forminput" : "forminput Invalid"} />
                                <label htmlFor="locality" className="formlabel">{t("workplace.menuitems.requisites.locality")}</label>
                                <div className={localityValid ? "forminputvalid textValidSettingsContractors" : "forminputvalid textValidSettingsContractors Invalid"}>{t("workplace.menuitems.requisites.validlocality")}</div>
                            </div>
                        </div>
                        <div className="forminputmonoblock">
                            <div className="forminputblock">
                                <input autoComplete="new-password" id="address" value={address} onChange={e => { setAddress(e.target.value); setAddressValid(true); }} placeholder=" " className={addressValid ? "forminput" : "forminput Invalid"} />
                                <label htmlFor="address" className="formlabel">{t("workplace.menuitems.requisites.address")}</label>
                                <div className={addressValid ? "forminputvalid textValidSettingsContractors" : "forminputvalid textValidSettingsContractors Invalid"}>{t("workplace.menuitems.requisites.validaddress")}</div>
                            </div>
                        </div>
                        <div className="forminputmonoblock">
                            <div className="forminputblock">
                                <input autoComplete="new-password" id="department" value={department} onChange={e => { setDepartment(e.target.value); setDepartmentValid(true); }} placeholder=" " className="forminput" />
                                <label htmlFor="department" className="formlabel">{t("workplace.menuitems.requisites.department")}</label>
                                <div className={departmentValid ? "forminputvalid textValidSettingsContractors" : "forminputvalid textValidSettingsContractors Invalid"}>{t("workplace.menuitems.requisites.validdepartment")}</div>
                            </div>
                        </div>
                        <div className="forminputgroupblock">
                            <div className="forminputblock">
                                <input autoComplete="new-password" id="index" value={index} onChange={e => { setIndex(e.target.value); setIndexValid(true); }} placeholder=" " className={indexValid ? "forminput" : "forminput Invalid"} />
                                <label htmlFor="index" className="formlabel">{t("workplace.menuitems.requisites.index")}</label>
                                <div className={indexValid ? "forminputvalid textValidSettingsContractors" : "forminputvalid textValidSettingsContractors Invalid"}>{t("workplace.menuitems.requisites.validindex")}</div>
                            </div>
                            <div className="forminputblock">
                                <input autoComplete="new-password" id="email" value={email} onChange={e => { setEmail(e.target.value); setEmailValid(true); }} placeholder=" " className={emailValid ? "forminput" : "forminput Invalid"} />
                                <label htmlFor="email" className="formlabel">E-mail</label>
                                <div className={emailValid ? "forminputvalid textValidSettingsContractors" : "forminputvalid textValidSettingsContractors Invalid"}>{t("workplace.menuitems.requisites.validemail")}</div>
                            </div>
                        </div>
                        <div className="forminputgroupblock">
                            <div className="forminputblock">
                                <input autoComplete="new-password" id="phone" value={phone} onChange={e => { setPhone(e.target.value); setPhoneValid(true); }} placeholder=" " className={phoneValid ? "forminput" : "forminput Invalid"} />
                                <label htmlFor="phone" className="formlabel">{t("workplace.menuitems.requisites.phone")}</label>
                                <div className={phoneValid ? "forminputvalid textValidSettingsContractors" : "forminputvalid textValidSettingsContractors Invalid"}>{t("workplace.menuitems.requisites.validphone")}</div>
                            </div>
                            <div className="forminputblock">
                                <input autoComplete="new-password" id="fax" value={fax} onChange={e => { setFax(e.target.value); setFaxValid(true); }} placeholder=" " className={faxValid ? "forminput" : "forminput Invalid"} />
                                <label htmlFor="fax" className="formlabel">{t("workplace.menuitems.requisites.fax")}</label>
                                <div className={faxValid ? "forminputvalid textValidSettingsContractors" : "forminputvalid textValidSettingsContractors Invalid"}>{t("workplace.menuitems.requisites.validfax")}</div>
                            </div>
                        </div>
                        <div className="forminputgroupblock">
                            <div className="forminputblock">
                                <input autoComplete="new-password" id="inn" value={inn} onChange={e => { setINN(e.target.value); setINNValid(true); }} placeholder=" " className="forminput" />
                                <label htmlFor="inn" className="formlabel">{t("workplace.menuitems.requisites.inn")}</label>
                                <div className={innValid ? "forminputvalid textValidSettingsContractors" : "forminputvalid textValidSettingsContractors Invalid"}>{t("workplace.menuitems.requisites.validinn")}</div>
                            </div>
                            <div className="forminputblock">
                                <input autoComplete="new-password" id="offerperiod" value={offerperiod} onChange={e => { setOfferPeriod(e.target.value); setOfferPeriodValid(true); }} placeholder=" " className="forminput" />
                                <label htmlFor="offerperiod" className="formlabel">{t("workplace.menuitems.requisites.offerperiod")}</label>
                                <div className={offerperiodValid ? "forminputvalid textValidSettingsContractors" : "forminputvalid textValidSettingsContractors Invalid"}>{t("workplace.menuitems.requisites.validofferperiod")}</div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className='requisitesSettingsTitle'>
                            <div>{t("workplace.menuitems.settingsContractors.bankrequisites")}</div>
                        </div>
                        <div className="forminputmonoblock">
                            <div className="forminputblock">
                                <input value={bank} onChange={e => { setBank(e.target.value); setBankValid(true); }} placeholder=" " id="RequisitesBank" className="addcontractorforminput" />
                                <label htmlFor="RequisitesBank" className="addcontractorformlabel">{t("workplace.menuitems.requisites.bank")}</label>
                                <div className={bankValid ? "forminputvalid textValidSettingsContractors" : "forminputvalid textValidSettingsContractors Invalid"}>{t("workplace.menuitems.requisites.validbank")}</div>
                            </div>
                        </div>
                        <div className="forminputmonoblock">
                            <div className="forminputblock">
                                <input value={codeBank} onChange={e => { setCodeBank(e.target.value); setCodeBankValid(true); }} placeholder=" " id="RequisitesCodeBank" className="addcontractorforminput" />
                                <label htmlFor="RequisitesCodeBank" className="addcontractorformlabel">{t("workplace.menuitems.requisites.codebank")}</label>
                                <div className={codeBankValid ? "forminputvalid textValidSettingsContractors" : "forminputvalid textValidSettingsContractors Invalid"}>{t("workplace.menuitems.requisites.validcodeBank")}</div>
                            </div>
                        </div>
                        <div className="forminputgroupblock">
                            <div className="forminputblock">
                                <input value={bankAccount} onChange={e => { setBankAccount(e.target.value); setBankAccountValid(true); }} placeholder=" " id="RequisitesBankAccount" className="addcontractorforminput" />
                                <label htmlFor="RequisitesBankAccount" className="addcontractorformlabel">{t("workplace.menuitems.requisites.bankaccount")}</label>
                                <div className={bankAccountValid ? "forminputvalid textValidSettingsContractors" : "forminputvalid textValidSettingsContractors Invalid"}>{t("workplace.menuitems.requisites.validbankAccount")}</div>
                            </div>
                            <div className="forminputblock">
                                <input value={companyAccount} onChange={e => { setCompanyAccount(e.target.value); setCompanyAccountValid(true); }} placeholder=" " id="RequisitesCompanyAccount" className="addcontractorforminput" />
                                <label htmlFor="RequisitesCompanyAccount" className="addcontractorformlabel">{t("workplace.menuitems.requisites.companyaccount")}</label>
                                <div className={companyAccountValid ? "forminputvalid textValidSettingsContractors" : "forminputvalid textValidSettingsContractors Invalid"}>{t("workplace.menuitems.requisites.validcompanyAccount")}</div>
                            </div>
                        </div>
                        <div className="forminputgroupblock">
                            <div className="forminputblock">
                                <input value={bik} onChange={e => { setBIK(e.target.value); setBIKValid(true); }} placeholder=" " id="RequisitesBIK" className="addcontractorforminput" />
                                <label htmlFor="RequisitesBIK" className="addcontractorformlabel">{t("workplace.menuitems.requisites.bik")}</label>
                                <div className={bikValid ? "forminputvalid textValidSettingsContractors" : "forminputvalid textValidSettingsContractors Invalid"}>{t("workplace.menuitems.requisites.validbik")}</div>
                            </div>
                            <div className="forminputblock">
                                <input value={bankINN} onChange={e => { setBankINN(e.target.value); setBankINNValid(true); }} placeholder=" " id="RequisitesBankINN" className="addcontractorforminput" />
                                <label htmlFor="RequisitesBankINN" className="addcontractorformlabel">{t("workplace.menuitems.requisites.bankinn")}</label>
                                <div className={bankINNValid ? "forminputvalid textValidSettingsContractors" : "forminputvalid textValidSettingsContractors Invalid"}>{t("workplace.menuitems.requisites.validbankINN")}</div>
                            </div>
                        </div>
                        <div className="forminputgroupblock">
                            <div className="forminputblock">
                                <input value={governingBody} onChange={e => { setGoverningBody(e.target.value); setGoverningBodyValid(true); }} placeholder=" " id="RequisitesGoverningBody" className="addcontractorforminput" />
                                <label htmlFor="RequisitesGoverningBody" className="addcontractorformlabel">{t("workplace.menuitems.requisites.governingbody")}</label>
                                <div className={governingBodyValid ? "forminputvalid textValidSettingsContractors" : "forminputvalid textValidSettingsContractors Invalid"}>{t("workplace.menuitems.requisites.validgoverningBody")}</div>
                            </div>
                            <div className="forminputblock">
                                <input value={supervisor} onChange={e => { setSupervisor(e.target.value); setSupervisorValid(true); }} placeholder=" " id="RequisitesSupervisor" className="addcontractorforminput" />
                                <label htmlFor="RequisitesSupervisor" className="addcontractorformlabel">{t("workplace.menuitems.requisites.supervisor")}</label>
                                <div className={supervisorValid ? "forminputvalid textValidSettingsContractors" : "forminputvalid textValidSettingsContractors Invalid"}>{t("workplace.menuitems.requisites.validsupervisor")}</div>
                            </div>
                        </div>
                        <div className="forminputgroupblock">
                            <div className="forminputblock">
                                <input value={chairmanSupervisoryBoard} onChange={e => { setChairmanSupervisoryBoard(e.target.value); setChairmanSupervisoryBoardValid(true); }} placeholder=" " id="RequisitesChairmanSupervisoryBoard" className="addcontractorforminput" />
                                <label htmlFor="RequisitesChairmanSupervisoryBoard" className="addcontractorformlabel">{t("workplace.menuitems.requisites.chairmansupervisoryboard")}</label>
                                <div className={chairmanSupervisoryBoardValid ? "forminputvalid textValidSettingsContractors" : "forminputvalid textValidSettingsContractors Invalid"}>{t("workplace.menuitems.requisites.validchairmanSupervisoryBoard")}</div>
                            </div>
                            <div className="forminputblock">
                                <input value={registeredCourt} onChange={e => { setRegisteredCourt(e.target.value); setRegisteredCourtValid(true); }} placeholder=" " id="RequisitesRegisteredCourt" className="addcontractorforminput" />
                                <label htmlFor="RequisitesRegisteredCourt" className="addcontractorformlabel">{t("workplace.menuitems.requisites.registeredcourt")}</label>
                                <div className={registeredCourtValid ? "forminputvalid textValidSettingsContractors" : "forminputvalid textValidSettingsContractors Invalid"}>{t("workplace.menuitems.requisites.validregisteredCourt")}</div>
                            </div>
                        </div>
                        <div className="forminputgroupblock">
                            <div className="forminputblock">
                                <input value={registrationNumber} onChange={e => { setRegistrationNumber(e.target.value); setRegistrationNumberValid(true); }} placeholder=" " id="RequisitesRegistrationNumber" className="addcontractorforminput" />
                                <label htmlFor="RequisitesRegistrationNumber" className="addcontractorformlabel">{t("workplace.menuitems.requisites.registrationnumber")}</label>
                                <div className={registrationNumberValid ? "forminputvalid textValidSettingsContractors" : "forminputvalid textValidSettingsContractors Invalid"}>{t("workplace.menuitems.requisites.validregistrationNumber")}</div>
                            </div>
                            <div className="forminputblock">
                                <input value={website} onChange={e => { setWebsite(e.target.value); setWebsiteValid(true); }} placeholder=" " id="RequisitesWebsite" className="addcontractorforminput" />
                                <label htmlFor="RequisitesWebsite" className="addcontractorformlabel">{t("workplace.menuitems.requisites.website")}</label>
                                <div className={websiteValid ? "forminputvalid textValidSettingsContractors" : "forminputvalid textValidSettingsContractors Invalid"}>{t("workplace.menuitems.requisites.validwebsite")}</div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            {
                buttonsBlock
                    ? <div id="RequisitesSettingsButtonsBlock">
                        <div id="RequisitesSettingsButtonSave" onClick={() => editrequisites()}>{t("buttons.save")}</div>
                        <div id="RequisitesSettingsButtonCancel" onClick={() => cancelRequisites()}>{t("buttons.cancel")}</div>
                    </div>
                    : null
            }
        </div>
    )
}
