import { React, useState, useEffect } from 'react'
import { HiDotsVertical } from 'react-icons/hi'
import { RiArrowDownSLine } from 'react-icons/ri'
import { IoHammerOutline } from 'react-icons/io5'
import { BsFolder } from 'react-icons/bs'

import './workstree.css'

export default function Workstree({ tree, node, currentNode, currentNodeEditMode, setCurrentNodeEditMode, setCurrentNode, setMenuBlock, setMenuY, changenode, setSelectWork }) {

    const [open, setOpen] = useState(false);
    const [title, setTitle] = useState(node.Title);
    const [childrenList, setChildrenList] = useState([]);

    useEffect(() => {
        setChildrenList(tree.filter(x => x.ParentId === node.TypeWorkId))
    }, [tree, node.TypeWorkId])

    useEffect(() => {
        setTitle(node.Title)
    }, [node])

    return (
        <div className={node.ParentId === 0 ? "NodeMainParent" : null}>

            <div className={
                node.ParentId === 0
                    ? open
                        ? "NodeMainParentHeader Open"
                        : "NodeMainParentHeader"
                    : currentNode && currentNode.TypeWorkId === node.TypeWorkId
                        ? "NodeHeader Open"
                        : "NodeHeader"}
                        onContextMenu={e=>{e.preventDefault(); setMenuBlock(true); setMenuY(e.target.getBoundingClientRect().y); setCurrentNode(node);}}>

                {/* Заголовок */}
                <div onClick={() => { setOpen(!open); setCurrentNode(node); }} className="NodeHeaderTitleBlock">
                    {
                        childrenList.length > 0
                            ? <RiArrowDownSLine className={open ? "ArrowIcon Open" : "ArrowIcon"} />
                            : null
                    }
                    {
                        node.ParentId !== 0
                            ? node.Work
                                ? <IoHammerOutline className="NodeIcon" />
                                : <BsFolder className="NodeIcon" />
                            : null
                    }
                    {
                        currentNode && currentNode.TypeWorkId === node.TypeWorkId && currentNodeEditMode
                            ? <input
                                className="NodeHeaderTitleInput"
                                autoFocus
                                value={title}
                                onFocus={e => e.target.select()}
                                onBlur={e => { setCurrentNodeEditMode(false); changenode(e.target.value) }}
                                onChange={e => setTitle(e.target.value)} />
                            : <div>{title}</div>
                    }
                </div>

                <HiDotsVertical className="DotsIcon" onClick={(e) => { setMenuBlock(true); setMenuY(e.target.getBoundingClientRect().y); setCurrentNode(node); }} />

            </div>

            {/* Дочерние элементы */}
            {
                childrenList.length > 0
                    ?
                    <div onContextMenu={e=>e.preventDefault()} className={open || (currentNode && currentNode.ParentId === node.TypeWorkId) ? "NodeListBlock Open" : "NodeListBlock"}>
                        {childrenList.map(item => item.ParentId === node.TypeWorkId ? 
                        <Workstree 
                        key={"Node_" + item.TypeWorkId} 
                        tree={tree}
                        node={item} 
                        currentNode={currentNode} 
                        currentNodeEditMode={currentNodeEditMode} 
                        setCurrentNodeEditMode={setCurrentNodeEditMode} 
                        setCurrentNode={setCurrentNode} 
                        setMenuBlock={setMenuBlock} 
                        setMenuY={setMenuY} 
                        changenode={changenode} 
                        setSelectWork={setSelectWork}
                        /> : null)}
                    </div>
                    : null
            }
        </div>
    )
}
