import { React, useState, useEffect } from 'react'
import './topRightAngleSettings.css'
import axios from 'axios'
import { useTranslation } from 'react-i18next'
import { FiPlus, FiMinus } from 'react-icons/fi'
import { BsList } from 'react-icons/bs'

export default function TopRightAngleSettings() {

  const { t } = useTranslation();

  const [items, setItems] = useState([]);
  const [currentItem, setCurrentItem] = useState();

  useEffect(() => axios.get("/angletoprightrequisitescontractors").then(response => response.data).then(data => { setItems(data.Attributes); }), [])

  const angletoprightrequisitescontractorsitem = (item) => {
    return (
      <div className={'angletoprightrequisitescontractorsitem' + (item.Bold ? ' bold' : '') + (item.Italic ? ' italic' : '') + (item.Link ? ' link' : '')} key={'angletoprightrequisitescontractorsitem_' + item.Id}
        draggable={item.Link ? true : false}
        onDragEnter={e => dragEnterAttribute(e, item)}
        onDragStart={() => { setCurrentItem(item); }}
        onDragOver={e => { e.preventDefault(); }}
        onDrop={e => { dropAttribute(e); }}
      >
        {
          item.Link
            ? <BsList className='angletoprightrequisitescontractorsitemmoveicon'/>
            : null
        }
        {
          item.Link
            ? <input type='checkbox' checked className='angletoprightrequisitescontractorsiteminput' onChange={() => changeLink(item.Id)} id={'Angletoprightrequisitescontractorsiteminputlink_' + item.Id}/>
            : <input type='checkbox' className='angletoprightrequisitescontractorsiteminput' onChange={() => changeLink(item.Id)} id={'Angletoprightrequisitescontractorsiteminputlink_' + item.Id}/>
        }
        <label className={'angletoprightrequisitescontractorsitemtext'} htmlFor={'Angletoprightrequisitescontractorsiteminputlink_' + item.Id}>{t("workplace.menuitems.toprightangle." + item.NameParameter)}</label>
        {
          item.Link
            ? <div className='angletoprightrequisitescontractorsiteminputbold'>
              {
                item.Bold
                  ? <input type='checkbox' checked id={'Angletoprightrequisitescontractorsiteminputbold_' + item.Id} onChange={() => changeBold(item.Id)} />
                  : <input type='checkbox' id={'Angletoprightrequisitescontractorsiteminputbold_' + item.Id} onChange={() => changeBold(item.Id)} />
              }
              <label htmlFor={'Angletoprightrequisitescontractorsiteminputbold_' + item.Id}>B</label>
            </div>
            : null
        }
        {
          item.Link
            ? <div className='angletoprightrequisitescontractorsiteminputitalic'>
              {
                item.Italic
                  ? <input type='checkbox' checked id={'Angletoprightrequisitescontractorsiteminputitalic_' + item.Id} onChange={() => changeItalic(item.Id)} />
                  : <input type='checkbox' id={'Angletoprightrequisitescontractorsiteminputitalic_' + item.Id} onChange={() => changeItalic(item.Id)} />
              }
              <label htmlFor={'Angletoprightrequisitescontractorsiteminputitalic_' + item.Id}>I</label>
            </div>
            : null
        }
        {
          item.Link
            ? <div>
              <select onChange={e => { changeFontSize(item.Id, e.target.value) }} defaultValue={item.FontSize === 0 ? '10' : item.FontSize}>
                <option value='8'>8pt</option>
                <option value='10'>10pt</option>
                <option value='12'>12pt</option>
                <option value='14'>14pt</option>
                <option value='16'>16pt</option>
              </select>
            </div>
            : null
        }
        {
          item.Link
            ? <div className='angletoprightrequisitescontractorsitemmarginrowsblock'>
              <FiMinus className={'angletoprightrequisitescontractorsitemmarginrowsminus' + (item.MarginBottomCountRows === 0 ? " disabled" : "")} onClick={() => item.MarginBottomCountRows === 0 ? null : changeMarginRow(item.Id, false)} />
              <div>{item.MarginBottomCountRows}</div>
              <FiPlus className='angletoprightrequisitescontractorsitemmarginrowsplus' onClick={() => changeMarginRow(item.Id, true)} />
            </div>
            : null
        }
      </div>
    )
  }

  const sortAttributes = (a, b) => {
    if (a.Order > b.Order) return 1;
    else return -1;
  }

  const sortAttributesId = (a, b) => {
    if (a.Id > b.Id) return 1;
    else return -1;
  }

  const changeLink = (id) => {
    var item = items[items.findIndex(x => x.Id === id)];
    item.Link = !item.Link;
    if (item.Link)
      item.Order = items.filter(x => x.Link).length + 1;
    else
      item.Order = 0;
    items.filter(x => x.Link).sort(sortAttributes).map((item, index) => (
      item.Order = index + 1
    ))
    axios.post("/angletoprightrequisitescontractorslink", { AttributeId: id, Link: item.Link })
    setItems([...items]);
  }

  const changeBold = (id) => {
    var item = items[items.findIndex(x => x.Id === id)];
    item.Bold = !item.Bold;
    axios.post("/angletoprightrequisitescontractorsbold", { AttributeId: id, Bold: item.Bold })
    setItems([...items]);
  }

  const changeItalic = (id) => {
    var item = items[items.findIndex(x => x.Id === id)];
    item.Italic = !item.Italic;
    axios.post("/angletoprightrequisitescontractorsitalic", { AttributeId: id, Italic: item.Italic })
    setItems([...items]);
  }

  const changeMarginRow = (id, plus) => {
    var item = items[items.findIndex(x => x.Id === id)];
    item.MarginBottomCountRows = plus ? item.MarginBottomCountRows + 1 : item.MarginBottomCountRows - 1;
    axios.post("/angletoprightrequisitescontractorsmarginrow", { AttributeId: id, MarginBottomCountRows: item.MarginBottomCountRows })
    setItems([...items]);
  }

  const changeFontSize = (id, fontsize) => {
    var item = items[items.findIndex(x => x.Id === id)];
    item.FontSize = fontsize;
    axios.post("/angletoprightrequisitescontractorsfontsize", { AttributeId: id, FontSize: fontsize })
    setItems([...items]);
  }

  const dragEnterAttribute = (e, item) => {
    e.preventDefault();
    if (item.Id === currentItem.Id) return;
    items[items.findIndex(x => x.Id === currentItem.Id)].Order = item.Order;
    var order = 1;
    items.filter(x => x.Link && x.Id !== currentItem.Id).sort(sortAttributes).map(i => {
      if (order === item.Order) order++;
      i.Order = order;
      order++;
      return i;
    });
    setItems([...items])
  }

  const dropAttribute = (e) => {
    e.preventDefault();
    axios.post("/angletoprightrequisitescontractorsorder", { AttributeId: currentItem.Id, Order: currentItem.Order });
  }

  return (
    <div id='topRightAngleSettings'>
      <div id='TopRightAngleSettingsTitle'>{t("workplace.menuitems.settingsContractors.toprightangle")}</div>
      {
        items.length > 0
          ?
          <div>
            <div>
              {
                items.filter(x => x.Link).sort(sortAttributes).map(item => (
                  angletoprightrequisitescontractorsitem(item)
                ))
              }
            </div>
            <div className='angletoprightrequisitescontractorsitem'>---------------------------------------------------------------------------------------------------------</div>
            <div>
              {
                items.filter(x => !x.Link).sort(sortAttributesId).map(item => (
                  angletoprightrequisitescontractorsitem(item)
                ))
              }
            </div>
          </div>
          : null
      }
    </div>
  )
}
