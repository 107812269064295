import React from 'react'
import "./settingsContractors.css"
import { Route, NavLink, useHistory } from 'react-router-dom'
import UsersContractors from './usersContractors/usersContractors'
import AddUsersContractors from './usersContractors/addEditUsersContractors/addUsersContractors'
import EditUsersContractors from './usersContractors/addEditUsersContractors/editUsersContractors'
import RequisitesSettings from './requisitesSettings/requisitesSettings'
import DocumentsSettings from './documentsSettings/documentsSettings'
import TopRightAngleSettings from './topRightAngleSettings/topRightAngleSettings'
import FooterCPSettings from './footerCPSettings/footerCPSettings'
import { useTranslation } from 'react-i18next'

export default function SettingsContractors() {

    const history = useHistory();
    const {t} = useTranslation();

    return (
        <div id="settingsContractors">
            <div id="settingsMenuBlock">
                <NavLink to="/workplace/settingsContractors" className={history.location.pathname === "/workplace/settingsContractors" ? "settingsMenuItem Active" : "settingsMenuItem"}>{t("workplace.menuitems.settingsContractors.users")}</NavLink>
                <NavLink to="/workplace/settingsContractors/requisites" className={history.location.pathname.includes("/workplace/settingsContractors/requisites") ? "settingsMenuItem Active" : "settingsMenuItem"}>{t("workplace.menuitems.settingsContractors.requisites")}</NavLink>
                <NavLink to="/workplace/settingsContractors/documents" className={history.location.pathname.includes("/workplace/settingsContractors/documents") ? "settingsMenuItem Active" : "settingsMenuItem"}>{t("workplace.menuitems.settingsContractors.documents")}</NavLink>
                <NavLink to="/workplace/settingsContractors/toprightangle" className={history.location.pathname.includes("/workplace/settingsContractors/toprightangle") ? "settingsMenuItem Active" : "settingsMenuItem"}>{t("workplace.menuitems.settingsContractors.toprightangle")}</NavLink>
                <NavLink to="/workplace/settingsContractors/footercpsetting" className={history.location.pathname.includes("/workplace/settingsContractors/footercpsetting") ? "settingsMenuItem Active" : "settingsMenuItem"}>{t("workplace.menuitems.settingsContractors.footer")}</NavLink>
            </div>
            <div id="settingsContentBlock">
                <Route exact path="/workplace/settingsContractors" component={UsersContractors} />
                <Route exact path="/workplace/settingsContractors/userscontractors/add" component={AddUsersContractors} />
                <Route exact path="/workplace/settingsContractors/userscontractors/edit/:lupid" component={EditUsersContractors} />
                <Route exact path="/workplace/settingsContractors/requisites" component={RequisitesSettings} />
                <Route exact path="/workplace/settingsContractors/documents" component={DocumentsSettings} />
                <Route exact path="/workplace/settingsContractors/toprightangle" component={TopRightAngleSettings} />
                <Route exact path="/workplace/settingsContractors/footercpsetting" component={FooterCPSettings} />
            </div>
        </div>
    )
}
