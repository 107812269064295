import { React, useState, useEffect } from 'react'
import { useParams, useHistory, NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import "./client.css"
import axios from 'axios'
import { MdOutlineAddAPhoto } from 'react-icons/md'
import { AiOutlineRight, AiOutlineDelete } from 'react-icons/ai'
import { animateScroll } from "react-scroll"
import { HiClock } from 'react-icons/hi'
import { IoMdClose, IoMdCheckmark } from 'react-icons/io'
import RequisitesClient from './requisitesClient'
import { MdOutlineArrowForward, MdArrowBackIosNew } from 'react-icons/md'
import ClientTransaction from './clientTransaction'
import ContactPersons from '../contactPersons/contactPersons'
import LinkContactPersons from '../contactPersons/linkContactPersons'

export default function Client() {

    const { t } = useTranslation();
    const { clientid } = useParams();
    const history = useHistory();

    const [client, setClient] = useState();
    const [clientInfoBlockDetalizationButton, setClientInfoBlockDetalizationButton] = useState(false);
    const [Name, setName] = useState("");
    const [ShortName, setShortName] = useState("");
    const [Company, setCompany] = useState("");
    const [Address, setAddress] = useState("");
    const [Index, setIndex] = useState("");
    const [Phone, setPhone] = useState("");
    const [Email, setEmail] = useState("");
    // const [Individual, setIndividual] = useState(false);
    const [File, setFile] = useState();
    const [Preview, setPreview] = useState();
    const [clientTasks, setClientTasks] = useState([]);
    const [historyList, setHistoryList] = useState([]);
    const [validAddTransaction, setValidAddTransaction] = useState(false);
    const [removed, setRemoved] = useState(true);
    const [removeBlock, setRemoveBlock] = useState(false);
    const [addTransactionBlock, setAddTransactionBlock] = useState(false);
    const [addTransactionValid, setAddTransactionValid] = useState(false);
    const [clientTransactions, setClientTransactions] = useState([]);
    const [contactPersons, setContactPersons] = useState([]);

    const [requisites, setRequisites] = useState();
    const [requisitesBlock, setRequisitesBlock] = useState(false);

    const [contactPersonalBlock, setContactPersonalBlock] = useState(false);

    useEffect(() => {
        if (clientid !== "0") {
            axios.get("/clientcheckremove/" + clientid).then(response => {
                if (response.data === true) history.push("/workplace/clients");
                var deltaTime = new Date().getTimezoneOffset() / 60;
                axios.get("/clientinfo/" + clientid + "/" + deltaTime).then(response => response.data).then(data => {
                    setClient(data);
                    if (data.Clients.Name !== null) setName(data.Clients.Name);
                    if (data.Clients.ShortName !== null) setShortName(data.Clients.ShortName);
                    if (data.Clients.Company !== null) setCompany(data.Clients.Company);
                    if (data.Clients.Address !== null) setAddress(data.Clients.Address);
                    if (data.Clients.Index !== null) setIndex(data.Clients.Index);
                    if (data.Clients.Phone !== null) setPhone(data.Clients.Phone);
                    if (data.Clients.Email !== null) setEmail(data.Clients.Email);
                    // setIndividual(data.Clients.Individual);
                    setHistoryList(data.HistoryList);
                    setClientTasks(data ? data.ClientTasks ? data.ClientTasks : null : null);
                    setRemoved(data ? data.Removed : false);
                    setClientTransactions(data ? data.TransactionsView : []);
                    setAddTransactionBlock(data ? data.BlockAddTransaction : false);
                    setRequisites(data ? data.Requisites ? data.RequisitesClients : null : null);
                })
            });
        }
    }, [])// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (clientid === "0" && (Name !== "" || ShortName !== "" || Company !== "" || Address !== "" || Index !== "" || Phone !== "" || Email !== "" || File !== undefined)) history.goBack();
    }, [clientid])// eslint-disable-line react-hooks/exhaustive-deps

    const addclient = () => {
        if (Name === "" && ShortName === "" && Company === "" && Address === "" && Index === "" && Phone === "" && Email === "" && File === undefined) return;
        let formData = new FormData();
        formData.append('Name', Name);
        formData.append('ShortName', ShortName);
        formData.append('Company', Company);
        formData.append('Address', Address);
        formData.append('Index', Index);
        formData.append('Phone', Phone);
        formData.append('Email', Email);
        formData.append('PhotoFile', File);
        axios.post("/addclient", formData, { headers: { 'Content-Type': 'multipart/form-data' } }).then(response => response.data).then((data) => stepClient(data));
    }

    const stepClient = (id) => {
        history.push("/workplace/clients/" + id);
    }

    const changeclient = () => {
        if (clientid === "0") { addclient(); setValidAddTransaction(false); }
        else axios.post("/editclientnophoto", { ClientId: clientid, Name: Name, ShortName: ShortName, Company: Company, Address: Address, Index: Index, Phone: Phone, Email: Email }).then(response => setHistoryList(response.data))
    }

    useEffect(() => {
        if (File === undefined) return;
        if (clientid === "0") {
            preview(File);
            addclient();
            setValidAddTransaction(false);
        }
        else {
            preview(File);
            let formData = new FormData();
            formData.append('ClientId', clientid);
            formData.append('PhotoFile', File);
            axios.post("/editclientphoto", formData, { headers: { 'Content-Type': 'multipart/form-data' } });
        }
    }, [File]);// eslint-disable-line react-hooks/exhaustive-deps

    const preview = (e) => {
        const reader = new FileReader();
        reader.onload = function () {
            setPreview(reader.result);
        }
        reader.readAsDataURL(e);
    }

    const addtransaction = () => {
        if (clientid === "0") setValidAddTransaction(true);
        else
            if (addTransactionBlock) setAddTransactionValid(true);
            else
                axios.post("/addtransaction", { ClientId: clientid }).then(response => response.data).then(data => history.push("/workplace/transactions/transaction/" + data))
    }

    useEffect(() => (
        historyList.length > 0
            ? animateScroll.scrollToBottom({
                duration: 0,
                containerId: "ClientHistoryContent"
            })
            : null
    ), [historyList]);

    useEffect(() => (axios.get("/clienthistory/" + clientid.toString()).then(response => setHistoryList(response.data))), [clientid]);

    const changeResultTask = (value, taskId) => {
        clientTasks[clientTasks.findIndex(x => x.Tasks.Id === taskId)].Tasks.Result = value;
        setClientTasks([...clientTasks]);
    }

    const successTask = (taskId) => {
        var result = clientTasks[clientTasks.findIndex(x => x.Tasks.Id === taskId)].Tasks.Result;
        if (result && result.trim().length > 0)
            axios.post("/successtask", { TaskId: taskId, Result: result, Clients: true }).then(response => {
                clientTasks.splice(clientTasks.findIndex(x => x.Tasks.Id === taskId), 1);
                setClientTasks([...clientTasks]);
                var deltaTime = new Date().getTimezoneOffset() / 60;
                setHistoryList(response.data);
                axios.get("/clientinfotransitions/" + clientid + "/" + deltaTime).then(response => setClientTransactions(response.data));
            })
    }

    const removeClient = () => {
        axios.get("/removeclient/" + clientid).then(() => history.push("/workplace/clients"))
    }

    useEffect(() => axios.get("/getrequisitesclient/" + clientid).then(response => setRequisites(response.data)), [contactPersons])// eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div id="client">

            <div className={"clientModalBlock" + (requisitesBlock ? " Open" : "")}>
                <RequisitesClient setRequisitesBlock={setRequisitesBlock} clientid={clientid} requisites={requisites} setRequisites={setRequisites} />
            </div>

            <div className={"clientModalBlock" + (contactPersonalBlock ? " Open" : "")}>
                <LinkContactPersons setContactPersonalBlock={setContactPersonalBlock} clientid={clientid} contactPersons={contactPersons} setContactPersons={setContactPersons} />
            </div>

            <div id="ClientBlock">
                <div id="ClientBlockContent">
                    <div id="ClientInfoBlock">
                        <div className="clientinfoblock">
                            <div id="ClientInfoTitleBlock">
                                <div className="clientinfotitle">{t("workplace.menuitems.client.title")}</div>
                                {clientid !== "0" ? <AiOutlineDelete id="ClientInfoTitleBlockDeleteIcon" className={removeBlock ? "Open" : null} onClick={() => setRemoveBlock(true)} /> : null}
                            </div>
                            {
                                removed
                                    ?
                                    <div id="ClientBlockDelete" className={removeBlock ? "Open" : null}>
                                        <div id="ClientConfirmRemoveText">{t("workplace.menuitems.client.confirmdelete")}</div>
                                        <div id="ClientConfirmRemoveButtonsBlock">
                                            <div className="boardConfirmRemoveButton BoardConfirmButtonYes" onClick={() => removeClient()}><IoMdCheckmark /></div>
                                            <div onClick={() => setRemoveBlock(false)} className="boardConfirmRemoveButton BoardConfirmButtonNo"><IoMdClose /></div>
                                        </div>
                                    </div>
                                    :
                                    <div id="ClientBlockDelete" className={removeBlock ? "Open" : null}>
                                        <div id="ClientConfirmRemoveText">{t("workplace.menuitems.client.blocktextdelete")}</div>
                                        <div id="ClientConfirmRemoveButtonsBlock">
                                            <div className="boardConfirmRemoveButton BoardConfirmButtonYes" onClick={() => setRemoveBlock(false)}><IoMdCheckmark /></div>
                                        </div>
                                    </div>
                            }
                            <div id="Clientphotoblock">
                                <div id="ClientPhotoInputBlock">
                                    <input style={{ display: "none" }} id={"ClientPhotoInput"} type="file" onChange={e => { setFile(e.target.files[0]); }} accept="image/png, image/jpeg" />
                                    <label id="ClientPhotoInputLabel" htmlFor={"ClientPhotoInput"}>
                                        {
                                            Preview
                                                ? <img id="ClientPhoto" src={Preview} alt="" />
                                                : client
                                                    ? client.Clients.Photo
                                                        ? <img id="ClientPhoto" src={client.Clients.Photo} alt="" />
                                                        : <MdOutlineAddAPhoto id="ClientPhotoNull" />
                                                    : <MdOutlineAddAPhoto id="ClientPhotoNull" />
                                        }
                                    </label>
                                </div>
                                {
                                    clientid === "0"
                                        ? <input id="FIOInput" autoFocus onChange={e => setName(e.target.value)} onBlur={() => changeclient()} value={Name} placeholder={t("workplace.menuitems.client.titleplaceholder")} className="clientinfoinput" />
                                        : <input id="FIOInput" onChange={e => setName(e.target.value)} onBlur={() => changeclient()} value={Name} placeholder={t("workplace.menuitems.client.titleplaceholder")} className="clientinfoinput" />
                                }
                                <MdArrowBackIosNew id='ClientInfoBlockDetalizationButton' onClick={() => setClientInfoBlockDetalizationButton(!clientInfoBlockDetalizationButton)} className={clientInfoBlockDetalizationButton ? "Open" : ""} />
                            </div>
                        </div>
                        <div id='ClientInfoBlockDetalization' className={clientInfoBlockDetalizationButton ? "Open" : ""}>
                            <div className="clientinfoblock">
                                <div className="clientinfotitle">{t("workplace.menuitems.client.shortname")}</div>
                                <input onChange={e => setShortName(e.target.value)} onBlur={() => changeclient()} value={ShortName} placeholder={t("workplace.menuitems.client.shortnameplaceholder")} className="clientinfoinput" />
                            </div>
                            <div className="clientinfoblock">
                                <div className="clientinfotitle">{t("workplace.menuitems.client.company")}</div>
                                <input onChange={e => setCompany(e.target.value)} onBlur={() => changeclient()} value={Company} placeholder={t("workplace.menuitems.client.companyplaceholder")} className="clientinfoinput" />
                            </div>
                            <div className="clientinfoblock">
                                <div className="clientinfotitle">{t("workplace.menuitems.client.address")}</div>
                                <input onChange={e => setAddress(e.target.value)} onBlur={() => changeclient()} value={Address} placeholder={t("workplace.menuitems.client.addressplaceholder")} className="clientinfoinput" />
                            </div>
                            <div className="clientinfoblock">
                                <div className="clientinfotitle">{t("workplace.menuitems.client.index")}</div>
                                <input onChange={e => setIndex(e.target.value)} onBlur={() => changeclient()} value={Index} placeholder={t("workplace.menuitems.client.indexplaceholder")} className="clientinfoinput" />
                            </div>
                            <div className="clientinfoblock">
                                <div className="clientinfotitle">{t("workplace.menuitems.client.phone")}</div>
                                <input onChange={e => setPhone(e.target.value)} onBlur={() => changeclient()} value={Phone} placeholder={t("workplace.menuitems.client.phoneplaceholder")} className="clientinfoinput" />
                            </div>
                            <div className="clientinfoblock">
                                <div className="clientinfotitle">E-mail</div>
                                <input onChange={e => setEmail(e.target.value)} onBlur={() => changeclient()} value={Email} placeholder={t("workplace.menuitems.client.emailplaceholder")} className="clientinfoinput" />
                            </div>
                            {/* <div className="clientinfoblock">
                                <div className='forminputblock'>
                                    <input id="ClientIndividual" value={Individual} onChange={() => { setIndividual(!Individual); }} type='checkbox' />
                                    <label htmlFor="ClientIndividual" className='clientinfotitle' id='RequisitesClientIndividualLabel'>{t("workplace.menuitems.requisites.individual")}</label>
                                </div>
                            </div> */}
                        </div>
                    </div>
                    {
                        clientid === "0"
                            ? null
                            : requisites
                                ? <div id='RequisitesClientButton' onClick={() => setRequisitesBlock(true)}>
                                    <div>{t("workplace.menuitems.requisites.title")}</div>
                                    <MdOutlineArrowForward />
                                </div>
                                : null
                        // <div className='clientinfoblock' id="RequisitesContractorAddButton" onClick={() => setRequisitesBlock(true)}>{t("workplace.menuitems.requisites.requisitesaddbutton")}</div>
                    }
                    {
                        requisites
                            ? null
                            : <div id="LineInfoClient" />
                    }
                    {
                        clientid !== "0"
                            ? <div id='ClientContactPersonalsBlock'>
                                {contactPersons.length > 0 ? <div id="ClientContactPersonalsTitle" className="clientinfotitle">{t("workplace.menuitems.client.contactpersons")}</div> : null}
                                {
                                    contactPersons.map((item, index) => (
                                        <ContactPersons key={"ContactPersons_ А" + index} item={item} />
                                    ))
                                }
                                <div className="AddButton" onClick={() => { setContactPersonalBlock(true) }}>{t("workplace.menuitems.client.addcontactpersonbutton")}</div>
                            </div>
                            : null
                    }
                    {
                        clientid !== "0"
                            ? <div id="ClientTransactionBlock">
                                <div className="clientinfotitle">{t("workplace.menuitems.client.transactions")}</div>
                                <div id="ClientTransactionListBlock">
                                    <div id="ClientTransactionList">
                                        {
                                            clientTransactions.map((item, index) =>
                                                <ClientTransaction key={"ClientTransaction+_" + index} item={item} />
                                            )
                                        }
                                        <div id="ClientAddButtonsTransactionCPBlock">
                                            <div className="AddButton" id='ClientAddButtonTransaction' onClick={() => addtransaction()}>{t("workplace.menuitems.client.addtransaction")}</div>
                                        </div>
                                        <div className={"clientAddTransactionValid" + (addTransactionValid ? " Open" : "")}>{t("workplace.menuitems.client.validaddtransactionblock")}</div>
                                        <div className={"clientAddTransactionValid" + (validAddTransaction ? " Open" : "")}>{t("workplace.menuitems.client.validaddtransaction")}</div>
                                    </div>
                                </div>
                            </div>
                            : null
                    }
                </div>
            </div>
            <div id="ClientHistoryTasksBlock">
                {/* Задачи по клиенту */}
                <div id="ClientTasks">
                    <div className="clientHistoryTasksHeader">{t("workplace.menuitems.transaction.tasks")}</div>
                    <div className="clientHistoryTasksItemBlock">
                        <div id="ClientTasksContent">
                            {
                                clientTasks.map(task =>
                                    <div key={"TransactionTask_" + task.Tasks.Id} className={"transactionTaskItemBlock " + (task.Overdue || task.Today ? task.Overdue ? "taskOverdue" : task.Today ? "taskToday" : "taskWork" : "taskWork")}>
                                        <div className={"taskClockIconBlock taskItem " + (task.Overdue || task.Today ? task.Overdue ? "taskRed" : task.Today ? "taskYellow" : "taskGreen" : "taskGreen")}>
                                            <HiClock className="taskClockIcon" />
                                        </div>
                                        <div className={"taskItem " + (task.Overdue || task.Today ? task.Overdue ? "taskTextRed" : task.Today ? "taskTextYellow" : "taskTextGreen" : "taskTextGreen")}>
                                            {
                                                ("0" + new Date(task.Tasks.DateTime).getDate()).slice(-2) + "." + ("0" + (new Date(task.Tasks.DateTime).getMonth() + 1)).slice(-2) + "." +
                                                new Date(task.Tasks.DateTime).getFullYear() + " " + ("0" + new Date(task.Tasks.DateTime).getHours()).slice(-2) + ":" + ("0" + new Date(task.Tasks.DateTime).getMinutes()).slice(-2)
                                            }
                                        </div>
                                        <div className="taskItem taskItemInputBlock">
                                            <div className={"taskItem taskTransactionTitle " + (task.Overdue || task.Today ? task.Overdue ? "taskTextRed" : task.Today ? "taskTextYellow" : "taskTextGreen" : "taskTextGreen")}>{t("workplace.menuitems.transactions.transactiontitle")}: {task.Tasks.Transactions.Title}</div>
                                            <div className="taskItemInputTitleBlock">
                                                <div className="taskItemInputTitle">{task.Tasks.Manager.Peoples.FullName}</div>
                                                <div className="taskItemInputTitle">{t("workplace.menuitems.transaction.task")} "{task.Tasks.Title}"</div>
                                            </div>
                                            <div className="taskItemInputButtonBlock">
                                                <input placeholder={t("workplace.menuitems.transaction.resultplaceholder")} className="taskItemInput" onChange={e => changeResultTask(e.target.value, task.Tasks.Id)} />
                                                <div className={"taskItemButton " + (task.Overdue || task.Today ? task.Overdue ? "taskItemButtonRed" : task.Today ? "taskItemButtonYellow" : "taskItemButtonGreen" : "taskItemButtonGreen")} onClick={() => successTask(task.Tasks.Id)}>{t("workplace.menuitems.transaction.success")}</div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
                {/* История по клиенту */}
                <div id="ClientHistory">
                    <div className="clientHistoryTasksHeader">{t("workplace.menuitems.transaction.history")}</div>
                    <div className="clientHistoryTasksItemBlock">
                        <div id="ClientHistoryContent">
                            {
                                historyList.map((item, index) =>
                                (
                                    <div className="clientHistoryContentItem" key={"HistoryClient_" + index}>
                                        <div className="clientHistoryDateBlock">{item.Date}</div>
                                        {
                                            item.Items
                                                ?
                                                item.Items.map((itemhistory, index) =>
                                                    <div className="ClientHistoryClassEdit" key={"History_" + index}>
                                                        <div className="ClientHistoryBorder">
                                                            <div className="ClientHistoryBorderPoint"></div>
                                                        </div>
                                                        <div className="ClientHistoryDate">
                                                            {
                                                                ("0" + new Date(itemhistory.InsertedDate).getDate()).slice(-2) + "." + ("0" + (new Date(itemhistory.InsertedDate).getMonth() + 1)).slice(-2) + "." +
                                                                new Date(itemhistory.InsertedDate).getFullYear() + " " + ("0" + new Date(itemhistory.InsertedDate).getHours()).slice(-2) + ":" + ("0" + new Date(itemhistory.InsertedDate).getMinutes()).slice(-2)
                                                            }
                                                        </div>
                                                        <div>
                                                            <div className="ClientHistoryBlock">
                                                                <div className="ClientHistoryPeople">
                                                                    {itemhistory.InsertedLUP.Peoples.FullName}
                                                                </div>
                                                                {
                                                                    itemhistory.TransactionId
                                                                        ?
                                                                        itemhistory.TransactionTitle
                                                                            ? <div className="transactionHistoryLinkBlock">
                                                                                <div className="transactionHistoryText">{t("workplace.menuitems.transactions.transactiontitle")}</div>
                                                                                <NavLink to={"/workplace/transactions/transaction/" + itemhistory.TransactionId} className="textHistoryLink">
                                                                                    {itemhistory.TransactionTitle}
                                                                                </NavLink>
                                                                            </div>
                                                                            : <div className="transactionHistoryLinkBlock">
                                                                                <NavLink to={"/workplace/transactions/transaction/" + itemhistory.TransactionId} className="textHistoryLink">
                                                                                    {t("workplace.menuitems.transactions.transactiontitle")}
                                                                                </NavLink>
                                                                            </div>
                                                                        : null
                                                                }
                                                                {
                                                                    itemhistory.Client
                                                                        ? <div className="ClientHistoryText">
                                                                            {itemhistory.Name && itemhistory.Create && !itemhistory.Remove ? t("workplace.menuitems.client.titleadd") : null}
                                                                            {itemhistory.Name && !itemhistory.Create && !itemhistory.Remove ? t("workplace.menuitems.client.titlechange") : null}
                                                                            {itemhistory.Name && !itemhistory.Create && itemhistory.Remove ? t("workplace.menuitems.client.titleremove") : null}
                                                                            {itemhistory.ShortName && itemhistory.Create && !itemhistory.Remove ? t("workplace.menuitems.client.shortnameadd") : null}
                                                                            {itemhistory.ShortName && !itemhistory.Create && !itemhistory.Remove ? t("workplace.menuitems.client.shortnamechange") : null}
                                                                            {itemhistory.ShortName && !itemhistory.Create && itemhistory.Remove ? t("workplace.menuitems.client.shortnameremove") : null}
                                                                            {itemhistory.Company && itemhistory.Create && !itemhistory.Remove ? t("workplace.menuitems.client.companyadd") : null}
                                                                            {itemhistory.Company && !itemhistory.Create && !itemhistory.Remove ? t("workplace.menuitems.client.companychange") : null}
                                                                            {itemhistory.Company && itemhistory.Remove ? t("workplace.menuitems.client.companyremove") : null}
                                                                            {itemhistory.Address && itemhistory.Create && !itemhistory.Remove ? t("workplace.menuitems.client.addressadd") : null}
                                                                            {itemhistory.Address && !itemhistory.Create && !itemhistory.Remove ? t("workplace.menuitems.client.addresschange") : null}
                                                                            {itemhistory.Address && itemhistory.Remove ? t("workplace.menuitems.client.addressremove") : null}
                                                                            {itemhistory.Index && itemhistory.Create && !itemhistory.Remove ? t("workplace.menuitems.client.indexadd") : null}
                                                                            {itemhistory.Index && !itemhistory.Create && !itemhistory.Remove ? t("workplace.menuitems.client.indexchange") : null}
                                                                            {itemhistory.Index && itemhistory.Remove ? t("workplace.menuitems.client.indexremove") : null}
                                                                            {itemhistory.Phone && itemhistory.Create && !itemhistory.Remove ? t("workplace.menuitems.client.phoneadd") : null}
                                                                            {itemhistory.Phone && !itemhistory.Create && !itemhistory.Remove ? t("workplace.menuitems.client.phonechange") : null}
                                                                            {itemhistory.Phone && itemhistory.Remove ? t("workplace.menuitems.client.phoneremove") : null}
                                                                            {itemhistory.Email && itemhistory.Create && !itemhistory.Remove ? t("workplace.menuitems.client.emailadd") : null}
                                                                            {itemhistory.Email && !itemhistory.Create && !itemhistory.Remove ? t("workplace.menuitems.client.emailchange") : null}
                                                                            {itemhistory.Email && itemhistory.Remove ? t("workplace.menuitems.client.emailremove") : null}
                                                                            {itemhistory.TransactionsId ? t("workplace.menuitems.client.transactionadd") : null}
                                                                            {itemhistory.CreateClient ? t("workplace.menuitems.client.createclient") : null}
                                                                        </div>
                                                                        : null
                                                                }
                                                                {
                                                                    itemhistory.Transaction
                                                                        ? <div className="ClientHistoryText">
                                                                            {itemhistory.Title && itemhistory.Create && !itemhistory.Remove ? t("workplace.menuitems.transaction.TitleAdd") : null}
                                                                            {itemhistory.Title && !itemhistory.Create && !itemhistory.Remove ? t("workplace.menuitems.transaction.TitleChange") : null}
                                                                            {itemhistory.Title && !itemhistory.Create && itemhistory.Remove ? t("workplace.menuitems.transaction.TitleRemove") : null}
                                                                            {itemhistory.Description && itemhistory.Create && !itemhistory.Remove ? t("workplace.menuitems.transaction.DescriptionAdd") : null}
                                                                            {itemhistory.Description && !itemhistory.Create && !itemhistory.Remove ? t("workplace.menuitems.transaction.DescriptionChange") : null}
                                                                            {itemhistory.Description && !itemhistory.Create && itemhistory.Remove ? t("workplace.menuitems.transaction.DescriptionRemove") : null}
                                                                            {itemhistory.Budget && itemhistory.Create && !itemhistory.Remove ? t("workplace.menuitems.transaction.BudgetAdd") : null}
                                                                            {itemhistory.Budget && !itemhistory.Create && !itemhistory.Remove ? t("workplace.menuitems.transaction.BudgetChange") : null}
                                                                            {itemhistory.Budget && !itemhistory.Create && itemhistory.Remove ? t("workplace.menuitems.transaction.BudgetRemove") : null}
                                                                            {itemhistory.Board && !itemhistory.Create && !itemhistory.Remove ? t("workplace.menuitems.transaction.StageChange") : null}
                                                                        </div>
                                                                        : null
                                                                }
                                                                {
                                                                    itemhistory.Task
                                                                        ? <div className="ClientHistoryText">
                                                                            {itemhistory.CreateTask ? <span>{t("workplace.menuitems.tasks.createtask")} <span className="clientHistoryBoldText">{itemhistory.TaskTitle}</span></span> : null}
                                                                            {itemhistory.CompleteTask ? <span><span className="clientHistoryBoldText">{itemhistory.TaskTitle}</span> {t("workplace.menuitems.tasks.completetask")} <span className="clientHistoryBoldText">{itemhistory.ResultTask}</span></span> : null}
                                                                        </div>
                                                                        : null
                                                                }
                                                                {
                                                                    (itemhistory.OldValue !== "" || itemhistory.NewValue !== "") && !itemhistory.Remove
                                                                        ? <div className="ClientHistoryText">
                                                                            {
                                                                                <div className="clientHistoryTextChangValueBlock">
                                                                                    <div>{itemhistory.OldValue}</div>
                                                                                    {itemhistory.OldValue && itemhistory.NewValue ? <AiOutlineRight className="clientHistoryTextChangValueArrow" /> : null}
                                                                                    <div>{itemhistory.NewValue}</div>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                        : null
                                                                }
                                                                {
                                                                    itemhistory.TransactionsId
                                                                        ? <NavLink to={"/workplace/transactions/transaction/" + itemhistory.TransactionsId} className="textHistoryLink">
                                                                            {itemhistory.Transactions.Title}
                                                                        </NavLink>
                                                                        : null
                                                                }
                                                            </div>
                                                            {/* <div className="ClientHistoryNote">{itemtransaction.Note}</div> */}
                                                        </div>
                                                    </div>
                                                )
                                                : null
                                        }
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}