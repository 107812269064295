import { React, useState, useEffect } from 'react'
import "./goodsservices.css"
import Plus from "../../../img/workplace/goodsservices/Plus.svg"
import Folder from "../../../img/workplace/goodsservices/Folder.svg"
import axios from 'axios'
import { HiOutlinePlus } from 'react-icons/hi'
import { useHistory } from 'react-router-dom'

export default function GoodsServices() {

    const history = useHistory();

    const [categories, setCategories] = useState([])

    useEffect(() => axios.get("/goodsservices").then(response => response.data).then(data => setCategories(data.Categories)), [])

    const AddCategory = (value, title) => {
        axios.get("/addgoodsservicescategory/" + title + '/' + value).then(response => setCategories([...categories, { Title: "Новый раздел", CategoryId: response.data.CategoryId }]))
    }

    const ChangeCategory = (value, title) => {
        if (categories.find(x => x.CategoryId === value).Title === title) return;
        axios.put("/changegoodsservicescategory", { CategoryId: value, Title: title })
    }

    const Categories = () => {
        return <div id='GoodsServicesCategoriesBlock'>
            {
                categories.map((item, index) => {
                    return <div key={"GoodsServicesCategory_" + index} className="goodsServicesCategoryBlock">
                        <div>
                            <img className="goodsServicesCategoryItem" src={Folder} alt="" />
                        </div>
                        <div className="goodsServicesCategoryItemTitle" contentEditable={true} onBlur={(e) => ChangeCategory(item.CategoryId, e.currentTarget.textContent)} suppressContentEditableWarning={true}>{item.Title}</div>
                    </div>
                }
                )
            }
            <div id='GoodsServicesPlusBlock' onClick={() => AddCategory(0, "Новый раздел")}>
                <div>
                    <img id="GoodsServicesPlusIcon" src={Plus} alt='' />
                </div>
                <span>Добавить<br />раздел</span>
            </div>
        </div>
    }
    const GoodsServicesAddButtonFilter = () => {
        return <div id='GoodsServicesAddButtonFilterButtonsBlock'>
            <div id='GoodsServicesAddButton' onClick={() => history.push('/workplace/goodsservices/addgoodsservices')}>Добавить позицию <HiOutlinePlus id='GoodsServicesAddButtonPlusIcon' /></div>
            <div id='GoodsServicesFilterButtonsBlock'>
                <div className='goodsServicesFilterButton'>Все</div>
                <div className='goodsServicesFilterButton'>Товары</div>
                <div className='goodsServicesFilterButton'>Услуги</div>
            </div>
        </div>
    }
    // const GoodsServicesItems = () => {

    // }

    const Pagination = () => {
        return <div id='GoodsServicesPaginationBlock'>
            <div id='GoodsServicesPaginationCount'>321</div>
            <div>123</div>
        </div>
    }

    return (
        <div id='goodsservices'>
            <Categories />
            <GoodsServicesAddButtonFilter />
            {/* <GoodsServicesItems /> */}
            <div style={{ flexGrow: 1 }}></div>
            <Pagination />
        </div>
    )
}
