import React from 'react'
import {AiOutlineDelete} from 'react-icons/ai'
import { useTranslation } from 'react-i18next'

export default function Addworksitemsprofessionspart({item, index, deleteprofessionitem}) {

    const { t } = useTranslation();

    return (
        <div className="AddWorksItemsProfessionsPartBlock">
            <div className="AddWorksItemsProfessionsPartTitle">{item.Title}</div>
            <div className="AddWorksItemsProfessionsPartCount">{item.Count} {t("workplace.menuitems.works.itemshortpeople")}</div>
            <AiOutlineDelete className="AddWorksItemsProfessionsPartDeleteIcon" onClick={()=>deleteprofessionitem(index)}/>
        </div>
    )
}
