import { React, useState, useEffect, useRef } from 'react'
import "./usersContractors.css"
import { NavLink, useHistory } from 'react-router-dom'
import axios from 'axios';
import { useTranslation } from 'react-i18next'
import { MdDeleteOutline } from 'react-icons/md'
import { MdBlock } from 'react-icons/md'
import { BiEdit } from 'react-icons/bi'
import { CgUnblock } from 'react-icons/cg'
import { ImBlocked } from 'react-icons/im'

export default function UsersContractors() {

    const history = useHistory();
    const blockNoteRef = useRef();
    const [users, setUsers] = useState([]);
    const { t } = useTranslation();
    const [currentUser, setCurrentUser] = useState();
    const [modal, setModal] = useState(false);
    const [block, setBlock] = useState(false);
    const [blockStatus, setBlockStatus] = useState(false);
    const [blockNote, setBlockNote] = useState("");
    const [blockNoteValid, setBlockNoteValid] = useState(false);
    const [remove, setRemove] = useState(false);

    const blockdeleteUser = () => {
        if (block) {
            if (blockNote.trim().length === 0) { setBlockNoteValid(true); setBlockNote(""); blockNoteRef.current.focus(); return; }
            users[users.findIndex(x => x.User.Id === currentUser.User.Id)].User.Block = blockStatus;
            setUsers([...users]);
        }
        if (remove) {
            users.splice(users.findIndex(x => x.User.Id === currentUser.User.Id), 1);
            setUsers([...users]);
        }
        axios.post("/blockdeleteuser", { UserId: currentUser.User.Id, Block: block, BlockStatus: blockStatus, BlockNote: blockNote.trim(), Remove: remove });
        closemodal();
    }
    const unBlockUser = (user) => {
        axios.post("/blockdeleteuser", { UserId: user.User.Id, Block: true, BlockStatus: false });
        users[users.findIndex(x => x.User.Id === user.User.Id)].User.Block = false;
        setUsers([...users]);
    }

    const closemodal = () => {
        setModal(false);
        setBlock(false);
        setBlockNote("");
        setBlockStatus(false);
        setRemove(false);
        setBlockNoteValid(false);
    }

    useEffect(() => {
        axios.get("/userscontractors").then(response => response.data).then(data => setUsers(data));
    }, [])

    return (
        <div id="usersContractors">

            {/* Модальное окно */}
            <div id="UsersContractorsModal" className={modal ? "Open" : null}>
                <div id="UsersContractorsModalContent">
                    <div id="UsersContractorsModalHeader">
                        <div>
                            {
                                block
                                    ? t("workplace.menuitems.users.blockuser")
                                    : null
                            }
                            {
                                remove
                                    ? t("workplace.menuitems.users.deleteuser")
                                    : null
                            }
                        </div>
                    </div>

                    {
                        block || remove
                            ? <div>
                                <div id="UsersContractorsModalText">
                                    {
                                        block
                                            ? <span>{t("workplace.menuitems.users.confirmblockuser")} "{currentUser.Peoples.FullName}" ?</span>
                                            : null
                                    }
                                    {
                                        remove
                                            ? <span>{t("workplace.menuitems.users.confirmdeleteuser")} "{currentUser.Peoples.FullName}" ?</span>
                                            : null
                                    }
                                </div>
                                {
                                    block
                                        ?
                                        <div id="UsersContractorsModalBlockNoteBlock">
                                            <div>{t("workplace.menuitems.users.blocknote")}</div>
                                            <textarea ref={blockNoteRef} value={blockNote} onChange={e => { setBlockNote(e.target.value); setBlockNoteValid(false); }} id="UsersContractorsModalBlockNote"></textarea>
                                            <div id="UsersContractorsModalBlockNoteValid" className={blockNoteValid ? "Open" : null}>{t("workplace.menuitems.users.blocknotevalid")}</div>
                                        </div>
                                        : null
                                }
                                <div id="UsersContractorsModalButtonsBlock">
                                    <div className="usersContractorsModalButton" id="UsersContractorsModalButtonYes" onClick={() => blockdeleteUser()}>{t("buttons.yes")}</div>
                                    <div className="usersContractorsModalButton" id="UsersContractorsModalButtonNo" onClick={() => closemodal()}>{t("buttons.no")}</div>
                                </div>
                            </div>
                            : null
                    }
                </div>
            </div>

            <div id="usersContractorsItemHeader">
                <NavLink to="/workplace/settingscontractors/userscontractors/add" id="">
                    <span className="AddButton" >{t("workplace.menuitems.users.adduserbutton")}</span>
                </NavLink>
                {
                    users.length === 0
                        ? <div id="usersContractorsItemHeaderNotUsers">{t("workplace.menuitems.users.usersnotfind")}</div>
                        : <div id="usersContractorsItemHeaderColumns">
                            <div className="usersContractorsItemHeaderColumnsItem">{t("workplace.menuitems.users.nameuser")}</div>
                            <div className="usersContractorsItemHeaderColumnsItem">{t("workplace.menuitems.users.postuser")}</div>
                            <div className="usersContractorsItemHeaderColumnsItem">{t("workplace.menuitems.users.phoneuser")}</div>
                            <div className="usersContractorsItemHeaderColumnsItem">E-mail</div>
                            <div className="usersContractorsItemHeaderColumnsItem">{t("workplace.menuitems.users.roleuser")}</div>
                        </div>
                }
            </div>
            {
                users.map((user, index) =>
                    <div key={"Users_" + user.Id} className="usersContractorsItem">
                        <div className="usersContractorsItemButtonsBlock">
                            <BiEdit className="usersContractorsItemButton" onClick={() => { history.push("/workplace/settingscontractors/userscontractors/edit/" + user.Id); }} />
                            {
                                index !== 0
                                    ? <div>
                                        {
                                            user.User.Block
                                                ? <CgUnblock className="usersContractorsItemButton" onClick={() => unBlockUser(user)} />
                                                : <MdBlock className="usersContractorsItemButton" onClick={() => { setCurrentUser(user); setBlock(true); setBlockStatus(true); setModal(true); }} />
                                        }
                                        <MdDeleteOutline className="usersContractorsItemButton" onClick={() => { setCurrentUser(user); setRemove(true); setModal(true); }} />
                                    </div>
                                    : null
                            }
                        </div>
                        <div className="usersContractorsItemDataRow">
                            <div className="usersContractorsItemColumnItem">
                                {
                                    user.User.Block
                                        ? <ImBlocked className="usersContractorsItemColumnItemBlockIcon" />
                                        : null
                                }
                                {user.Peoples.FullName ? user.Peoples.FullName : "-"}
                                {index === 0 ? t("workplace.menuitems.users.you") : null}
                            </div>
                            <div className="usersContractorsItemColumnItem">{user.Peoples.Post ? user.Peoples.Post : "-"}</div>
                            <div className="usersContractorsItemColumnItem">{user.Peoples.Phone ? user.Peoples.Phone : "-"}</div>
                            <div className="usersContractorsItemColumnItem">{user.Peoples.Email ? user.Peoples.Email : "-"}</div>
                            <div className="usersContractorsItemColumnItem">{user.TypeUsers.Title ? t("workplace.menuitems.users." + user.TypeUsers.IdentityRoleName) : "-"}</div>
                        </div>
                    </div>
                )
            }
        </div>
    )
}
