import { React } from "react"
import "./App.css"
import "react-i18next"
import "./i18n.js"
import Mainsite from "./mainsite/mainsite"
import axios from 'axios'
import Cookies from 'universal-cookie'
import { Route } from "react-router-dom"
import Workplace from "./workplace/workplace"
import PrivateRoute from "./PrivateRoute"

function App() {

  const cookies = new Cookies();

  if (cookies.get("url") === undefined)
    fetch("/customData.json").then(response => response.json()).then(resp => {
      cookies.set('url', resp.url, { path: '/', expires: new Date(Date.now() + 315360000000) })    
    });

  axios.interceptors.request.use(function (config) {
    config.baseURL = cookies.get(['url']);
    config.headers.Authorization = 'Bearer ' + cookies.get(['token']);
    return config;
  })

  return (
    <div>
      <Route exact path="/" component={Mainsite} />
      <PrivateRoute exact path="/workplace" component={Workplace} />
      <PrivateRoute exact path="/workplace/*" component={Workplace} />
    </div>
  );
}
export default App;