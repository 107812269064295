import { React, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import axios from 'axios';

export default function Addcontractordata(
    {
        data,
        name, setName,
        nameValid, setNameValid,
        phone, setPhone,
        phoneValid, setPhoneValid,
        email, setEmail,
        emailValid, setEmailValid,
        country, setCountry,
        countryValid, setCountryValid,
        language, setLanguage,
        languageValid, setLanguageValid,
        currency, setCurrency,
        currencyValid, setCurrencyValid,
        address, setAddress,
        addressValid, setAddressValid,
        access, setAccess,
        namePeople, setNamePeople,
        namePeopleValid, setNamePeopleValid,
        login, setLogin,
        loginValid, setLoginValid,
        password, setPassword,
        passwordValid, setPasswordValid,
        passwordConfirm, setPasswordConfirm,
        passwordConfirmValid, setPasswordConfirmValid,
        checkFreeLogin, setCheckFreeLogin,
        openrequisites, setDataFormValid
    }
) {

    const { t } = useTranslation();

    useEffect(() => { 
        setDataFormValid(false); 
    }, [name, phone, email, country, language, currency, address, access, namePeople, login, password, passwordConfirm, setDataFormValid])

    function CheckFreeLogin(value) {
        if (value.length > 2)
            axios.get("/checkfreelogin?login=" + value).then(response => response.data).then(data => setCheckFreeLogin(data));
    }

    return (
        <div className={"addcontractorform"+(openrequisites ? '' : ' Active')}>
            <div className="addcontractorforminputblock">
                <input autoComplete="new-password" placeholder=" " id="addcontractorformNC" className="addcontractorforminput" onChange={e => { setName(e.target.value.trim()); setNameValid(false); }} />
                <label htmlFor="addcontractorformNC" className="addcontractorformlabel">{t("workplace.menuitems.contractors.titlecontractor")}</label>
                <div className={nameValid ? "addcontractorforminputvalid Invalid" : "addcontractorforminputvalid"}>{t("workplace.menuitems.contractors.validtitlecontractor")}</div>
            </div>
            <div className="addcontractorforminputgroupblock">
                <div className="addcontractorforminputblock">
                    <input autoComplete="new-password" placeholder=" " id="addcontractorformP" className="addcontractorforminput" onChange={e => { setPhone(e.target.value.trim()); setPhoneValid(false); }} />
                    <label htmlFor="addcontractorformP" className="addcontractorformlabel">{t("workplace.menuitems.contractors.phone")}</label>
                    <div className={phoneValid ? "addcontractorforminputvalid Invalid" : "addcontractorforminputvalid"}>{t("workplace.menuitems.contractors.validphone")}</div>
                </div>
                <div className="addcontractorforminputblock">
                    <input autoComplete="new-password" placeholder=" " id="addcontractorformE" className="addcontractorforminput" onChange={e => { setEmail(e.target.value.trim()); setEmailValid(false); }} />
                    <label htmlFor="addcontractorformE" className="addcontractorformlabel">e-mail</label>
                    <div className={emailValid ? "addcontractorforminputvalid Invalid" : "addcontractorforminputvalid"}>{t("workplace.menuitems.contractors.validemail")}</div>
                </div>
            </div>

            <div id="addcontractorformgroupselect">
                <div className="addcontractorforminputblock addcontractorformselect">
                    <select placeholder=" " id="addcontractorformCO" className="addcontractorforminput select" onChange={(e) => { setCountry(e.target.value); setCountryValid(false); }}>
                        <option hidden></option>
                        {
                            data.Countries
                                ? data.Countries.map(item => {
                                    return (<option key={"co" + item.Id} value={item.Id}>{item.FullName.TextPhrase}</option>)
                                })
                                : ""
                        }
                    </select>
                    <label htmlFor="addcontractorformCO" className={country > 0 ? "addcontractorformlabelselect Valid" : "addcontractorformlabelselect"}>{t("workplace.menuitems.contractors.country")}</label>
                    <div className={countryValid ? "addcontractorforminputvalid Invalid" : "addcontractorforminputvalid"}>{t("workplace.menuitems.contractors.validcountry")}</div>
                </div>
                <div className="addcontractorforminputblock addcontractorformselect">
                    <select id="addcontractorformL" className="addcontractorforminput select" onChange={(e) => { setLanguage(e.target.value); setLanguageValid(false); }}>
                        <option hidden></option>
                        {
                            data.Languages
                                ? data.Languages.map(item => {
                                    return (<option key={"l" + item.Id} value={item.Id}>{item.FullName.TextPhrase}</option>)
                                })
                                : ""
                        }
                    </select>
                    <label htmlFor="addcontractorformL" className={language > 0 ? "addcontractorformlabelselect Valid" : "addcontractorformlabelselect"}>{t("workplace.menuitems.contractors.language")}</label>
                    <div className={languageValid ? "addcontractorforminputvalid Invalid" : "addcontractorforminputvalid"}>{t("workplace.menuitems.contractors.validlanguage")}</div>
                </div>
                <div className="addcontractorforminputblock addcontractorformselect">
                    <select id="addcontractorformCU" className="addcontractorforminput select" onChange={(e) => { setCurrency(e.target.value); setCurrencyValid(false); }}>
                        <option hidden></option>
                        {
                            data.Currency
                                ? data.Currency.map(item => {
                                    return (<option key={"cu" + item.Id} value={item.Id}>{item.FullName.TextPhrase}</option>)
                                })
                                : ""
                        }
                    </select>
                    <label htmlFor="addcontractorformCU" className={currency > 0 ? "addcontractorformlabelselect Valid" : "addcontractorformlabelselect"}>{t("workplace.menuitems.contractors.currency")}</label>
                    <div className={currencyValid ? "addcontractorforminputvalid Invalid" : "addcontractorforminputvalid"}>{t("workplace.menuitems.contractors.validcurrency")}</div>
                </div>
            </div>

            <div className="addcontractorforminputblock">
                <input autoComplete="new-password" placeholder=" " id="addcontractorformA" className="addcontractorforminput" onChange={e => { setAddress(e.target.value.trim()); setAddressValid(false); }} />
                <label htmlFor="addcontractorformA" className="addcontractorformlabel">{t("workplace.menuitems.contractors.address")}</label>
                <div className={addressValid ? "addcontractorforminputvalid Invalid" : "addcontractorforminputvalid"}>{t("workplace.menuitems.contractors.validaddress")}</div>
            </div>

            <div>
                <div className="switchcontainer">
                    <input type="checkbox" name="toggle" id="toggle-button" className="toggle-button" onChange={() => { setAccess(!access) }} />
                    <label htmlFor="toggle-button" className="switchtext">{t("workplace.menuitems.contractors.accesssystem")}</label>
                </div>
            </div>

            <div className={access ? 'addcontractorforminputgroupblock' : 'addcontractorforminputgroupblock hidden'}>
                <div className="addcontractorforminputblock">
                    <input autoComplete="new-password" placeholder=" " id="addcontractorformNameClient" className="addcontractorforminput" onChange={e => { setNamePeople(e.target.value.trim()); setNamePeopleValid(false); }} />
                    <label htmlFor="addcontractorformNameClient" className="addcontractorformlabel">{t("workplace.menuitems.contractors.namepeople")}</label>
                    <div className={namePeopleValid ? "addcontractorforminputvalid Invalid" : "addcontractorforminputvalid"}>{t("workplace.menuitems.contractors.namepeopleValid")}</div>
                </div>
                <div className="addcontractorforminputblock">
                    <input autoComplete="new-password" placeholder=" " id="addcontractorformLogin" className="addcontractorforminput" onChange={(e) => { e.target.value = e.target.value.replace(/[^A-Za-z0-9]/ig, ''); setLogin(e.target.value.trim()); setLoginValid(false); CheckFreeLogin(e.target.value); }} />
                    <label htmlFor="addcontractorformLogin" className="addcontractorformlabel">{t("workplace.menuitems.contractors.login")}</label>
                    <div className={loginValid ? "addcontractorforminputvalid Invalid" : "addcontractorforminputvalid"}>{t("workplace.menuitems.contractors.loginValid")}</div>
                    <div className={checkFreeLogin ? "addcontractorforminputvalid" : "addcontractorforminputvalid Invalid"}>{t("workplace.menuitems.contractors.loginFreeValid")}</div>
                </div>
            </div>

            <div className={access ? 'addcontractorforminputgroupblock' : 'addcontractorforminputgroupblock hidden'}>
                <div className="addcontractorforminputblock">
                    <input autoComplete="new-password" placeholder=" " id="addcontractorformPassword" type='password' className="addcontractorforminput" onChange={(e) => { e.target.value = e.target.value.replace(/[^A-Za-z0-9]/ig, ''); setPassword(e.target.value.trim()); setPasswordValid(false); }} />
                    <label htmlFor="addcontractorformPassword" className="addcontractorformlabel">{t("workplace.menuitems.contractors.password")}</label>
                    <div className={passwordValid ? "addcontractorforminputvalid Invalid" : "addcontractorforminputvalid"}>{t("workplace.menuitems.contractors.passwordValid")}</div>
                </div>
                <div className="addcontractorforminputblock">
                    <input autoComplete="new-password" placeholder=" " id="addcontractorformPasswordC" type='password' className="addcontractorforminput" onChange={e => { setPasswordConfirm(e.target.value.trim()); setPasswordConfirmValid(false); }} />
                    <label htmlFor="addcontractorformPasswordC" className="addcontractorformlabel">{t("workplace.menuitems.contractors.passwordconfirm")}</label>
                    <div className={passwordConfirmValid ? "addcontractorforminputvalid Invalid" : "addcontractorforminputvalid"}>{t("workplace.menuitems.contractors.passwordconfirmValid")}</div>
                </div>
            </div>

        </div>
    )
}
