import { React, useState, useEffect } from 'react'
import './worksitem.css'
import { IoHammerOutline } from 'react-icons/io5'
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import Addworksitems from './addworksitems/addworksitems'
import Worksitemstage from './worksitemstage'
import Worksitemprofessions from './worksitemprofessions'

export default function Worksitem({ selectWorkItem }) {

    const { t } = useTranslation();
    const [defaultLanguageId, setDefaultLanguageId] = useState(0);
    const [selectLanguageId, setSelectLanguageId] = useState(0);
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [professionsAddList, setProfessionsAddList] = useState([]);
    const [professionsWorks, setProfessionsWorks] = useState([]);
    const [addWorksItemsBlock, setAddWorksItemsBlock] = useState(false);
    const [stages, setStages] = useState([]);

    useEffect(() => {
        if (selectWorkItem == null) return;
        setProfessionsAddList(selectWorkItem.ProfessionsAdd);
        setProfessionsWorks(selectWorkItem.LinkWorksProfessionsContractors);
        setTitle(selectWorkItem.Title);
        setDescription(selectWorkItem.Description ? selectWorkItem.Description : "");
        setStages(selectWorkItem.StageWorks);
        setDefaultLanguageId(selectWorkItem.DefaultLanguageId);
        setSelectLanguageId(selectWorkItem.SelectLanguageId);
    }, [selectWorkItem, setProfessionsAddList])

    useEffect(() => {
        setAddWorksItemsBlock(false)
    }, [selectWorkItem])

    function ChangeTitle() {
        if (title.trim().length !== 0)
            axios.post("/changetitleworksitems", { WorksId: selectWorkItem.WorksId, Title: title, SelectLanguageId: selectWorkItem.SelectLanguageId })
    }
    function ChangeDescription() {
        if (title.trim().length !== 0)
            axios.post("/changedescriptionworksitems", { WorksId: selectWorkItem.WorksId, Description: description, SelectLanguageId: selectWorkItem.SelectLanguageId })
    }

    return (
        <div id="worksitem">
            {
                selectWorkItem
                    ?
                    <div id="WorksitemContent">
                        <div id="WorksitemHeader">
                            <div id="WorksitemHeaderTitle">{selectWorkItem.TypeWorks.Title}</div>
                            <div id="WorksitemHeaderLanguageBlock">
                                <div>{t("workplace.menuitems.works.itemselectlanguage")}</div>
                                {
                                    selectWorkItem.Languages.map(item =>
                                        <div onClick={() => setSelectLanguageId(item.Id)} key={item.Id} className={selectLanguageId === item.Id ? "WorksitemHeaderLanguage Active" : "WorksitemHeaderLanguage"}>{t("workplace.menuitems.works." + item.FullName.TextPhrase)}</div>
                                    )
                                }
                            </div>
                        </div>
                        {
                            addWorksItemsBlock
                                ?
                                <Addworksitems setAddWorksItemsBlock={setAddWorksItemsBlock} worksId={selectWorkItem.WorksId} stages={stages} setStages={setStages} />
                                : <div id="WorksItemBody">
                                    <div id="WorksItemBodyContent">
                                        <div className="WorksItemContentBlock">
                                            <div className="WorksItemContentTitle">{t("workplace.menuitems.works.itemtitle")}</div>
                                            <input disabled={defaultLanguageId === selectLanguageId ?null :"disabled"} placeholder={t("workplace.menuitems.works.itemnulltitle")} className="WorksItemContentText" value={title} onChange={(e) => setTitle(e.target.value)} onBlur={() => ChangeTitle()} type="text" />
                                        </div>
                                        <div className="WorksItemContentBlock">
                                            <div className="WorksItemContentTitle">{t("workplace.menuitems.works.itemdescription")}</div>
                                            <textarea disabled={defaultLanguageId === selectLanguageId ?null :"disabled"} placeholder={t("workplace.menuitems.works.itemnulldescription")} className="WorksItemContentText" value={description} onChange={(e) => setDescription(e.target.value)} onBlur={() => ChangeDescription()} type="text"></textarea>
                                        </div>
                                        <Worksitemprofessions selectLanguageId={selectLanguageId} defaultLanguageId={defaultLanguageId} setProfessionsAddList={setProfessionsAddList} setProfessionsWorks={setProfessionsWorks} selectWorkItem={selectWorkItem} professionsAddList={professionsAddList} professionsWorks={professionsWorks} />
                                        <div className="WorksItemContentBlock">
                                            <div className="WorksItemContentTitle">{t("workplace.menuitems.works.stages")}</div>
                                            {
                                                stages.length > 0
                                                    ? stages.map(item =>
                                                        <Worksitemstage item={item} />
                                                    )
                                                    : null
                                            }
                                            {
                                                selectLanguageId === defaultLanguageId
                                                    ? <div onClick={() => setAddWorksItemsBlock(true)} className="WorksItemAddButton">{t("workplace.menuitems.works.addstage")}</div>
                                                    : null
                                            }
                                        </div>
                                    </div>
                                </div>
                        }
                    </div>
                    : <div id="worksitemnull">
                        <IoHammerOutline id="worksitemnullicon" />
                        <div>{t("workplace.menuitems.works.itemselectnull")}</div>
                    </div>
            }
        </div>
    )
}
