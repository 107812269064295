import { React, useState } from 'react'
import "./login.css"
import { useTranslation } from 'react-i18next'
import { BiLogInCircle } from 'react-icons/bi'
import axios from 'axios'
import Cookies from 'universal-cookie'

export default function Login({ loginblock, setloginblock }) {

    const cookies = new Cookies();
    const { t } = useTranslation();
    const [login, setlogin] = useState("");
    const [password, setpassword] = useState("");
    const [rememberme, setrememberme] = useState(true);
    const [noAuth, setNoAuth] = useState(false);
    const [noAuthRequired, setNoAuthRequired] = useState(false);

    function gettoken() {
        if(login.trim() === "" || password.trim() === "")
        {
            setNoAuthRequired(true);
            return;
        }
        axios.post("/token", { login: login, password: password, rememberme: rememberme })
            .then(response => response.data)
            .then(data => {
                if (data.Error) {
                    setNoAuth(true)
                }
                else {
                    if (data.RememberMe)
                        cookies.set('token', data.Token, { path: '/', expires: new Date(Date.now() + 315360000000) });
                    else
                        cookies.set('token', data.Token, { path: '/' });

                    axios.get('/firstpath').then(response => window.location.href = '/workplace/' + response.data);
                }
            });
    };

    return (
        <div id="LoginBlockFade" className={loginblock ? "Open" : ""}>
            <div id="LoginBlock" className={loginblock ? "Open" : ""}>
                <div id="LoginBlockBackBlock">
                    <div id="LoginBlockBackButton" onClick={() => setloginblock(false)}>
                        <BiLogInCircle id="LoginBlockBackButtonIcon" />
                        <div>{t("mainsite.loginblock.back")}</div>
                    </div>
                </div>
                <div id="LoginBlockFormBlock">
                    <div className="LoginBlockFormTitle">{t("mainsite.loginblock.title")}</div>
                    <div className="LoginBlockFormInputBlock">
                        <label className="MSBodyThirdBlockFormLabel" htmlFor="LoginBlockFormInputLogin">{t('mainsite.loginblock.login')}</label>
                        <input id="LoginBlockFormInputLogin" onChange={(e) => { setlogin(e.target.value); setNoAuth(false); setNoAuthRequired(false); }} className="LoginBlockFormInput" type="text" />
                    </div>
                    <div className="LoginBlockFormInputBlock">
                        <label className="MSBodyThirdBlockFormLabel" htmlFor="LoginBlockFormInputPasword">{t('mainsite.loginblock.password')}</label>
                        <input id="LoginBlockFormInputPasword" onChange={(e) => { setpassword(e.target.value); setNoAuth(false); setNoAuthRequired(false); }} type="password" className="LoginBlockFormInput" />
                    </div>
                    {/* <div id="LoginBlockFormForgotYourPassword">{t('mainsite.loginblock.forgotyourpassword')}</div> */}
                    <div>
                        <input type="checkbox" id="LoginBlockFormRememberMe" onChange={() => setrememberme(!rememberme)} checked={rememberme} />
                        <label htmlFor="LoginBlockFormRememberMe" id="LoginBlockFormRememberMeTitle">{t('mainsite.loginblock.rememberme')}</label>
                    </div>
                    <button id="LoginBlockFormButtonLogin" onClick={() => gettoken()}>
                        {t("mainsite.loginblock.loginbutton")}
                    </button>
                    <div id="LoginBlockFormNoAuthBlock">
                        <div className={noAuthRequired ? "" : "hidden"} id="LoginBlockFormNoAuth">{t("mainsite.loginblock.noauthrequired")}</div>
                        <div className={noAuth ? "" : "hidden"} id="LoginBlockFormNoAuth">{t("mainsite.loginblock.noauth")}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}
