import axios from 'axios';
import { React, useState, useEffect, useRef } from 'react'
import "./professions.css"
import { RiArrowGoBackLine } from 'react-icons/ri'
import { AiOutlineClear } from 'react-icons/ai'
import { GoCheck } from 'react-icons/go'
import { AiOutlineDelete } from 'react-icons/ai'
import { useTranslation } from 'react-i18next'

export default function Professions() {

    const {t} = useTranslation();
    const addinput = useRef();
    const [addBlock, setAddBlock] = useState(false);
    const [professions, setProfessions] = useState([]);
    const [title, setTitle] = useState("");
    const [currentProffesion, setCurrentProffesion] = useState();
    const [editMode, setEditMode] = useState(false);

    useEffect(() => {
        axios.get("/professionscontractors").then(response => response.data).then(data => setProfessions(data))
    }, [])

    const addprofession = () => {
        if (title.trim().length !== 0)
            axios.post("/addprofessionscontractors", { Title: title }).then(response => response.data)
                .then(data => {
                    setProfessions([...professions, data]);
                    setTitle("");
                    setAddBlock(false);
                })
    }

    const changeprofession = () => {
        if (title.trim().length !== 0)
            axios.post("/changeprofessionscontractors", { Title: title, ProfessionsContractorId: currentProffesion.Id })
                .then(() => {
                    var index = professions.findIndex(x => x.Id === currentProffesion.Id);
                    let g = professions[index];
                    g["Title"] = title;
                    setProfessions([...professions.slice(0, index), g, ...professions.slice(index + 1)]);
                    setTitle("");
                    setEditMode(false);
                })
    }

    const removeprofession = (id) => {
            axios.post("/removeprofessionscontractors", { ProfessionsContractorId: id }).then(response=>response.data)
            .then(data=>setProfessions(data))
    }

    return (
        <div id="professions">
            <div id="ProfessionsAddBLock">
                {
                    addBlock
                        ? <div id="ProfessionsAddInputBlock">
                            <input ref={addinput} autoFocus placeholder={t("workplace.menuitems.professions.addprofessionstitleplaceholder")} id="ProfessionsAddInput" value={title} onChange={e => setTitle(e.target.value)} type="text" />
                            <GoCheck className="ProfessionsAddBlockIcons" onClick={() => addprofession()} />
                            <AiOutlineClear className="ProfessionsAddBlockIcons" onClick={() => { setTitle(""); addinput.current.focus(); }} />
                            <RiArrowGoBackLine className="ProfessionsAddBlockIcons" onClick={() => { setAddBlock(false) }} />
                        </div>
                        : <div onClick={() => { setAddBlock(true) }} id="ProfessionsAddButtonBlock">{t("workplace.menuitems.professions.addprofessionstitle")}</div>
                }
            </div>
            <div id="ProfessionsBlock">
                <div id="ProfessionsBlockContent">
                    {
                        professions.length
                            ? professions.map(item => (
                                <div className="ProfessionsItem" key={item.Id}>
                                    <div className="ProfessionsItemNumber">{item.Number})</div>
                                    {
                                        editMode && currentProffesion.Id === item.Id
                                            ? <input className="ProfessionsItemTitleInput" value={title} autoFocus onChange={e => setTitle(e.target.value)} onBlur={() => changeprofession()} />
                                            : <div className="ProfessionsItemTitle" onClick={() => { setTitle(item.Title); setEditMode(true); setCurrentProffesion(item); }}>{item.Title}</div>
                                    }
                                    {
                                        editMode
                                        ? null
                                        : <AiOutlineDelete  onClick={() => { removeprofession(item.Id); }} className="ProfessionsItemTitleRemoveIcon"/>
                                    }
                                </div>
                            ))
                            : <div>{t("workplace.menuitems.professions.nullprofessions")}</div>
                    }
                </div>
            </div>
        </div>
    )
}
