import React from 'react';
import axios from 'axios';
import { useState } from 'react';
import { RiDeleteBack2Line } from 'react-icons/ri'
import { useEffect } from 'react';

export default function CommercialProposalTableRow({ row, valuePixel, fontsize, rowsTable, setRowsTable, size }) {

  const [rowItem, setRowItem] = useState(row);

  useEffect(()=>setRowItem(row),[row])

  const changeRow = () => {
    axios.post("/changerowcp", rowItem).then(() => { const rowid = rowsTable.findIndex(x => x.CPTIId === rowItem.CPTIId); rowsTable[rowid] = rowItem; setRowsTable([...rowsTable]); })
  }

  const removeRow = () => {
    axios.post("/removerowcp/" + rowItem.CPTIId).then(() => { setRowsTable([...rowsTable.filter(x=>x.CPTIId!==rowItem.CPTIId)]) })
  }

  return (
    <div className='rowTable'>
      <RiDeleteBack2Line className='cpRemoveIconRow' style={{ left: size(-5), top:size(1) }} onClick={() => removeRow()} />
      <div style={{ width: valuePixel(10), maxWidth: valuePixel(10) }}>
        <input style={{ width: valuePixel(10), maxWidth: valuePixel(10), fontSize: fontsize }} className='pageInput' onBlur={() => changeRow()} onChange={e => setRowItem({ ...rowItem, Number: e.target.value })} value={rowItem.Number} />
      </div>
      <div style={{ width: valuePixel(68.1), maxWidth: valuePixel(68.1) }}>
        <textarea style={{ width: valuePixel(68.1), maxWidth: valuePixel(68.1), fontSize: fontsize }} className='pageInput' onBlur={() => changeRow()} onChange={e => setRowItem({ ...rowItem, Title: e.target.value })} value={rowItem.Title}></textarea>
      </div>
      <div style={{ width: valuePixel(24.2), maxWidth: valuePixel(24.2), textAlign: "end" }}>
        <input style={{ width: valuePixel(24.2), maxWidth: valuePixel(24.2), fontSize: fontsize, textAlign: "end" }} className='pageInput' onBlur={() => changeRow()} onChange={e => setRowItem({ ...rowItem, Count: e.target.value, Total: (e.target.value * rowItem.Price).toLocaleString('ru-RU') })} value={rowItem.Count} />
      </div>
      <div style={{ width: valuePixel(24.2), maxWidth: valuePixel(24.2), textAlign: "end" }}>
        <input style={{ width: valuePixel(24.2), maxWidth: valuePixel(24.2), fontSize: fontsize, textAlign: "end" }} className='pageInput' onBlur={() => changeRow()} onChange={e => setRowItem({ ...rowItem, UnitOfMeasurement: e.target.value })} value={rowItem.UnitOfMeasurement} />
      </div>
      <div style={{ width: valuePixel(24.2), maxWidth: valuePixel(24.2), textAlign: "end" }}>
        <input style={{ width: valuePixel(24.2), maxWidth: valuePixel(24.2), fontSize: fontsize, textAlign: "end" }} className='pageInput' onBlur={() => changeRow()} onChange={e => setRowItem({ ...rowItem, Price: e.target.value, Total: (rowItem.Count * e.target.value).toLocaleString('ru-RU') })} value={rowItem.Price} />
      </div>
      <div style={{ width: valuePixel(24.2), maxWidth: valuePixel(24.2), textAlign: "end" }}>
        <div>{rowItem.Total}</div>
      </div>
    </div>)
}