import {React, useEffect, useState} from 'react'
import './contractors.css'
import axios from 'axios'
import { useTranslation } from 'react-i18next'
import {NavLink, useHistory} from 'react-router-dom'

export default function Contractors() {

    const {t} = useTranslation();
    const history = useHistory();
    const [error, setError] = useState(null);
    const [items, setItems] = useState([]);

    useEffect(()=>{
      axios.get("/contractors")
    .then(
      (result) => {
        setItems(result.data.Contractors);
      },
      (error) => {
        setError(error);
      })
    },[]);
    
    return (
        <div id="contractors">   
        <NavLink to="/workplace/contractors/addcontractor" className="AddButton">{t("workplace.menuitems.contractors.addbutton")}</NavLink>
        {
          error ? <div>Ошибка загрузки данных</div> :         
          items.lenght===0
          ? <div>Контрагенты не найдены</div>
          :   
          <div id="TableBlock">
            <div id="TableContent">
            <table id="TableContractors">
            <thead>
              <tr className="tablehead">
              <th>№</th>
              <th>{t("workplace.menuitems.contractors.titlecontractor")}</th>
              <th>{t("workplace.menuitems.contractors.country")}</th>
              <th>{t("workplace.menuitems.contractors.address")}</th>
              <th>{t("workplace.menuitems.contractors.phone")}</th>
              <th>e-mail</th>
              </tr>
            </thead>
            <tbody>
            {items.map(item => (
              <tr className="tablebody" key={item.Id} onClick={()=>history.push("/workplace/contractors/custom/"+item.Id)}>
                <td>{item.Id}</td>
                <td>{item.Title}</td>
                <td>{item.Countries.FullName.TextPhrase}</td>
                <td>{item.Address}</td>
                <td>{item.Phone}</td>
                <td>{item.Email}</td>
              </tr>
            ))}
            </tbody>
          </table>
            </div>
          </div>  
          

          // <ul>
          //     {items.map(item => (<li className="ContractorsItems" key={item.Id}>{item.Title}</li>))}
          //   </ul>
          
        }      
        </div>
    )
}
