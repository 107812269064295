import { React, useState, useEffect } from 'react'
import "./contractor.css"
import axios from 'axios'
import { useParams, NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { AiOutlineRight } from 'react-icons/ai'
import RequisitesContractor from './requisitesContractor'
import { MdOutlineArrowForward } from 'react-icons/md'

export default function Contractor() {

    const { t } = useTranslation();
    const { contractorid } = useParams();
    const [contractor, setContractor] = useState();
    const [HistoryList, setHistoryList] = useState([]);

    //region Данные по контрагенту
    const [title, setTitle] = useState("");
    const [address, setAddress] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [requisites, setRequisites] = useState();
    const [requisitesBlock, setRequisitesBlock] = useState(false);

    function EditContractor() {
        axios.post("/editcontranctors", { ContractorsId: contractor.Id, Title: title, Address: address, Email: email, Phone: phone }).then(response => setHistoryList(response.data))
    }
    //endregion

    useEffect(() => {
        axios("/contractors/" + contractorid).then(response => {
            setContractor(response.data.Contractors);
            setHistoryList(response.data.HistoryList);
            setTitle(response.data.Contractors.Title);
            setEmail(response.data.Contractors.Email);
            setPhone(response.data.Contractors.Phone);
            setAddress(response.data.Contractors.Address);
            setRequisites(response.data.Requisites);
        })
    }, [contractorid])

    return (
        <div id="Contractor">

            <div id="RequisitesModalBlock" className={requisitesBlock ? "Open" : ""}>
                <RequisitesContractor setRequisitesBlock={setRequisitesBlock} contractorid={contractorid} requisites={requisites} setRequisites={setRequisites} dateInput={false} />
            </div>

            <div id="ContractorDataBlock">
                <div id="ContractorDataContentBlock">
                    <div className="contractorblock">
                        <div className="contractortitle">{t("workplace.menuitems.contractor.titlecontractor")}</div>
                        <input placeholder={t("workplace.menuitems.contractor.titleplaceholder")} value={title} onChange={e => setTitle(e.target.value)} onBlur={() => EditContractor()} className="contractorinput" />
                    </div>
                    <div className="contractorblock">
                        <div className="contractortitle">{t("workplace.menuitems.contractor.address")}</div>
                        <input placeholder={t("workplace.menuitems.contractor.addressplaceholder")} value={address} onChange={e => setAddress(e.target.value)} onBlur={() => EditContractor()} className="contractorinput" />
                    </div>
                    <div className="contractorblock">
                        <div className="contractortitle">{t("workplace.menuitems.contractor.phone")}</div>
                        <input placeholder={t("workplace.menuitems.contractor.phoneplaceholder")} value={phone} onChange={e => setPhone(e.target.value)} onBlur={() => EditContractor()} className="contractorinput" />
                    </div>
                    <div className="contractorblock">
                        <div className="contractortitle">E-mail</div>
                        <input placeholder={t("workplace.menuitems.contractor.emailplaceholder")} value={email} onChange={e => setEmail(e.target.value)} onBlur={() => EditContractor()} className="contractorinput" />
                    </div>
                </div>
                <div className="contractorblock">
                    {
                        requisites
                            ? <div id='RequisitesContractorButton' onClick={() => setRequisitesBlock(true)}>
                                <div>{t("workplace.menuitems.requisites.title")}</div>
                                <MdOutlineArrowForward />
                            </div>
                            : null
                            // <div id="RequisitesContractorAddButton" onClick={() => setRequisitesBlock(true)}>{t("workplace.menuitems.requisites.requisitesaddbutton")}</div>
                    }
                </div>
            </div>

            <div id="ContractorHistoryBlock">
                <div id="ContractorHistory">
                    <div id="ContractorHistoryContent">
                        {
                            HistoryList.map((item, index) =>
                            (
                                <div className="contractorHistoryContentItem" key={"Historycontractor_" + index}>
                                    <div className="contractorHistoryDateBlock">{item.Date}</div>
                                    {
                                        item.Items
                                            ?
                                            item.Items.map((itemhistory, index) =>
                                                <div className="contractorHistoryClassEdit" key={"History_" + index}>
                                                    <div className="contractorHistoryBorder">
                                                        <div className="contractorHistoryBorderPoint"></div>
                                                    </div>
                                                    <div className="contractorHistoryDate">{new Date(itemhistory.InsertedDate).toLocaleString()}</div>
                                                    <div>
                                                        <div className="contractorHistoryBlock">
                                                            <div className="contractorHistoryPeople">
                                                                {itemhistory.InsertedLUP.Peoples.FullName}
                                                            </div>
                                                            {
                                                                itemhistory
                                                                    ? <div className="contractorHistoryText">
                                                                        {itemhistory.Title && itemhistory.Create && !itemhistory.Remove ? t("workplace.menuitems.contractor.titleadd") : null}
                                                                        {itemhistory.Title && !itemhistory.Create && !itemhistory.Remove ? t("workplace.menuitems.contractor.titlechange") : null}
                                                                        {itemhistory.Title && !itemhistory.Create && itemhistory.Remove ? t("workplace.menuitems.contractor.titleremove") : null}
                                                                        {itemhistory.Address && itemhistory.Create && !itemhistory.Remove ? t("workplace.menuitems.contractor.addressadd") : null}
                                                                        {itemhistory.Address && !itemhistory.Create && !itemhistory.Remove ? t("workplace.menuitems.contractor.addresschange") : null}
                                                                        {itemhistory.Address && !itemhistory.Create && itemhistory.Remove ? t("workplace.menuitems.contractor.addressremove") : null}
                                                                        {itemhistory.Phone && itemhistory.Create && !itemhistory.Remove ? t("workplace.menuitems.contractor.phoneadd") : null}
                                                                        {itemhistory.Phone && !itemhistory.Create && !itemhistory.Remove ? t("workplace.menuitems.contractor.phonechange") : null}
                                                                        {itemhistory.Phone && itemhistory.Remove ? t("workplace.menuitems.contractor.phoneremove") : null}
                                                                        {itemhistory.Email && itemhistory.Create && !itemhistory.Remove ? t("workplace.menuitems.contractor.emailadd") : null}
                                                                        {itemhistory.Email && !itemhistory.Create && !itemhistory.Remove ? t("workplace.menuitems.contractor.emailchange") : null}
                                                                        {itemhistory.Email && itemhistory.Remove ? t("workplace.menuitems.contractor.emailremove") : null}
                                                                        {itemhistory.CreateContractors ? t("workplace.menuitems.contractor.createcontractor") : null}
                                                                    </div>
                                                                    : null
                                                            }
                                                            {
                                                                (itemhistory.OldValue !== "" || itemhistory.NewValue !== "") && !itemhistory.Remove
                                                                    ? <div className="contractorHistoryText">
                                                                        {
                                                                            <div className="contractorHistoryTextChangValueBlock">
                                                                                <div>{itemhistory.OldValue}</div>
                                                                                {itemhistory.OldValue && itemhistory.NewValue ? <AiOutlineRight className="contractorHistoryTextChangValueArrow" /> : null}
                                                                                <div>{itemhistory.NewValue}</div>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                    : null
                                                            }
                                                            {
                                                                itemhistory.TransactionsId
                                                                    ? <NavLink to={"/workplace/transactions/transaction/" + itemhistory.TransactionsId} className="textHistoryLink">
                                                                        {itemhistory.Transactions.Title}
                                                                    </NavLink>
                                                                    : null
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                            : null
                                    }
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
