import { React, useEffect, useState } from 'react'
import './menu.css'
import { useTranslation } from 'react-i18next'
import "../../App.css"
// import logo from "../../img/icons/mainsite/logo.svg"
import clientson from "../../img/workplace/icons/clientson.svg"
import clientsoff from "../../img/workplace/icons/clientsoff.svg"
import workson from "../../img/workplace/icons/workson.svg"
import worksoff from "../../img/workplace/icons/worksoff.svg"
import transactionon from "../../img/workplace/icons/transactionon.svg"
import transactionoff from "../../img/workplace/icons/transactionoff.svg"
import localizationon from "../../img/workplace/icons/localizationon.svg"
import localizationoff from "../../img/workplace/icons/localizationoff.svg"
import contractorsiconon from "../../img/workplace/icons/contractorson.svg"
import contractorsiconoff from "../../img/workplace/icons/contractorsoff.svg"
import settingsContractorson from "../../img/workplace/icons/settingsContractorson.svg"
import settingsContractorsoff from "../../img/workplace/icons/settingsContractorsoff.svg"
import taskson from "../../img/workplace/icons/taskson.svg"
import tasksoff from "../../img/workplace/icons/tasksoff.svg"
import goodsserviceson from "../../img/workplace/icons/goodsserviceson.svg"
import goodsservicesoff from "../../img/workplace/icons/goodsservicesoff.svg"
import { NavLink } from 'react-router-dom'
import axios from 'axios'

export default function Menu() {

    const { t } = useTranslation();
    const [items, setItems] = useState();
    useEffect(() => {
        axios.get("/menuitems").then(response => response.data)
            .then(data => setItems(data))
    }, []);

    let images = {};
    images["clientson"] = clientson;
    images["clientsoff"] = clientsoff;
    images["workson"] = workson;
    images["worksoff"] = worksoff;
    images["transactionon"] = transactionon;
    images["transactionoff"] = transactionoff;
    images["localizationon"] = localizationon;
    images["localizationoff"] = localizationoff;
    images["contractorson"] = contractorsiconon;
    images["contractorsoff"] = contractorsiconoff;
    images["settingsContractorson"] = settingsContractorson;
    images["settingsContractorsoff"] = settingsContractorsoff;
    images["taskson"] = taskson;
    images["tasksoff"] = tasksoff;
    images["goodsserviceson"] = goodsserviceson;
    images["goodsservicesoff"] = goodsservicesoff;

    return (
        <div id="menu">

            <div id="MenuLogoBlock" onClick={() => window.location.href = "/"}>
                {/* <img id="MenuLogo" src={logo} alt="" /> */}
                <div id='MenuLogoWhiteText'>CRM</div>
                <div id='MenuLogoBlackTextBlock'>
                    <div id='MenuLogoColorBlock'>
                        <div className='menuLogoColor' id='MenuLogoColorBlack'></div>
                        <div className='menuLogoColor' id='MenuLogoColorRed'></div>
                        <div className='menuLogoColor' id='MenuLogoColorYellow'></div>
                    </div>
                    <div id='MenuLogoBlackText'>DE</div>
                </div>
            </div>

            {
                items
                    ? items.map(item => (
                        <NavLink key={item.Id} to={"/workplace/" + item.Phrases.TextPhrase} className="MenuItem">
                            <div className="MenuItemIconBlock">
                                <img src={images[t('workplace.menu.' + item.IconPath) + "on"]} alt="" className="MenuItemIcon On" />
                                <img src={images[t('workplace.menu.' + item.IconPath) + "off"]} alt="" className="MenuItemIcon Off" />
                            </div>
                            <div>{t("workplace.menu." + item.Phrases.TextPhrase)}</div>
                        </NavLink>
                    ))
                    : null
            }

        </div>
    )
}
