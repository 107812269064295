import React from 'react'
import { useTranslation } from 'react-i18next'
import call from "../../img/icons/mainsite/сalling.png"
import location from "../../img/icons/mainsite/location.png"
import message from "../../img/icons/mainsite/message.png"
import "./footer.css"
import "../../../src/App.css"

export default function Footer() {
    const {t} = useTranslation();

    return (
        <div>
        <div id="MSfooter" className="CRMcontainer">

            <div className="MSfooteritem">
                <div className="MSfooterblockitem">
                    <div className="MSfootericon">
                        <img src={call} alt=""></img>
                    </div>
                    <div className="MSfootertext">
                        <div>+ (49) 1522 - 1473472</div>
                        <div>+ (49) 1522 - 7861123</div>
                    </div>
                </div>
            </div>

            <div className="MSfooteritem">
                <div className="MSfooterblockitem">
                    <div className="MSfootericon">
                        <img src={message} alt=""></img>
                    </div>
                <div className="MSfootertext">info@crm-de.de</div>
                </div>
            </div>

            <div className="MSfooteritem">
                <div className="MSfooterblockitem">
                    <div className="MSfootericon">
                        <img src={location} alt=""></img>
                    </div>
                <div className="MSfootertext">{t("mainsite.footeradress")}</div>
                </div>
            </div>
            
        </div>
        <div id="MSfootertitle">Copyright © Zubarev-Technik UG</div>
        </div>
    )
}
