import { React, useState, useEffect } from 'react'
import { MdOutlinePhotoCamera, MdNoPhotography } from 'react-icons/md'
import { useTranslation } from 'react-i18next'
//import { HiDotsVertical } from 'react-icons/hi'

export default function Worksitemstage({ item }) {

    const { t } = useTranslation();
    const [items, setItems] = useState([]);
    const [professions, setProfessions] = useState([]);

    useEffect(() => { setItems(item.Items); setProfessions(item.Professions) }, [item])

    return (
        <div className="StageBlock">
            <div className="StageHeader">
                <div className="StageTitle">{item.Description}</div>
                <div className="StageHeaderMenuButtonBlock">
                    {/* <div> */}
                        {
                            item.Fotofixierung
                                ? <MdOutlinePhotoCamera className="StageFotofixierung" />
                                : <MdNoPhotography className="StageFotofixierung No" />
                        }
                    {/* </div>
                    <HiDotsVertical className="StageHeaderMenuButton"/> */}
                </div>
            </div>
            {
                items.map((item, index) =>
                    <div key={"Stage_" + index} className="StagePhotoBlock">
                        <img className="StagePhoto" src={item.PathImage} alt="" />
                        <div className="StageTextBlock">
                            <div className="StageText">
                                {item.Description}
                            </div>
                            {
                                item.Note
                                    ? <div className="StageNote">! {item.Note}</div>
                                    : null
                            }
                        </div>
                    </div>
                )
            }
            <div className="StageProfessionsBlock">
                {
                    professions.map(profession =>
                        <div className="StageProfessionsItemBlock">
                            <div className="StageProfessionsItemTitle">{profession.ProfessionsContractors.Title}</div>
                            <div>{profession.Count} {t("workplace.menuitems.works.itemshortpeople")}</div>
                        </div>
                    )
                }
            </div>
        </div>
    )
}
