import { React, useState, useEffect, useRef } from 'react'
import "./transactions.css"
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import { NavLink } from 'react-router-dom'
import BoardTitle from './boardTitle'
import { GoPlus } from 'react-icons/go'
import { RiArrowGoBackLine } from 'react-icons/ri'
import { FiCheck } from 'react-icons/fi'

export default function TransactionsNew() {

    const { t } = useTranslation();
    const boardnewforminput = useRef();
    const [boards, setBoards] = useState([]);
    const [transactions, setTransactions] = useState([]);
    const [currentBoard, setCurrentBoard] = useState();
    const [currentBoardIndex, setCurrentBoardIndex] = useState(0);
    const [transactionsTasksStatus, setTransactionsTasksStatus] = useState([]);

    useEffect(() => {
        var deltaTime = new Date().getTimezoneOffset() / 60;
        axios.get("/boards/" + deltaTime).then(response => { setBoards(response.data.Boards); setTransactions(response.data.Transactions); setTransactionsTasksStatus(response.data.TransactionsTasksStatus); })
    }, [])
    useEffect(() => (axios.get("/colors").then(response => response.data).then(data => setColorList(data))), [])

    //#region Сортировки
    const sortBoard = (a, b) => {
        if (a.Boards.Number > b.Boards.Number) return 1;
        else return -1;
    }
    const sortTransaction = (a, b) => {
        if (a.Number > b.Number) return 1;
        else return -1;
    }
    //#endregion

    //#region Добавление доски
    const [addBoard, setAddBoard] = useState(false);
    const [titleNewBoard, setTitleNewBoard] = useState("");
    const [selectColor, setSelectColor] = useState(1);
    const [colorList, setColorList] = useState([]);
    function AddBoard() {
        if (titleNewBoard === "" || titleNewBoard === null) { boardnewforminput.current.focus(); return; }
        axios.post("/addboard", { Title: titleNewBoard, Color: selectColor }).then(response => { boards.push(response.data); setBoards([...boards]); setAddBoard(false); setTitleNewBoard(""); setSelectColor(1); });
    }
    //#endregion

    //#region Перенос досок
    const [moveBoardId, setMoveBoardId] = useState(0);
    const [dragBoard, setDragBoard] = useState(true);
    function dragEnterBoard(e, board, index) {
        e.preventDefault();
        if (currentBoardIndex === index) return;
        setMoveBoardId(board.Boards.Id);
        if (currentBoard.Boards.Id === board.Boards.Id) return;
        const boardstart = currentBoard.Boards.Number;
        const boardend = board.Boards.Number;
        setBoards(
            boards.map(b => {
                if (b.Boards.Number > boardstart && b.Boards.Number <= boardend) {
                    b.Boards.Number = b.Boards.Number - 1;
                }
                if (b.Boards.Number < boardstart && b.Boards.Number >= boardend) {
                    b.Boards.Number = b.Boards.Number + 1;
                }
                if (b.Boards.Id === currentBoard.Boards.Id)
                    b.Boards.Number = boardend;
                return b;
            }));
        setCurrentBoardIndex(index);
    }
    function dropBoard(e) {
        e.preventDefault();
        if (moveBoardId === 0 || moveBoardId === currentBoard.Boards.Id || dragTransaction) return;
        axios.post("/boardsmove", { BoardStartId: currentBoard.Boards.Id, BoardEndId: moveBoardId });
    }
    //#endregion

    //#region Перенос сделок
    const [dragTransaction, setDragTransaction] = useState(true);
    const [currentTransaction, setCurrentTransaction] = useState();
    const [moveTransactionEnd, setMoveTransactionEnd] = useState();
    function dragEnterTransaction(e, transaction, board, indexboard) {
        e.preventDefault();
        if ((transaction !== null && currentTransaction.Id === transaction.Id) || (transaction === null && currentBoardIndex === indexboard)) return;

        if (transaction !== null) {
            setMoveTransactionEnd(transaction);
            if (currentBoardIndex !== indexboard) {
                transactions[transactions.findIndex(x => x.Id === currentTransaction.Id)].BoardsId = board.Boards.Id;
                transactions[transactions.findIndex(x => x.Id === currentTransaction.Id)].BoardNumber = board.Boards.Number;
                setCurrentBoardIndex(indexboard);
                transactions[transactions.findIndex(x => x.Id === currentTransaction.Id)].Number = transaction.Number;
                transactions.filter(x => x.BoardsId === board.Boards.Id).map(item => {
                    if (item.Number >= transaction.Number && item.Id !== currentTransaction.Id) item.Number++;
                    return item;
                });
            }
            else {
                var number = transaction.Number;
                var order = 1
                transactions.filter(x => x.BoardsId === board.Boards.Id && x.Id !== currentTransaction.Id).map(item => {
                    if (order === number) order++;
                    item.Number = order;
                    order++;
                    return item;
                });
                transactions[transactions.findIndex(x => x.Id === currentTransaction.Id)].Number = number;
            }
            setTransactions([...transactions]);
        }
        else if (transactions.filter(x => x.BoardsId === board.Boards.Id).length === 0) {
            transactions[transactions.findIndex(x => x.Id === currentTransaction.Id)].BoardsId = board.Boards.Id;
            transactions[transactions.findIndex(x => x.Id === currentTransaction.Id)].BoardNumber = board.Boards.Number;
            transactions[transactions.findIndex(x => x.Id === currentTransaction.Id)].Number = transactions.filter(x => x.BoardsId === board.Boards.Id).length + 1;
            setCurrentBoardIndex(indexboard);
            setTransactions([...transactions]);
        }
    }
    function dropTransaction(board, indexboard) {
        if (moveTransactionEnd !== undefined && currentTransaction.Id === moveTransactionEnd.Id) return;
        var transactionEndId = moveTransactionEnd !== undefined && moveTransactionEnd.BoardsId === board.Boards.Id ? moveTransactionEnd.Id : 0;
        if (currentTransaction !== undefined) axios.post("/transactionsmove", { TransactionId: currentTransaction.Id, BoardEndId: board.Boards.Id, TransactionEndId: transactionEndId })
            .then(
                response => {
                    response.data.forEach(item => {
                        boards[boards.findIndex(x => x.Boards.Id === item.Boards.Id)].Budget = item.Budget;
                    });
                    setBoards([...boards]);
                });
        setCurrentBoard();
        boards.forEach(board => {
            var number = 1;
            transactions.sort(sortTransaction).filter(x => x.BoardsId === board.Boards.Id).map(transaction => {
                transaction.Number = number;
                number++;
                return transaction;
            });
        });
        setTransactions([...transactions]);
    }
    //#endregion

    useEffect(() => {
        if (addBoard) boardnewforminput.current.focus();
    }, [addBoard])

    return (
        <div id="Transactions">
            <div id="TransactionsContent">
                {/* Доски */}
                {
                    boards.sort(sortBoard).map((item, index) => (
                        <div key={"Board_" + item.Boards.Id} className="boardBlock">
                            <div
                                draggable={dragBoard}
                                onDragEnter={e => dragBoard ? dragEnterBoard(e, item, index) : dragEnterTransaction(e, null, item, index)}
                                onDragStart={() => { setCurrentBoard(item); setDragTransaction(false); setCurrentBoard(item); setCurrentBoardIndex(index); }}
                                onDragOver={e => { e.preventDefault(); }}
                                onDrop={e => { dropBoard(e); dropTransaction(item, index); setDragTransaction(true); setDragBoard(true); }}
                                style={{ background: "#" + item.Boards.Colors.BackColor, color: "#" + item.Boards.Colors.Color }}
                                className="board">

                                {/* Заголовок */}
                                <BoardTitle board={item} boards={boards} setBoards={setBoards} currentBoard={currentBoard} setCurrentBoard={setCurrentBoard} setAddBoard={setAddBoard} setDragBoard={setDragBoard} />

                                {/* Сделки */}
                                <div className="boardTransactionsBlock">
                                    {transactions.filter(x => x.BoardsId === item.Boards.Id).sort(sortTransaction).map((transaction, indextransaction) =>
                                        <NavLink to={"/workplace/transactions/transaction/" + transaction.Id} key={"Transaction_" + transaction.Id}>
                                            <div className="boardTransaction"
                                                draggable={true}
                                                onDragStart={(e) => {
                                                    setCurrentTransaction(transaction);
                                                    setDragBoard(false);
                                                    setCurrentBoard(item);
                                                    setCurrentBoardIndex(index);
                                                }}
                                                onDragEnd={() => setDragBoard(true)}
                                                onDragEnter={e => dragEnterTransaction(e, transaction, item, index)}
                                                onDragOver={(e) => { e.preventDefault(); }}
                                                onDrop={e => { e.preventDefault(); }}
                                            >
                                                <div className="transactionTitle">{transaction.Title ? transaction.Title : "—"}</div>
                                                <div className="transactionline" />
                                                <div className="transactionsTitle">{t("workplace.menuitems.transaction.Client")}</div>
                                                <div className="transactionsData">{transaction.Clients ? transaction.Clients.Name : "—"}</div>
                                                <div className="transactionsTitle">{t("workplace.menuitems.transaction.Responsible")}</div>
                                                <div className="transactionsData">{transaction.Manager ? transaction.Manager.Peoples.FullName : null}</div>
                                                <div className="transactionsTitle">{t("workplace.menuitems.transactions.budget")}</div>
                                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    <div className="transactionsData">{transaction.Price ? transaction.Price : "—"}</div>
                                                    <div className=
                                                        {transactionsTasksStatus.length > 0
                                                            ? transactionsTasksStatus[transactionsTasksStatus.findIndex(x => x.TransactionId === transaction.Id)].NotTasks
                                                                ? "clientTransactionItemTaskColorBlock clientTransactionItemTaskGray"
                                                                : transactionsTasksStatus[transactionsTasksStatus.findIndex(x => x.TransactionId === transaction.Id)].Overdue
                                                                    ? "clientTransactionItemTaskColorBlock clientTransactionItemTaskRed"
                                                                    : transactionsTasksStatus[transactionsTasksStatus.findIndex(x => x.TransactionId === transaction.Id)].Today
                                                                        ? "clientTransactionItemTaskColorBlock clientTransactionItemTaskYellow"
                                                                        : "clientTransactionItemTaskColorBlock clientTransactionItemTaskGreen"
                                                            : null
                                                        }
                                                    >
                                                        {transactionsTasksStatus.length > 0
                                                            ? transactionsTasksStatus[transactionsTasksStatus.findIndex(x => x.TransactionId === transaction.Id)].NotTasks
                                                                ? t("workplace.menuitems.client.notasks")
                                                                : transactionsTasksStatus[transactionsTasksStatus.findIndex(x => x.TransactionId === transaction.Id)].Overdue
                                                                    ? t("workplace.menuitems.client.overdue")
                                                                    : transactionsTasksStatus[transactionsTasksStatus.findIndex(x => x.TransactionId === transaction.Id)].Today
                                                                        ? t("workplace.menuitems.client.today")
                                                                        : transactionsTasksStatus[transactionsTasksStatus.findIndex(x => x.TransactionId === transaction.Id)].Tomorrow
                                                                        ? t("workplace.menuitems.client.tomorrow")
                                                                        : <span>{transactionsTasksStatus[transactionsTasksStatus.findIndex(x => x.TransactionId === transaction.Id)].Days} {t("workplace.menuitems.client.days")}</span>
                                                            : null
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </NavLink>
                                    )}
                                </div>
                            </div>
                        </div>
                    ))
                }
                <div>
                    <div id="BoardNewBlock">
                        <div id="BoardNewForm" className={addBoard ? "Open" : null}>
                            <input ref={boardnewforminput} placeholder={t("workplace.menuitems.transactions.placeholderaddboard")} type="text" value={titleNewBoard} onBlur={e => setTitleNewBoard(e.target.value.trim())} onChange={e => { setTitleNewBoard(e.target.value) }} />
                            <div id="BoardNewFormColorsBlock">
                                {
                                    colorList.map(color =>
                                        <div key={color.Id} className={selectColor === color.Id ? "boardNewFormColor Select" : "boardNewFormColor"} style={{ background: '#' + color.Color }} onClick={() => setSelectColor(color.Id)}>
                                            <FiCheck />
                                        </div>)
                                }
                            </div>
                            <div id="BoardNewFormButtonsBlock">
                                <div id="BoardNewFormButtonAdd" onClick={() => AddBoard()}>{t("workplace.menuitems.transactions.buttonaddboard")}</div>
                                <RiArrowGoBackLine className="boardNewFormIcon" onClick={() => { setAddBoard(false); setTitleNewBoard(""); setSelectColor(1); }} />
                            </div>
                        </div>
                        <div id="BoardNew" className={addBoard ? "Close" : null} onClick={() => { setAddBoard(true); }}>
                            <GoPlus id="BoardNewPlus" />
                            <div>{t("workplace.menuitems.transactions.buttonaddboard")}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
