import axios from 'axios';
import { React, useState, useEffect } from 'react'
import './footerCPSettings.css'
import { useTranslation } from 'react-i18next'
import { ImCross } from 'react-icons/im'
import { FiPlus, FiMinus } from 'react-icons/fi'
import { IoIosArrowUp, IoIosArrowDown } from 'react-icons/io'

export default function FooterCPSettings() {

    const { t } = useTranslation();

    const [columns, setColumns] = useState([]);
    const [items, setItems] = useState([]);
    const [parameters, setParameters] = useState([]);
    const [currentTool, setCurrentTool] = useState();

    useEffect(() => axios.get("/settingfooter").then(response => response.data).then(data => { setColumns(data.ColumnsList); setItems(data.Items); setParameters(data.Parameters) }), [])

    const SortToolColumn = (a, b) => {
        if (a.Order > b.Order) return 1;
        else return -1;
    }

    const AddColumn = () => {
        axios.get("/settingfooteraddcolumn").then(response => response.data).then(data => setColumns([...columns, data]))
    }

    const RemoveColumn = (value) => {
        axios.get("/settingfooterremovecolumn/" + value).then(response => response.data).then(() => {
            var columnorder = columns[columns.findIndex(x => x.Id === value)].Order;
            columns.forEach(item => {
                if (item.Order > columnorder) item.Order--;
            })
            setColumns([...columns.filter(x => x.Id !== value)]);
        })
    }

    const RemoveColumnItems = (value) => {
        axios.get("/settingfooterremoveitem/" + value).then(response => response.data).then(data => {
            var itemcolumn = items.find(x => x.NameParameter === value);
            items.filter(x => x.CommercialProposalFooterColumnsId === itemcolumn.CommercialProposalFooterColumnsId && x.Order > parseInt(data)).forEach(item => {
                item.Order--;
            })
            setItems([...items.filter(x => x.NameParameter !== value)]);
        })
    }

    const AddColumnBlock = () => {
        return <div>
            <div id='FooterCPSettingsAddColumnsButton' onClick={() => AddColumn()}>{t("workplace.menuitems.commercialProposal.addcolumnfooter")}</div>
        </div>
    }

    const Columns = () => {
        return <div id='FooterCPSettingsColumnsBlock'>
            <div id='FooterCPSettingsColumnsContentBlock'>
                {
                    columns.map((item, index) => {
                        return <div key={'Columns_' + index} className='footerCPSettingsColumnBlock' onDrop={() => onDropItem()} onDragOver={(e) => e.preventDefault()} onDragEnter={() => { dragEnterTool(item); }}>
                            <div className='footerCPSettingsColumnFooterBlock'>
                                <div>{t("workplace.menuitems.commercialProposal.column")} {item.Order}</div>
                                <ImCross onClick={() => RemoveColumn(item.Id)} className='footerCPSettingsColumnFooterCrossIcon' />
                            </div>
                            <ColumnsItems value={item.Id} />
                        </div>
                    })}
                {
                    columns.length < 3
                        ? <AddColumnBlock />
                        : null
                }
            </div>
        </div>
    }

    const ColumnsItems = ({ value }) => {
        return items.filter(x => x.CommercialProposalFooterColumnsId === value).sort(SortToolColumn).map((item, index) => {
            return <div key={'ColumnItem_' + index} className="footerCPSettingsColumnItemBlock">
                <div id='FooterCPSettingsColumnItemOrderBlock'
                >
                    <div>
                        <IoIosArrowUp onClick={() => {
                            if (item.Order !== 1)
                                changeOrder(item.NameParameter, true)
                        }
                        } className={'footerCPSettingsColumnItemOrderItem' +
                            (item.Order !== 1
                                ? " Active"
                                : "")} />
                    </div>
                    <div>
                        <IoIosArrowDown onClick={() => {
                            if (item.Order !== items.filter(x => x.CommercialProposalFooterColumnsId === item.CommercialProposalFooterColumnsId).length)
                                changeOrder(item.NameParameter, false)
                        }
                        } className={'footerCPSettingsColumnItemOrderItem' +
                            (item.Order !== items.filter(x => x.CommercialProposalFooterColumnsId === item.CommercialProposalFooterColumnsId).length
                                ? " Active"
                                : "")} />
                    </div>
                </div>
                <div style={{ flexGrow: '1' }}>
                    <div className="footerCPSettingsColumnItemHeaderBlock">
                        <div>{t("workplace.menuitems.toprightangle." + item.NameParameter)}
                        </div>
                        <ImCross onClick={() => { RemoveColumnItems(item.NameParameter) }} className='footerCPSettingsColumnFooterCrossIcon' />
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div className='angletoprightrequisitescontractorsiteminputbold'>
                            {
                                t("workplace.menuitems.toprightangle." + item.NameParameter) === t("workplace.menuitems.toprightangle.Bank")
                                    ? <input type='checkbox' checked id={'Angletoprightrequisitescontractorsiteminputbold_' + item.Id} disabled />
                                    : item.Bold
                                        ? <input type='checkbox' checked id={'Angletoprightrequisitescontractorsiteminputbold_' + item.Id} onChange={() => changeBold(item.NameParameter)} />
                                        : <input type='checkbox' id={'Angletoprightrequisitescontractorsiteminputbold_' + item.Id} onChange={() => changeBold(item.NameParameter)} />
                            }
                            <label htmlFor={'Angletoprightrequisitescontractorsiteminputbold_' + item.Id}>B</label>
                        </div>
                        <div className='angletoprightrequisitescontractorsiteminputitalic'>
                            {
                                item.Italic
                                    ? <input type='checkbox' checked id={'Angletoprightrequisitescontractorsiteminputitalic_' + item.Id} onChange={() => changeItalic(item.NameParameter)} />
                                    : <input type='checkbox' id={'Angletoprightrequisitescontractorsiteminputitalic_' + item.Id} onChange={() => changeItalic(item.NameParameter)} />
                            }
                            <label htmlFor={'Angletoprightrequisitescontractorsiteminputitalic_' + item.Id}>I</label>
                        </div>
                        <div>
                            <select onChange={e => { changeFontSize(item.NameParameter, e.target.value) }} defaultValue={item.FontSize === 0 ? '10' : item.FontSize}>
                                <option value='8'>8pt</option>
                                <option value='10'>10pt</option>
                                <option value='12'>12pt</option>
                                <option value='14'>14pt</option>
                                <option value='16'>16pt</option>
                            </select>
                        </div>
                        <div className='angletoprightrequisitescontractorsitemmarginrowsblock'>
                            <FiMinus className={'angletoprightrequisitescontractorsitemmarginrowsminus' + (item.MarginBottomCountRows === 0 ? " disabled" : "")} onClick={() => item.MarginBottomCountRows === 0 ? null : changeMarginRow(item.NameParameter, false)} />
                            <div>{item.MarginBottomCountRows}</div>
                            <FiPlus className='angletoprightrequisitescontractorsitemmarginrowsplus' onClick={() => changeMarginRow(item.NameParameter, true)} />
                        </div>
                    </div>
                </div>
            </div>
        })
    }

    const dragEnterTool = (column) => {
        if (items.find(x => x.CommercialProposalFooterColumnsId === column.Id && x.NameParameter === currentTool) === undefined) {
            var order = items.filter(x => x.CommercialProposalFooterColumnsId === column.Id).length + 1;
            if (items.find(x => x.NameParameter === currentTool) !== undefined) {
                var item = items.find(x => x.NameParameter === currentTool);
                items.forEach(itemColumn => {
                    if (itemColumn.CommercialProposalFooterColumnsId === item.CommercialProposalFooterColumnsId && itemColumn.NameParameter !== currentTool && itemColumn.Order > item.Order) {
                        itemColumn.Order--;
                    }
                })
            }
            setItems([...items.filter(x => x.NameParameter !== currentTool), { Order: order, NameParameter: currentTool, CommercialProposalFooterColumnsId: column.Id }]);
        }
    }

    const onDropItem = () => {
        var item = items.find(x => x.NameParameter === currentTool);
        axios.post("/settingfooteraddcolumnitem", { Order: item.Order, ColumnId: item.CommercialProposalFooterColumnsId, NameParameter: currentTool });
    }

    const ColumnsTools = () => {
        return <div id='FooterCPSettingsItemsBlock'>
            <div id='FooterCPSettingsItemsContentBlock'>
                {
                    parameters.map((item, index) => {
                        //parameters.filter(x=>!items.map(a => a.NameParameter).includes(x)).map((item, index) => {
                        return <div key={'ItemColumntTool_' + index} className='footerCPSettingsItem' draggable={true} onMouseDown={() => setCurrentTool(item)} >{t("workplace.menuitems.toprightangle." + item)}</div>
                    })
                }
            </div>
        </div>
    }

    const changeBold = (value) => {
        var item = items[items.findIndex(x => x.NameParameter === value)];
        item.Bold = !item.Bold;
        axios.post("/settingfooterbold", { NameParameter: value, Bold: item.Bold })
        setItems([...items]);
    }

    const changeItalic = (value) => {
        var item = items[items.findIndex(x => x.NameParameter === value)];
        item.Italic = !item.Italic;
        axios.post("/settingfooteritalic", { NameParameter: value, Italic: item.Italic })
        setItems([...items]);
    }

    const changeMarginRow = (value, plus) => {
        var item = items[items.findIndex(x => x.NameParameter === value)];
        item.MarginBottomCountRows = plus ? item.MarginBottomCountRows + 1 : item.MarginBottomCountRows - 1;
        axios.post("/settingfootermarginrow", { NameParameter: value, MarginBottomCountRows: item.MarginBottomCountRows })
        setItems([...items]);
    }

    const changeFontSize = (value, fontsize) => {
        var item = items[items.findIndex(x => x.NameParameter === value)];
        item.FontSize = fontsize;
        axios.post("/settingfooterfontsize", { NameParameter: value, FontSize: fontsize })
        setItems([...items]);
    }

    const changeOrder = (value, up) => {
        var item = items[items.findIndex(x => x.NameParameter === value)];
        axios.post("/settingfooterorder", { NameParameter: value, Up: up })
        if (up) {
            var itemup = items.find(x => x.CommercialProposalFooterColumnsId === item.CommercialProposalFooterColumnsId && x.Order === item.Order - 1);
            item.Order--;
            itemup.Order++;
        }
        else {
            var itemdown = items.find(x => x.CommercialProposalFooterColumnsId === item.CommercialProposalFooterColumnsId && x.Order === item.Order + 1);
            item.Order++;
            itemdown.Order--;
        }
        setItems([...items]);
    }

    return (
        <div id='footerCPSettings'>
            <ColumnsTools />
            <Columns />
        </div>
    )
}
