import axios from 'axios';
import { React, useState, useEffect } from 'react'
import './contactPersons.css'
import { IoCheckmark } from 'react-icons/io5'
import { IoMdClose } from 'react-icons/io'
import { useTranslation } from 'react-i18next'
import { FiEdit } from 'react-icons/fi'

export default function LinkContactPersons({ setContactPersonalBlock, clientid, contactPersons, setContactPersons }) {

  const { t } = useTranslation();
  const [linkContactPersons, setLinkContactPersons] = useState([]);
  const [fullName, setFullName] = useState("");
  const [newEditDataContactPerson, setNewEditDataContactPerson] = useState({ Id: 0, Phone: "", Post: "", Email: "", Note: "" });
  const [createEditContactPersons, setCreateEditContactPersons] = useState(false);

  useEffect(() => {
    axios.get("/linkcontactpersons/" + clientid).then(response => response.data).then(data => { setLinkContactPersons([...data]); setContactPersons([...data.filter(x => x.Link)]); })
  }, [])// eslint-disable-line react-hooks/exhaustive-deps

  const startEditContactPerson = (value) => {
    setCreateEditContactPersons(true);
    var contactperson = linkContactPersons[linkContactPersons.findIndex(x => x.ContactPersonsId === value)];
    setFullName(contactperson.FullName);
    setNewEditDataContactPerson({ Id: value, Phone: contactperson.Phone ?? "", Post: contactperson.Post ?? "", Email: contactperson.Email ?? "", Note: contactperson.Note ?? "" });
  }

  const editContactPerson = () => {
    axios.post("/editcontactspersonals", { ContactPersonsId: newEditDataContactPerson.Id, FullName: fullName, Phone: newEditDataContactPerson.Phone, Post: newEditDataContactPerson.Post, Email: newEditDataContactPerson.Email, Note: newEditDataContactPerson.Note })
      .then(response => {
        var contactpersonid = linkContactPersons.findIndex(x => x.ContactPersonsId === newEditDataContactPerson.Id);
        linkContactPersons[contactpersonid].FullName = fullName;
        linkContactPersons[contactpersonid].Phone = newEditDataContactPerson.Phone;
        linkContactPersons[contactpersonid].Post = newEditDataContactPerson.Post;
        linkContactPersons[contactpersonid].Email = newEditDataContactPerson.Email;
        linkContactPersons[contactpersonid].Note = newEditDataContactPerson.Note;
        if (contactPersons[contactPersons.findIndex(x => x.ContactPersonsId === newEditDataContactPerson.Id)] !== undefined) {
          var contactpersonlinkid = contactPersons.findIndex(x => x.ContactPersonsId === parseInt(newEditDataContactPerson.Id));
          contactPersons[contactpersonlinkid].FullName = fullName;
          contactPersons[contactpersonlinkid].Phone = newEditDataContactPerson.Phone;
          contactPersons[contactpersonlinkid].Post = newEditDataContactPerson.Post;
          contactPersons[contactpersonlinkid].Email = newEditDataContactPerson.Email;
          contactPersons[contactpersonlinkid].Note = newEditDataContactPerson.Note;
        }
        setLinkContactPersons([...linkContactPersons]);
        setContactPersons([...contactPersons]);
        setCreateEditContactPersons(false);
        setFullName("");
        setNewEditDataContactPerson({ Id: 0, Phone: "", Post: "", Email: "", Note: "" })
      })
  }

  const createContactPerson = () => {
    axios.post("/createcontactspersonals", { ClientId: clientid, FullName: fullName, Phone: newEditDataContactPerson.Phone, Post: newEditDataContactPerson.Post, Email: newEditDataContactPerson.Email, Note: newEditDataContactPerson.Note })
      .then(response => {
        var contactPerson = { Id: null, Link: true, ContactPersonsId: response.data, ClientId: clientid, FullName: fullName, Phone: newEditDataContactPerson.Phone, Post: newEditDataContactPerson.Post, Email: newEditDataContactPerson.Email, Note: newEditDataContactPerson.Note };
        linkContactPersons.push(contactPerson);
        contactPersons.push(contactPerson);
        setLinkContactPersons([...linkContactPersons]);
        setContactPersons([...contactPersons]);
        setCreateEditContactPersons(false);
        setFullName("");
        setNewEditDataContactPerson({ Id: 0, Phone: "", Post: "", Email: "", Note: "" })
      })
  }

  const addContactPerson = (value) => {
    axios.post("/addcontactspersonals", { ClientId: clientid, ContactPersonsId: value, Link: false }).then(() => {
      linkContactPersons[linkContactPersons.findIndex(x => x.ContactPersonsId === value)].Link = true;
      setLinkContactPersons([...linkContactPersons]);
      setContactPersons([...linkContactPersons.filter(x => x.Link)]);
    })
  }

  const disableContactPerson = (value) => {
    axios.post("/disablecontactspersonals", { ClientId: clientid, ContactPersonsId: value, Link: false }).then(() => {
      linkContactPersons[linkContactPersons.findIndex(x => x.ContactPersonsId === value)].Link = false;
      setLinkContactPersons([...linkContactPersons]);
      // var contactPersonId = contactPersons.findIndex(x=>x.ContactPersonsId===value);
      // contactPersons.splice(contactPersonId, 1);
      setContactPersons([...linkContactPersons.filter(x => x.Link)]);
    })
  }

  const cancelCreateContactPerson = () => {
    setFullName("");
    setCreateEditContactPersons(false);
  }

  return (
    <div id="LinkContactPersonsBlock">
      <div id="LinkContactPersonsHeaderBlock">
        <div id="LinkContactPersonsHeaderTitle">{t("workplace.menuitems.client.contactpersons")}</div>
        <IoMdClose id="LinkContactPersonsHeaderCloseIcon" onClick={() => setContactPersonalBlock(false)} />
      </div>
      <hr />

      {
        createEditContactPersons
          ? <div className='clientinfotitle'>{t("workplace.menuitems.client.fullnamecontactporson")}</div>
          : null
      }
      {
        createEditContactPersons || linkContactPersons.length !== 0
          ? <input className="linkContactPersonsInput" placeholder={createEditContactPersons ? "" : t("workplace.menuitems.client.fullnamecontactporson")} type="text" value={fullName} onChange={e => setFullName(e.target.value)} />
          : null
      }

      {
        !createEditContactPersons
          ? linkContactPersons.length !== 0
            ? linkContactPersons.filter(item => item.FullName.includes(fullName)).map((item, index) => (
              <div key={"LinkContactPerson_" + index} className='linkContactPersonItemBlock'>
                <div className='linkContactPersonItemData'>
                  <div className='linkContactPersonTitleBlock'>
                    <div>{item.FullName}</div>
                    <FiEdit onClick={() => startEditContactPerson(item.ContactPersonsId)} className='linkContactPersonEditIcon' />
                  </div>
                  <div className='linkContactPersonItemDataRow'>
                    <div className='linkContactPersonItemDataRowItem'>
                      <div className='clientinfotitle'>{t("default.phone")}</div>
                      <div>{item.Phone}</div>
                    </div>
                    <div className='linkContactPersonItemDataRowItem'>
                      <div className='clientinfotitle'>{t("default.post")}</div>
                      <div>{item.Post}</div>
                    </div>
                    <div className='linkContactPersonItemDataRowItem'>
                      <div className='clientinfotitle'>E-mail</div>
                      <div>{item.Email}</div>
                    </div>
                  </div>
                </div>
                {
                  item.Link
                    ? <div onClick={() => disableContactPerson(item.ContactPersonsId)} className='linkContactPersontAddedButton'>
                      <IoCheckmark />
                      <div>{t("workplace.menuitems.client.contactpersonadded")}</div>
                    </div>
                    : <div className='AddButton' onClick={() => addContactPerson(item.ContactPersonsId)}>+ {t("buttons.add")}</div>
                }
              </div>
            ))
            : <div id="LinkContactPersonsNotFoundBlock">
              <div id="LinkContactPersonsNotFoundText">{t("workplace.menuitems.client.contactpersonsnotfound")}</div>
              <div className='AddButton' onClick={() => setCreateEditContactPersons(true)}>{t("workplace.menuitems.client.createcontactpersonbutton")}</div>
            </div>
          : <div>
            <div>
              <div className='clientinfotitle'>{t("default.phone")}</div>
              <input className='linkContactPersonsInput' type="text" value={newEditDataContactPerson.Phone} onChange={e => setNewEditDataContactPerson({ ...newEditDataContactPerson, Phone: e.target.value })} />
            </div>
            <div>
              <div className='clientinfotitle'>{t("default.post")}</div>
              <input className='linkContactPersonsInput' type="text" value={newEditDataContactPerson.Post} onChange={e => setNewEditDataContactPerson({ ...newEditDataContactPerson, Post: e.target.value })} />
            </div>
            <div>
              <div className='clientinfotitle'>E-mail</div>
              <input className='linkContactPersonsInput' type="text" value={newEditDataContactPerson.Email} onChange={e => setNewEditDataContactPerson({ ...newEditDataContactPerson, Email: e.target.value })} />
            </div>
            <div>
              <div className='clientinfotitle'>{t("default.note")}</div>
              <input className='linkContactPersonsInput' value={newEditDataContactPerson.Note} onChange={e => setNewEditDataContactPerson({ ...newEditDataContactPerson, Note: e.target.value })} />
            </div>
            <div id='LinkContactPersonsCreateButtonsBlock'>
              <div id="LinkContactPersonsCreateButtonsCancel" onClick={() => cancelCreateContactPerson()}>{t("buttons.cancel")}</div>
              <div className='AddButton' onClick={() => newEditDataContactPerson.Id === 0 ? createContactPerson() : editContactPerson()}>{t("buttons.save")}</div>
            </div>
          </div>
      }
      {
        !createEditContactPersons && linkContactPersons.length > 0 && linkContactPersons.filter(item => item.FullName.includes(fullName)).length === 0
          ?
          <div id="LinkContactPersonsNotFoundBlock">
            <div id="LinkContactPersonsNotFoundText">{t("workplace.menuitems.client.contactpersonsnotfound")}</div>
            <div className='AddButton' onClick={() => setCreateEditContactPersons(true)}>{t("workplace.menuitems.client.createcontactpersonbutton")}</div>
          </div>
          : null
      }
    </div>
  )
}
