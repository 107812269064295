import {React, useState, useEffect} from 'react'
import './body.css'
import { useTranslation } from 'react-i18next'
import "../../App.css"
import PCDE from "../../img/mainsite/PCDE.svg"
import PCRU from "../../img/mainsite/PCRU.svg"
import PCEN from "../../img/mainsite/PCEN.svg"
import MobileDE from "../../img/mainsite/MobileDE.png"
import MobileRU from "../../img/mainsite/MobileRU.png"
import MobileEN from "../../img/mainsite/MobileEN.png"
import calendar from "../../img/icons/mainsite/calendar.svg"
import call from "../../img/icons/mainsite/call.svg"
import work from "../../img/icons/mainsite/work.svg"
import edit from "../../img/icons/mainsite/edit.svg"
import setting from "../../img/icons/mainsite/setting.svg"
import graph from "../../img/icons/mainsite/graph.svg"
import { Link } from "react-scroll"

export default function Body() {
    const {t} = useTranslation();
    let images = {};
    images["PCDE"] = PCDE; 
    images["PCRU"] = PCRU; 
    images["PCEN"] = PCEN; 
    images["MobileDE"] = MobileDE; 
    images["MobileRU"] = MobileRU; 
    images["MobileEN"] = MobileEN; 
    const [pcimg ,setpcimg] = useState(t('mainsite.body.FBImagePC'));
    const [mobileimg ,setmobileimg] = useState(t('mainsite.body.FBImageMobile'));

    useEffect(() => {setpcimg(t('mainsite.body.FBImagePC'));},[t]); 
    useEffect(() => {setmobileimg(t('mainsite.body.FBImageMobile'));},[t]); 

    return (
        <div id="MainSiteBody">
            <div id="MSBodyFirstBlock" className="CRMcontainer">
                <div id="MSBodyFirstBlockImage">    
                    <img id="MSBodyFBPCIMG" src={images[pcimg]} alt="" className="PCBlock"/>
                    <img id="MSBodyFBMobileIMG" src={images[mobileimg]} alt="" className="MobileBlock"/>
                </div>
                <div id="MSBodyFirstBlockTextButton">
                    <div id="MSBodyFirstBlockText">{t("mainsite.body.FBTitle")}</div>
                    <Link activeClass="active" to="MSBodyThirdBlock" spy={true} smooth={true} offset={-113} duration={500}><button id="MSBodyFirstBlockButton" className="MSBodyButton">{t("mainsite.body.FBButton")}</button></Link>
                </div>             
            </div>

            <div id="MSBodySecondBlock" className="CRMcontainer">
                <div id="MSBodySecondBlockList">
                    <div className="MSBodySecondBlockItem">
                        <div className="MSBodySecondBlockItemCircle">
                            <img src={calendar} className="MSBodySecondBlockItemCircleIcon" alt=""/>
                        </div>
                        <div className="MSBodySecondBlockItemTitle">
                            {t('mainsite.body.SB1BTitle')}
                        </div>
                        <div className="MSBodySecondBlockItemText">
                            {t('mainsite.body.SB1BText')}
                        </div>
                    </div>
                    <div className="MSBodySecondBlockItem">
                    <div className="MSBodySecondBlockItemCircle">
                            <img src={call} className="MSBodySecondBlockItemCircleIcon" alt=""/>
                        </div>
                        <div className="MSBodySecondBlockItemTitle">
                            {t('mainsite.body.SB2BTitle')}
                        </div>
                        <div className="MSBodySecondBlockItemText">
                            {t('mainsite.body.SB2BText')}
                        </div>
                    </div>
                    <div className="MSBodySecondBlockItem">
                    <div className="MSBodySecondBlockItemCircle">
                            <img src={work} className="MSBodySecondBlockItemCircleIcon" alt=""/>
                        </div>
                        <div className="MSBodySecondBlockItemTitle">
                            {t('mainsite.body.SB3BTitle')}
                        </div>
                        <div className="MSBodySecondBlockItemText">
                            {t('mainsite.body.SB3BText')}
                        </div>
                    </div>
                    <div className="MSBodySecondBlockItem">
                    <div className="MSBodySecondBlockItemCircle">
                            <img src={edit} className="MSBodySecondBlockItemCircleIcon" alt=""/>
                        </div>
                        <div className="MSBodySecondBlockItemTitle">
                            {t('mainsite.body.SB4BTitle')}
                        </div>
                        <div className="MSBodySecondBlockItemText">
                            {t('mainsite.body.SB4BText')}
                        </div>
                    </div>
                    <div className="MSBodySecondBlockItem">
                    <div className="MSBodySecondBlockItemCircle">
                            <img src={setting} className="MSBodySecondBlockItemCircleIcon" alt=""/>
                        </div>
                        <div className="MSBodySecondBlockItemTitle">
                            {t('mainsite.body.SB5BTitle')}
                        </div>
                        <div className="MSBodySecondBlockItemText">
                            {t('mainsite.body.SB5BText')}
                        </div>
                    </div>
                    <div className="MSBodySecondBlockItem">
                    <div className="MSBodySecondBlockItemCircle">
                            <img src={graph} className="MSBodySecondBlockItemCircleIcon" alt=""/>
                        </div>
                        <div className="MSBodySecondBlockItemTitle">
                            {t('mainsite.body.SB6BTitle')}
                        </div>
                        <div className="MSBodySecondBlockItemText">
                            {t('mainsite.body.SB6BText')}
                        </div>
                    </div>
                </div>
            </div>

            <div id="MSBodyThirdBlock">
                <div id="MSBodyThirdBlockTitle">{t('mainsite.body.TBTitle')}</div>
                <div id="MSBodyThirdBlockForm">
                    <div className="MSBodyThirdBlockFormItem">
                        <label className="MSBodyThirdBlockFormLabel" htmlFor="MSBodyThirdBlockFormFN">{t('mainsite.body.TBFF')}</label>
                        <input id="MSBodyThirdBlockFormFN" className="MSBodyThirdBlockFormInput" type="text"/>
                    </div>
                    <div className="MSBodyThirdBlockFormItem">
                        <label className="MSBodyThirdBlockFormLabel" htmlFor="MSBodyThirdBlockFormSN">{t('mainsite.body.TBFS')}</label>
                        <input id="MSBodyThirdBlockFormSN" className="MSBodyThirdBlockFormInput" type="text"/>
                    </div>
                    <div className="MSBodyThirdBlockFormItem">
                        <label className="MSBodyThirdBlockFormLabel" htmlFor="MSBodyThirdBlockFormTN">{t('mainsite.body.TBFT')}</label>
                        <input id="MSBodyThirdBlockFormTN" className="MSBodyThirdBlockFormInput" type="text"/>
                    </div>
                    <button className="MSBodyButton">{t('mainsite.body.TBButton')}</button>

                </div>
            </div>

        </div>
    )
}
