import axios from 'axios';
import { React, useState, useEffect } from 'react'
import "./works.css"
import Workstreeitemadd from './workstree/workstreeitemadd'
import { BsFolderPlus } from 'react-icons/bs'
import { IoHammerOutline } from 'react-icons/io5'
import { FaEdit } from 'react-icons/fa'
import { AiOutlineDelete } from 'react-icons/ai'
import Worksitem from './worksitem/worksitem'
import { useTranslation } from 'react-i18next'
import Workstree from './workstree/workstree';

export default function Works() {

    const { t } = useTranslation();
    const [tree, setTree] = useState([]);
    const [menuBlock, setMenuBlock] = useState(false);
    const [menuY, setMenuY] = useState(0);
    const [selectWork, setSelectWork] = useState();
    const [currentNode, setCurrentNode] = useState();
    const [currentNodeEditMode, setCurrentNodeEditMode] = useState(false);
    const [contextMenuItemAddFolder, setContextMenuItemAddFolder] = useState(false);
    const [contextMenuItemAddWork, setContextMenuItemAddWork] = useState(false);
    const [contextMenuItemRemove, setContextMenuItemRemove] = useState(false);

    useEffect(() => (axios.get("/treeworks").then(response => response.data).then(data => setTree(data))), [])

    // useEffect(() => {
    //     if (currentWork != null && titleNew != null)
    //         axios.post("/changetreeworks", { TypeWorkId: currentWork.TypeWorkId, Title: titleNew })
    //             .then(() => {
    //                 if (currentWork.Work && currentWork.TypeWorks) {
    //                     var w = selectWork;
    //                     w.TypeWorks.Title = titleNew;
    //                     setSelectWork(w);
    //                 }
    //                 if (currentWork.Work && createWork)
    //                     axios.post("/createworks", { TypeWorksId: currentWork.TypeWorkId })
    //                         .then(() => axios.post("/selectwork", { TypeWorksId: currentWork.TypeWorkId })
    //                             .then(response => response.data).then(data => { setSelectWork(data); setCreateWork(false); }))
    //                 setTitleNew(null)
    //             }
    //             )
    // }, [titleNew, currentWork, createWork, selectWork])

    const concernedElement = document.getElementById("WorksMenuBlock");
    document.addEventListener("mousedown", (event) => { if (concernedElement != null && !concernedElement.contains(event.target)) setMenuBlock(false) });

    const addnode = (work) => {
        var newItem = { Id: null, TypeWorkId: 9999999999, ParentId: currentNode.TypeWorkId, Title: work ? t("workplace.menuitems.works.newtypework") : t("workplace.menuitems.works.newtypework"), Work: work ? true : false }
        setMenuBlock(false);
        setCurrentNode(newItem);
        setCurrentNodeEditMode(true);
        setTree([...tree, newItem]);
    }
    const changenode = (value) => {
        if (currentNode.TypeWorkId === 9999999999) {
            axios.post("/addtreeworks", { ParentId: currentNode.ParentId, Title: value, Work: currentNode.Work ? true : false }).then(response => response.data)
                .then(data => {
                    setCurrentNode(data);
                    var index = tree.findIndex(x => x.TypeWorkId === 9999999999);
                    tree[index].TypeWorkId = data.TypeWorkId;
                    tree[index].Title = value;
                    setTree([...tree]);
                    if (currentNode.Work) {
                        axios.post("/createworks", { TypeWorksId: data.TypeWorkId })
                            .then(() => axios.post("/selectwork", { TypeWorksId: data.TypeWorkId })
                                .then(response => response.data).then(data => setSelectWork(data)))
                    }
                })
        }
        else {
            axios.post("/changetreeworks", { TypeWorkId: currentNode.TypeWorkId, Title: value });
            if (currentNode.Work) {
                var w = selectWork;
                w.TypeWorks.Title = value;
                setSelectWork(w);
            }
        }
        setMenuBlock(false);
        setCurrentNodeEditMode(false);
    }
    const removenode = () => {
        axios.post("/removetreeworks", { TypeWorkId: currentNode.TypeWorkId }).then(() => {
            var index = tree.findIndex(x => x.TypeWorkId === currentNode.TypeWorkId);
            tree.splice(index, 1);
            setCurrentNode();
            setTree([...tree]);
        });
    }
    useEffect(() => {
        if (currentNode !== undefined) {
            if (currentNode.Work) {
                setContextMenuItemAddFolder(false);
                setContextMenuItemAddWork(false);
                setContextMenuItemRemove(false);
            }
            else {
                setContextMenuItemAddFolder(true);
                setContextMenuItemAddWork(true);
                if (tree.filter(x => x.ParentId === currentNode.TypeWorkId).length === 0)
                    setContextMenuItemRemove(true);
                else
                    setContextMenuItemRemove(false);
            }
        }
        if (currentNode !== undefined && currentNode.Work && currentNode.TypeWorkId !== 9999999999)
            axios.post("/selectwork", { TypeWorksId: currentNode.TypeWorkId }).then(response => response.data).then(data => { data.WorksId === 0 ? setSelectWork(null) : setSelectWork(data) });
        else setSelectWork(null);
    }, [tree, currentNode]);

    return (
        <div id="works">
            {/* Блок меню */}
            <div id="WorksMenuBlock" style={{ top: menuY - 27 }} className={menuBlock ? "Open" : null}>
                {
                    contextMenuItemAddFolder
                        ? <div className="WorksMenuItemBlock" onClick={() => { addnode(false); }}>
                            <BsFolderPlus className="WorksMenuItemIcon" />
                            <div>{t("workplace.menuitems.works.addtypework")}</div>
                        </div>
                        : null
                }
                {
                    contextMenuItemAddWork
                        ? <div className="WorksMenuItemBlock" onClick={() => { addnode(true); }}>
                            <IoHammerOutline className="WorksMenuItemIcon" />
                            <div>{t("workplace.menuitems.works.addwork")}</div>
                        </div>
                        : null
                }
                <div className="WorksMenuItemBlock" onClick={() => { setMenuBlock(!menuBlock); setCurrentNodeEditMode(true); }} >
                    <FaEdit className="WorksMenuItemIcon" />
                    <div>{t("workplace.menuitems.works.edit")}</div>
                </div>
                {
                    contextMenuItemRemove
                        ? <div className="WorksMenuItemBlock" onClick={() => { removenode(); setMenuBlock(false) }}>
                            <AiOutlineDelete className="WorksMenuItemIcon" />
                            <div>{t("workplace.menuitems.works.remove")}</div>
                        </div>
                        : null
                }
            </div>
            {/* Дерево работ */}
            <div id="WorksTree">
                <div id="WorksTreeContent">
                    {
                        tree.filter(x => x.ParentId === 0)
                            .map(item =>
                                <Workstree
                                    key={"Node_" + item.TypeWorkId}
                                    tree={tree}
                                    node={item}
                                    currentNode={currentNode}
                                    currentNodeEditMode={currentNodeEditMode}
                                    setCurrentNodeEditMode={setCurrentNodeEditMode}
                                    setCurrentNode={setCurrentNode}
                                    setMenuBlock={setMenuBlock}
                                    setMenuY={setMenuY}
                                    changenode={changenode}
                                    setSelectWork={setSelectWork}
                                />)
                    }
                    <Workstreeitemadd tree={tree} setTree={setTree} />
                </div>
            </div>
            <Worksitem selectWorkItem={selectWork ? selectWork : null} />
        </div>
    )
}
