import { React, useState } from 'react'
import axios from 'axios'
import { AiOutlineDelete } from 'react-icons/ai'
import { useTranslation } from 'react-i18next'
import { IoMdClose, IoMdCheckmark } from 'react-icons/io'

export default function BoardTitle({ board, boards, setBoards, currentBoard, setCurrentBoard, setAddBoard, setDragBoard }) {

    const { t } = useTranslation();
    const [title, setTitle] = useState(board.Boards.Title);
    const [edit, setEdit] = useState(false);
    const [remove, setRemove] = useState(false);

    function RemoveBoard() {
        axios.post("/removeboard/" + currentBoard.Boards.Id);
        var array = [...boards];
        var index = array.indexOf(currentBoard)
        if (index !== -1) {
            array.splice(index, 1);
            array.map(item =>
            (
                item.Boards.Number > currentBoard.Number
                    ? item.Boards.Number = item.Boards.Number - 1
                    : null
            ))
            setBoards(array);
        }
    }

    return (
        <div className="boardTitle" style={{ borderBottom: "1.5px dashed #" + board.Boards.Colors.Color }}>
            <div className="boardTitleRemoveBlock">
                {
                    edit
                        ? <input autoFocus value={title} onFocus={()=>setDragBoard(false)} onBlur={() => {setDragBoard(true); setEdit(false); axios.post("/boardchangetitle", { BoardId: board.Boards.Id, Title: title }); }} onChange={(e) => setTitle(e.target.value)} className="boardTitleInput" />
                        : <div className="boardTitleText" onClick={() => setEdit(true)}>{title}</div>
                }
                {remove ? null : <AiOutlineDelete className="boardRemoveIcon" onClick={() => { setRemove(true); setAddBoard(false); setCurrentBoard(board); }} />}
            </div>
            {/* Блок удаления */}
            <div id="BoardConfirmRemoveBlock" style={{ border: "1.5px dashed #" + board.Boards.Colors.Color }} className={remove ? "Open" : null}>
                <div>{t("workplace.menuitems.transactions.RemoveConfirm")}</div>
                <div id="BoardConfirmRemoveButtonsBlock">
                    <div className="boardConfirmRemoveButton BoardConfirmButtonYes" onClick={() => { setRemove(false); RemoveBoard() }}><IoMdCheckmark /></div>
                    <div onClick={() => setRemove(false)} className="boardConfirmRemoveButton BoardConfirmButtonNo"><IoMdClose /></div>
                </div>
            </div>
            <div className="boardTitleBudget">{board.Budget}</div>
        </div>
    )
}
