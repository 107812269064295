import { React, useState, useEffect } from 'react'
import { AiOutlineDelete } from 'react-icons/ai'
import { IoImageOutline } from 'react-icons/io5'
import { useTranslation } from 'react-i18next'

export default function Addworksitemspart({ item, index, changeitem, deletephotoitem }) {

    const { t } = useTranslation();
    const [description, setDescription] = useState("");
    const [note, setNote] = useState("");
    const [imagePreView, setImagePreView] = useState();

    useEffect(() => {
        setDescription(item.Description);
        setNote(item.Note);
        if (item.Photo != null)
            previewChange(item.Photo)
    }, [item])

    const preview = (e) => {
        const reader = new FileReader();
        reader.onload = function () {
            setImagePreView(reader.result);
        }
        reader.readAsDataURL(e.target.files[0]);
    }

    const previewChange = (e) => {
        const reader = new FileReader();
        reader.onload = function () {
            setImagePreView(reader.result);
        }
        reader.readAsDataURL(e);
    }

    return (
        <div className="AddWorksItemsPhotoBlock">
            <div className="AddWorksItemsPhotoInputBlock">
                <input style={{ display: "none" }} id={"AddWorksItemsPhotoInput_" + index} type="file" onChange={e => { changeitem(index, e.target.files[0], "Photo"); preview(e); }} accept="image/png, image/jpeg" />
                <label htmlFor={"AddWorksItemsPhotoInput_" + index}>
                    {
                        imagePreView
                            ? <img className="AddWorksItemsPhotoImage" src={imagePreView} alt="" />
                            : <IoImageOutline className="AddWorksItemsPhotoImageNull" />
                    }
                </label>
            </div>
            <div className="AddWorksItemsPhotoTextBlock">
                <div className="forminputblock">
                    <textarea id={"AddWorksItemsPhotoTextAreaDescription_" + index} placeholder=" " value={description} onChange={(e) => { setDescription(e.target.value); }} onBlur={(e) => changeitem(index, e.target.value, "Description")} className="forminput AddWorksItemsTextArea" />
                    <label htmlFor={"AddWorksItemsPhotoTextAreaDescription_" + index} className="formlabel">{t("workplace.menuitems.works.descriptionphoto")}</label>
                </div>
                <div className="forminputblock">
                    <textarea id={"AddWorksItemsPhotoTextAreaNote_" + index} placeholder=" " value={note} onChange={(e) => setNote(e.target.value)} onBlur={(e) => { changeitem(index, e.target.value, "Note"); }} className="forminput AddWorksItemsTextArea" />
                    <label htmlFor={"AddWorksItemsPhotoTextAreaNote_" + index} className="formlabel">{t("workplace.menuitems.works.notephoto")}</label>
                </div>
            </div>
            <AiOutlineDelete className="AddWorksItemsPhotoDeleteIcon" onClick={() => deletephotoitem(index)} />
        </div>
    )
}
